<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Reporte Documentos Recibidos
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" to="/home">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="3" md="3">
              <v-dialog ref="dialog" v-model="dateModal" :return-value.sync="dates" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates" label="Periodo" prepend-icon="mdi-calendar" readonly required
                    :rules="dateRules" v-bind="attrs" v-on="on" class="pa-0" outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="dates" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateModal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field v-model="reporteRecibidos.cedulaEmisor" label="Cédula Emisor" class="pa-0" outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="reporteRecibidos.nombreEmisor" label="Nombre Emisor" class="pa-0" outlined
                dense></v-text-field>
            </v-col>

            <v-col col="12" md="3" sm="3">
              <v-select v-model="selectedMonedas" :items="monedas" item-text="md_nombre" item-value="md_codigo"
                label="Monedas" multiple class="pa-0" outlined dense>
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="selectedMonedas.length > 0 ? 'indigo darken-4' : ''
                        ">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos/Ninguno </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>

            <v-col col="12" md="3" sm="3">
              <v-select v-model="selectedTiposDocumentos" :items="tiposDocumentos" item-text="documento"
                item-value="tipoDocumento" label="Tipo Documentos" multiple class="pa-0" outlined dense>
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle2">
                    <v-list-item-action>
                      <v-icon :color="selectedTiposDocumentos.length > 0
                          ? 'indigo darken-4'
                          : ''
                        ">
                        {{ icon2 }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos/Ninguno </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="reporteRecibidos.clave" label="Clave" class="pa-0" outlined dense></v-text-field>
            </v-col>

            <v-col col="12" md="3" sm="3">
              <v-select v-model="selectedEstadosHacienda" :items="estadosHacienda" label="Estado en Hacienda" multiple
                class="pa-0" outlined dense>
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle3">
                    <v-list-item-action>
                      <v-icon :color="selectedEstadosHacienda.length > 0
                          ? 'indigo darken-4'
                          : ''
                        ">
                        {{ icon3 }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos/Ninguno </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
            <v-col col="12" md="3" sm="3">
              <v-select v-model="selectedEstadosAceptacion" :items="estadosAceptacion" label="Estado de Aceptación"
                multiple class="pa-0" outlined dense>
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle4">
                    <v-list-item-action>
                      <v-icon :color="selectedEstadosAceptacion.length > 0
                          ? 'indigo darken-4'
                          : ''
                        ">
                        {{ icon4 }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos/Ninguno </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-btn color="primary" @click="clear()">
                <v-icon left> mdi-eraser </v-icon>
                Limpiar
              </v-btn>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-btn color="primary" @click="getReport()" :disabled="!valid">
                <v-icon left> mdi-file-find </v-icon>
                Generar
              </v-btn>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <vue-excel-xlsx color="primary" v-if="items2.length > 0" :data="items2" :columns="columns"
                :filename="'ReporteRecibidos'" :sheetname="'Compras'">
                <v-btn color="primary" :disabled="!valid">
                  <v-icon left> mdi-download </v-icon>
                  Descargar
                </v-btn>
              </vue-excel-xlsx>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-btn color="primary" @click="sendEmailReport()" :disabled="!validEnviarCorreo">
                <v-icon left> mdi-email-alert </v-icon>
                Enviar por correo
              </v-btn>
            </v-col>

            <!-- <v-col cols="12" sm="4" md="4">
              <v-btn color="primary" @click="getReport()" :disabled="!valid">
                <v-icon left> mdi-eraser </v-icon>
                Decargar
                <vue-excel-xlsx
                  v-if="items2.length > 0"
                  :data="items2"
                  :columns="columns"
                  :filename="'Reporte_De_Caso'"
                  :sheetname="'Hoja'"
                  class="
                    v-btn v-btn--is-elevated v-btn--has-bg
                    theme--light
                    v-size--small
                  "
                >
                  Descargar
                </vue-excel-xlsx>
              </v-btn>
            </v-col> -->

            <!-- <v-col cols="12" md="4" sm="4">
              <v-btn small :disabled="!valid" @click="getReport()"
                >Generar Reporte</v-btn
              >
              <vue-excel-xlsx
                v-if="items2.length > 0"
                :data="items2"
                :columns="columns"
                :filename="'Reporte_De_Caso'"
                :sheetname="'Hoja'"
                class="
                  v-btn v-btn--is-elevated v-btn--has-bg
                  theme--light
                  v-size--small
                "
              >
                Descargar
              </vue-excel-xlsx>
            </v-col> -->
          </v-row>

          <!-- <v-row>
            <v-col cols="12" md="4" sm="4" class="actionsCol">
              <v-btn small :disabled="!valid" @click="getReport()"
                >Generar Reporte</v-btn
              >
              <vue-excel-xlsx
                v-if="items.length > 0"
                :data="items"
                :columns="columns"
                :filename="'Reporte_De_Caso'"
                :sheetname="'Hoja'"
                class="
                  v-btn v-btn--is-elevated v-btn--has-bg
                  theme--light
                  v-size--small
                "
              >
                Descargar
              </vue-excel-xlsx>
            </v-col>
          </v-row> -->
        </v-form>
        <v-data-table :headers="headers2" :items="items2">
          <template v-slot:[`item.xmlRecibido`]="{ item }">
            <v-btn color="primary" class="pa-0" @click="viewXMLRecibido(item)">
              <v-icon color="white"> mdi-file-import </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.xmlFirmado`]="{ item }">
            <v-btn color="primary" class="pa-0" @click="viewXMLFirmado(item)">
              <v-icon color="white"> mdi-file-export </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.xmlRespuesta`]="{ item }">
            <v-btn color="primary" class="pa-0" @click="viewXMLRespuesta(item)">
              <v-icon color="white"> mdi-file-import </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Snackbar from "./shared/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
  data() {
    return {
      valid: false,
      validDecargar: false,
      dateModal: false,
      validEnviarCorreo: false,
      dates: [],
      items2: [],
      tab2: 0,
      selectedMonedas: [],
      monedas: [],
      selectedTiposDocumentos: [],
      tiposDocumentos: [],
      selectedEstadosHacienda: [],
      selectedEstadosAceptacion: [],
      opcionesSistemaCia: [],
      snackText: "",
      snackValue: false,
      dateRules: [
        (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
      ],
      headers2: [
        {
          text: "Clave",
          align: "start",
          value: "clavehacienda",
          width: 450,
        },
        {
          text: "Emisor",
          align: "start",
          value: "clienteNombre",
          width: 450,
        },
        {
          text: "Cédula Emisor",
          align: "start",
          value: "cedulaReceptor",
          width: 140,
        },
        {
          text: "Fecha",
          align: "start",
          value: "fechaString",
          width: 110,
        },
        {
          text: "Moneda",
          align: "start",
          value: "moneda",
          width: 110,
        },
        // {
        //   text: "Tipo Cambio",
        //   align: "start",
        //   value: "tipoCambio",
        //   width: 130,
        // },
        {
          text: "Consecutivo Emisor",
          align: "start",
          value: "consecutivoEmisor",
          width: 200,
        },
        {
          text: "Estado Doc Hacienda",
          align: "start",
          value: "estadoHaciendaEmisor",
          width: 180,
        },
        {
          text: "Estado Aceptación",
          align: "start",
          value: "estadoAceptacion",
          width: 160,
        },
        {
          text: "Consecutivo Receptor",
          align: "start",
          value: "consHacReal",
          width: 200,
        },
        {
          text: "Documento",
          align: "start",
          value: "tipoDocumento",
          width: 220,
        },
        {
          text: "Sub Total",
          align: "start",
          value: "totalVentaStr",
          width: 120,
        },
        {
          text: "Total Descuentos",
          align: "start",
          value: "totalDescuentosStr",
          width: 150,
        },
        {
          text: "Total IVA",
          align: "start",
          value: "totalImpuestoStr",
          width: 120,
        },
        // {
        //   text: "Total Iva 1",
        //   align: "start",
        //   value: "totalIva1Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 2",
        //   align: "start",
        //   value: "totalIva2Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 4",
        //   align: "start",
        //   value: "totalIva4Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 8",
        //   align: "start",
        //   value: "totalIva8Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 13",
        //   align: "start",
        //   value: "totalIva13Str",
        //   width: 120,
        // },
        {
          text: "Total Comprobante",
          align: "start",
          value: "totalComprobanteStr",
          width: 160,
        },
        { text: "XML Recibido", value: "xmlRecibido", sortable: false },
        { text: "Mensaje Receptor", value: "xmlFirmado", sortable: false },
        { text: "XML Respuesta", value: "xmlRespuesta", sortable: false },
      ],
      columns: [
        {
          label: "Clave",
          field: "clavehacienda",
        },
        {
          label: "Emisor",
          field: "clienteNombre",
        },
        {
          label: "Cédula Emisor",
          field: "cedulaReceptor",
        },
        {
          label: "Fecha",
          field: "fechaString",
        },
        {
          label: "Moneda",
          field: "moneda",
        },
        {
          label: "Tipo Cambio",
          field: "tipoCambio",
        },
        {
          label: "Consecutivo Emisor",
          field: "consecutivoEmisor",
        },
        {
          label: "Estado Doc Hacienda",
          field: "estadoHaciendaEmisor",
        },
        {
          label: "Estado Aceptación",
          field: "estadoAceptacion",
        },
        {
          label: "Consecutivo Receptor",
          field: "consHacReal",
        },
        {
          label: "Documento",
          field: "tipoDocumento",
        },
        {
          label: "Sub Total",
          field: "totalVenta",
        },
        {
          label: "Total Descuentos",
          field: "totalDescuentos",
        },
        {
          label: "Total IVA",
          field: "totalImpuesto",
        },
        {
          label: "Total Iva 1",
          field: "totalIva1",
        },
        {
          label: "Total Iva 2",
          field: "totalIva2",
        },
        {
          label: "Total Iva 4",
          field: "totalIva4",
        },
        {
          label: "Total Iva 8",
          field: "totalIva8",
        },
        {
          label: "Total Iva 13",
          field: "totalIva13",
        },
        {
          label: "Total Exento",
          field: "totalExento",
        },
        {
          label: "Total Otros Cargos",
          field: "totalOtrosCargos",
        },
        {
          label: "Total Comprobante",
          field: "totalComprobante",
        },
      ],
      estadosHacienda: ["ACEPTADO", "RECHAZADO"],
      estadosAceptacion: [
        "ACEPTADO",
        "ACEPTADO PARCIAL",
        "RECHAZADO",
        "PENDIENTE",
      ],
      reporteRecibidos: {
        cia: "",
        fechaInicio: Date,
        fechaFin: Date,
        docNombre: "",
        cedulaEmisor: "",
        nombreEmisor: "",
        clave: "",
        moneda: "",
        tipoDocumento: "",
        estadoAceptacion: "",
        estadoHaciendaEmisor: "",
      },
    };
  },

  created() {
    this.getMonedas();
    this.getTiposDocumentos();
    this.getOpcionesSistemaCia();
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllMonedas) {
          this.selectedMonedas = [];
        } else {
          this.selectedMonedas = this.monedas.slice();
        }
      });
    },

    toggle2() {
      this.$nextTick(() => {
        if (this.likesAllTiposDocumentos) {
          this.selectedTiposDocumentos = [];
        } else {
          this.selectedTiposDocumentos = this.tiposDocumentos.slice();
        }
      });
    },

    toggle3() {
      this.$nextTick(() => {
        if (this.likesAllEstadosHacienda) {
          this.selectedEstadosHacienda = [];
        } else {
          this.selectedEstadosHacienda = this.estadosHacienda.slice();
        }
      });
    },

    toggle4() {
      this.$nextTick(() => {
        if (this.likesAllEstadosAceptacion) {
          this.selectedEstadosAceptacion = [];
        } else {
          this.selectedEstadosAceptacion = this.estadosAceptacion.slice();
        }
      });
    },

    clear() {
      console.log("clear");
      (this.dates = []),
        (this.selectedMonedas = []),
        (this.selectedTiposDocumentos = []),
        (this.selectedEstadosHacienda = []),
        (this.selectedEstadosAceptacion = []),
        (this.reporteRecibidos = {
          cia: "",
          fechaInicio: Date,
          fechaFin: Date,
          docNombre: "",
          cedulaEmisor: "",
          nombreEmisor: "",
          clave: "",
          moneda: "",
          tipoDocumento: "",
          estadoAceptacion: "",
          estadoHaciendaEmisor: "",
        });
    },

    getReport() {
      this.getRecibidos();
    },

    getOpcionesSistemaCia() {
      let me = this;
      axios
        .get("api/General/getOpcionesSistemaCia?cia=" + this.strCia)
        .then(function (response) {
          me.opcionesSistemaCia = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    viewXMLRecibido(item) {
      window.open(
        "EduvaFE\\" +
        item.cia +
        "\\XML_RECEPCION\\" +
        item.clavehacienda +
        ".xml",
        "_blank"
      );
      // window.open(item.pathFile, "_blank");
    },

    viewXMLFirmado(item) {
      window.open(
        "EduvaFE\\" +
        item.cia +
        "\\MensajeReceptor\\" +
        item.consHacReal +
        "\\" +
        item.consHacReal +
        "_Signed.xml",
        "_blank"
      );
      // window.open(item.pathFile, "_blank");
    },

    viewXMLRespuesta(item) {
      window.open(
        "EduvaFE\\" +
        item.cia +
        "\\MensajeReceptor\\" +
        item.consHacReal +
        "\\" +
        item.consHacReal +
        "_Response.xml",
        "_blank"
      );
      // window.open(item.pathFile, "_blank");
    },

    getMonedas() {
      let me = this;
      axios
        .get("api/General/getMonedas")
        .then(function (response) {
          me.monedas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTiposDocumentos() {
      let me = this;
      axios
        .get("api/General/getTipoDocumentos")
        .then(function (response) {
          me.tiposDocumentos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    sendEmailReport() {
      let me = this;

      this.reporteRecibidos.cia = this.strCia;
      this.reporteRecibidos.fechaInicio = this.dates[0].toString();
      this.reporteRecibidos.fechaFin = this.dates[1].toString();
      this.reporteRecibidos.docNombre = "Compras";
      this.reporteRecibidos.moneda = "";
      this.reporteRecibidos.tipoDocumento = "";
      this.reporteRecibidos.estadoHaciendaEmisor = "";
      this.reporteRecibidos.estadoAceptacion = "";

      for (const item of this.selectedMonedas) {
        this.reporteRecibidos.moneda += item.md_codigo + ",";
        this.reporteRecibidos.moneda += item + ",";
      }

      for (const item of this.selectedTiposDocumentos) {
        this.reporteRecibidos.tipoDocumento += item.tipoDocumento + ",";
        this.reporteRecibidos.tipoDocumento += item + ",";
      }

      for (const item of this.selectedEstadosHacienda) {
        this.reporteRecibidos.estadoHaciendaEmisor += item + ",";
      }

      for (const item of this.selectedEstadosAceptacion) {
        this.reporteRecibidos.estadoAceptacion += item + ",";
      }

      axios
        .post("/api/Reportes/enviarCorreoRecibidos", this.reporteRecibidos)
        .then(function (response) {
          me.snackText = response.data;
          me.snackValue = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getRecibidos() {
      this.items2 = [];

      let me = this;

      this.reporteRecibidos.cia = this.strCia;
      this.reporteRecibidos.fechaInicio = this.dates[0].toString();
      this.reporteRecibidos.fechaFin = this.dates[1].toString();
      this.reporteRecibidos.docNombre = "Compras";
      this.reporteRecibidos.moneda = "";
      this.reporteRecibidos.tipoDocumento = "";
      this.reporteRecibidos.estadoHaciendaEmisor = "";
      this.reporteRecibidos.estadoAceptacion = "";

      for (const item of this.selectedMonedas) {
        this.reporteRecibidos.moneda += item.md_codigo + ",";
        this.reporteRecibidos.moneda += item + ",";
      }

      for (const item of this.selectedTiposDocumentos) {
        this.reporteRecibidos.tipoDocumento += item.tipoDocumento + ",";
        this.reporteRecibidos.tipoDocumento += item + ",";
      }

      for (const item of this.selectedEstadosHacienda) {
        this.reporteRecibidos.estadoHaciendaEmisor += item + ",";
      }

      for (const item of this.selectedEstadosAceptacion) {
        this.reporteRecibidos.estadoAceptacion += item + ",";
      }

      axios
        .post("/api/Reportes/getComprasReporte", this.reporteRecibidos)
        .then(function (response) {
          me.items2 = response.data;
          if (me.opcionesSistemaCia) {
            for (const opc of me.opcionesSistemaCia) {
              if (opc.opcionId === "correo_contador") {
                if (opc.valor === "true") {
                  me.validEnviarCorreo = true;
                }
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    downloadReport() {
      axios
        .get(
          "api/Reportes/getReporteContable?cia=" +
          this.strCia +
          "&fechaInicio=" +
          this.dates[0].toString() +
          "&fechaFin=" +
          this.dates[1].toString(),
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ReporteContable.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    closed() {
      this.customerModal = false;
    },
  },
  computed: {
    ...mapGetters(["user", "strCia"]),
    likesAllMonedas() {
      return this.selectedMonedas.length === this.monedas.length;
    },
    likesSomeMonedas() {
      return this.selectedMonedas.length > 0 && !this.likesAllMonedas;
    },
    icon() {
      if (this.likesAllMonedas) return "mdi-close-box";
      if (this.likesSomeMonedas) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    likesAllTiposDocumentos() {
      return (
        this.selectedTiposDocumentos.length === this.tiposDocumentos.length
      );
    },
    likesSomeTiposDocumentos() {
      return (
        this.selectedTiposDocumentos.length > 0 && !this.likesAllTiposDocumentos
      );
    },
    icon2() {
      if (this.likesAllTiposDocumentos) return "mdi-close-box";
      if (this.likesSomeTiposDocumentos) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    likesAllEstadosHacienda() {
      return (
        this.selectedEstadosHacienda.length === this.estadosHacienda.length
      );
    },
    likesSomeEstadosHacienda() {
      return (
        this.selectedEstadosHacienda.length > 0 && !this.likesAllEstadosHacienda
      );
    },
    icon3() {
      if (this.likesAllEstadosHacienda) return "mdi-close-box";
      if (this.likesSomeEstadosHacienda) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    likesAllEstadosAceptacion() {
      return (
        this.selectedEstadosAceptacion.length === this.estadosAceptacion.length
      );
    },
    likesSomeEstadosAceptacion() {
      return (
        this.selectedEstadosAceptacion.length > 0 &&
        !this.likesAllEstadosAceptacion
      );
    },
    icon4() {
      if (this.likesAllEstadosAceptacion) return "mdi-close-box";
      if (this.likesSomeEstadosAceptacion) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
};
</script>

<style>
.actionsCol .v-btn {
  margin-right: 10px;
}
</style>