<template>
    <v-container fluid>
        <!-- <v-toolbar color="primary" elevation="1">
            <v-btn icon color="white" @click="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar> -->
        <v-card max-width="400">
            <div v-html="tiqueteInfo.idTiquete" ref="element" id="ticket"></div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" hide-details @click="close()">
                    <v-icon left> mdi-close </v-icon>

                </v-btn>
                <v-btn color="primary" hide-details @click="print">
                    <v-icon left> mdi-printer </v-icon>

                </v-btn>
                <v-btn color="primary" hide-details @click="share">
                    <v-icon left> mdi-share-variant </v-icon>

                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";

export default {
    props: {
        tiqueteInfo: {
            idTiquete: "",
            tipoTiquete: "",
        },
        allwedOnly: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        itemsPerPage: 4,
        tiqueteReport: [],
        miHtml2: `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Tiquete</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      font-size: 11px;
    }
    .ticket {
      width: 250px;
      margin: 0 auto;
      /* border: 1px solid #000; */
      padding: 0px;
    }
    .header {
      font-weight: bold;
      text-align: center; 
      font-size: 12px;
    }
    .item {
      text-align: center; 
      margin-bottom: 1px;
      font-size: 11px;
    }
    .item:last-child {
      margin-bottom: 0;
    }
    .total {
      font-weight: bold;
      text-align: center; 
      font-size: 12px;
    }
    .footer {
      margin-top: 5px;
      text-align: center; 
    }
  </style>
</head>
<body>

<div class="ticket">
    <div class="header">
        <span>Tiquete</span>
    </div>
    <div class="header">
        <span>Mini Super S&D</span>
    </div>
    <div class="header">
        <span>Tel: 83733396</span>
    </div>

    <hr>
  
  <div class="item">
    <span>N Factura:</span> <span>F-0000000158</span>
  </div>
  <div class="item">
    <span>Fecha:</span> <span>05-04-2024</span>
  </div>
  <div class="item">
    <span>Cliente:</span> <span>Marcelo López Brenes</span>
  </div>

  <div class="line"></div>

  <hr>

<table class="item">
  <thead>
    <tr>
      <th>Producto</th>
      <th>Cant</th>
      <th>Precio</th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Coca Cola Zero 355ml</td>
      <td>2</td>
      <td>₡700.00</td>
      <td>₡791.00</td>
    </tr>
    <tr>
      <td>Fruta de pan, fresca o refrigerada</td>
      <td>1</td>
      <td>₡6,000.00</td>
      <td>₡6,780.00</td>
    </tr>
  </tbody>
</table>

<hr>

  <div class="item">
    <span>Sub Total:</span> <span>₡6,000.00</span>
  </div>

  <div class="item">
    <span>Descuentos:</span> <span>₡00.00</span>
  </div>

  <hr>

  <div class="item">
    <span>Sub Total:</span> <span>₡6,000.00</span>
  </div>
  <div class="item">
    <span>Impuestos:</span> <span>₡780.00</span>
  </div>

  <div class="total">
    <span>Total:</span> <span>₡6,780.00</span>
  </div>
  <hr>
  <div class="footer">
    <p>Gracias por su compra!</p>
  </div>
</div>

</body>
</html>
`,
    }),
    computed: {
        ...mapGetters(["strCia"]),
    },

    created() {
        //this.getTiqueteReport();
        this.$vuetify.theme.dark = false;
    },
    methods: {
        getTiqueteReport() {
            this.snackValue = false;
            let me = this;
            axios
                .get(
                    "api/Tiempos/GetTiquete" + this.tiqueteInfo.tipoTiquete + "Report?idTiquete=" + this.tiqueteInfo.idTiquete +
                    "&companyId=" +
                    this.strCia
                )
                .then(function (response) {
                    me.tiqueteReport = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        selected() {
            this.dialog = false;
            this.$emit("clicked");
        },

        close() {
            this.dialog = false;
            this.$emit("closed2");
        },

        share() {
            // iife here
            (async () => {
                if (!("share" in navigator)) {
                    console.log("Sharing not supported");
                    return;
                }
                // `element` is the HTML element you want to share.
                // `backgroundColor` is the desired background color.
                const canvas = await html2canvas(this.$refs.element);

                canvas.toBlob(async (blob) => {
                    // Even if you want to share just one file you need to
                    // send them as an array of files.
                    const files = [new File([blob], "image.png", { type: blob.type })];
                    const shareData = {
                        text: "",
                        title: "",
                        files,
                    };
                    if (navigator.canShare(shareData)) {
                        try {
                            await navigator.share(shareData);
                        } catch (err) {
                            if (err.name !== "AbortError") {
                                console.error(err.name, err.message);
                            }
                        }
                    } else {
                        console.warn("Sharing not supported", shareData);
                    }
                });
            })();
            this.selected();
        },

        printTicket() {
            // Use HTML2Canvas to generate an image of the ticket
            html2canvas(this.$refs.element).then(function (canvas) {
                const imageData = canvas.toDataURL('image/png');
                // Create a new window for printing
                const printWindow = window.open('', '_blank');
                printWindow.document.write('<img src="' + imageData + '">');
                // Print the ticket
                printWindow.print();
            });
        },

        printContent: function () {
            // var self = this;
            // Capture the target element
            html2canvas(this.$refs.element).then(function (canvas) {
                // Create an image from the captured canvas
                var imgData = canvas.toDataURL('image/png');
                // Create a new window for printing
                var printWindow = window.open('', '_blank');
                printWindow.document.open();
                printWindow.document.write('<html><head><title>Print</title></head><body>');
                //printWindow.document.write('<style>@page { size: 100mm 150mm; margin: 0; }</style>');
                //printWindow.document.write('<style>@page { size: 80mm auto; margin: 0; }</style>');
                printWindow.document.write('<style>@page {height:279mm; width:80mm; }</style>');
                printWindow.document.write('<img src="' + imgData + '" style="width: 100%; height: 100%;"/>');
                printWindow.document.write('</body></html>');
                printWindow.document.close();

                // Wait for image to load before printing
                printWindow.onload = function () {
                    printWindow.focus(); // Focus on new window
                    printWindow.print(); // Print the window
                    printWindow.close(); // Close the window after printing
                };
            });
        },

        print: function () {
            const element = window.document.getElementById("ticket");//const ticketElem = this.$refs.element;
            var printPreview = window.open('', 'print_preview');
            var printDocument = printPreview.document;
            printDocument.open();

            printDocument.write(`
          <html>
            <head>
              <title></title>
            </head>
            <body>
              ${element.innerHTML}
            </body>
          </html>
        `);
            printPreview.print();
            printPreview.close();
            this.selected();
        },
    },
};
</script>

<style>
body,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header {
    font-weight: bold;
    text-align: center;
    font-size: 10px;
}

.tickets-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px;
}

.tickets-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tickets-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.ticket-wrapper {
    width: 340px;
    padding: 5px;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }
}
</style>