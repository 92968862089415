<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Reporte de Casos
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" to="/home">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="4" md="3">
              <v-dialog
                ref="dialog"
                v-model="customerModal"
                :return-value.sync="dates"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Cliente"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    v-bind:value="customerName"
                    @click:clear="clearCustomer()"
                  ></v-text-field>
                </template>
                <Available
                  v-bind:allwedOnly="false"
                  @clicked="selected"
                  @closed="closed"
                />
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="4" md="3">
              <v-dialog
                ref="dialog"
                v-model="dateModal"
                :return-value.sync="dates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dates"
                    label="Periodo"
                    prepend-icon="mdi-calendar"
                    readonly
                    required
                    :rules="dateRules"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dates" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateModal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="3" sm="4">
              <v-select
                v-model="selectedStates"
                :items="states"
                item-text="name"
                item-value="bonusStateId"
                label="Estados"
                multiple
                chips
                persistent-hint
              ></v-select>
            </v-col>

            <v-col cols="12" md="3" sm="4">
              <v-select
                v-model="selectedSponsors"
                :items="sponsors"
                item-text="name"
                item-value="sponsorId"
                label="Promotores"
                multiple
                chips
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4" sm="4" class="actionsCol">
              <v-btn small :disabled="!valid" @click="getReport()"
                >Generar Reporte</v-btn
              >
                <vue-excel-xlsx
                  v-if="items.length > 0"
                  :data="items"
                  :columns="columns"
                  :filename="'Reporte_De_Caso'"
                  :sheetname="'Hoja'"
                  class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small"
                >
                  Descargar
                </vue-excel-xlsx>

            </v-col>
          </v-row>
        </v-form>

        <v-data-table :headers="headers" :items="items"> </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Available from "./caseComponents/AvailableCustomers";

export default {
  components: {
    Available,
  },
  data() {
    return {
      valid: false,
      dateModal: false,
      customerModal: false,
      dates: [],
      states: [],
      sponsors: [],
      selectedStates: [],
      selectedSponsors: [],
      items: [],
      dateRules: [
        (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
      ],
      headers: [
        {
          text: "Número de Caso",
          align: "start",
          value: "caseNumber",
        },
        {
          text: "Promotor",
          align: "start",
          value: "sponsorName",
        },
        {
          text: "Analista/Usuario",
          align: "start",
          value: "analyst_User",
        },
        {
          text: "Nombre cliente",
          align: "start",
          value: "clientName",
        },
        {
          text: "Dirección cliente",
          align: "start",
          value: "adressClient",
        },
        {
          text: "Teléfono cliente",
          align: "start",
          value: "phone",
        },
        {
          text: "Celular cliente",
          align: "start",
          value: "cellPhone",
        },
        {
          text: "Última Actualización",
          align: "start",
          value: "dateState",
        },
        {
          text: "Estado/Descripción",
          align: "start",
          value: "actualState",
        },
        {
          text: "Observaciones",
          align: "start",
          value: "observation",
        },
      ],
      columns: [
        {
          label: "Número de Caso",
          field: "caseNumber",
        },
        {
          label: "Promotor",
          field: "sponsorName",
        },
        {
          label: "Analista/Usuario",
          field: "analyst_User",
        },
        {
          label: "Nombre Cliente",
          field: "clientName",
        },
        {
          label: "Dirección Cliente",
          field: "adressClient",
        },
        {
          label: "Teléfono Cliente",
          field: "phone",
        },
        {
          label: "Celular Cliente",
          field: "cellPhone",
        },
        {
          label: "Última Actualización",
          field: "dateState",
        },
        {
          label: "Estado/Descripcion",
          field: "actualState",
        },
        {
          label: "Observaciones",
          field: "observation",
        },
      ],
      customer: {
        dni: "",
        name: "",
        profession: "",
        phone: "",
        cellPhone: "",
        salary: "",
        address: "",
        bonusType: 0,
        bonusName: "",
        maritalStatus: "",
        userId: 0,
        email: "",
      },
    };
  },

  created() {
    this.getStates();
    this.getSponsors();
  },

  methods: {
    getReport() {
      let me = this;
      let file;

      this.items = [];

      file = {
        fromDate: this.dates[0].toString(),
        toDate: this.dates[1].toString(),
        statuses: this.getAllStates(),
        sponsors: this.getAllSponsors(),
        client: this.getCustomer(),
      };

      axios
        .post("/api/Report/getCaseReport", file)
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getStates() {
      let me = this;
      axios
        .get("/api/Configuration/getBonusStateAll")
        .then(function (response) {
          me.states = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getSponsors() {
      let me = this;
      axios
        .get("api/Configuration/getSponsorAll")
        .then(function (response) {
          me.sponsors = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getAllStates() {
      return this.selectedStates.length > 0
        ? this.selectedStates
        : this.states.map((item) => item.bonusStateId);
    },

    getAllSponsors() {
      return this.selectedSponsors.length > 0
        ? this.selectedSponsors
        : this.sponsors.map((item) => item.sponsorId);
    },

    getCustomer() {
      return this.customer.dni.length > 0 ? this.customer.dni : "-1";
    },

    selected(value) {
      this.customer = Object.assign({}, value);
      this.customerModal = false;
    },

    clearCustomer() {
      console.log("clear");
      this.customer = {
        dni: "",
        name: "",
        profession: "",
        phone: "",
        cellPhone: "",
        salary: "",
        address: "",
        bonusType: 0,
        bonusName: "",
        maritalStatus: "",
        userId: 0,
        email: "",
      };
    },

    closed() {
      this.customerModal = false;
    },
  },
  computed: {
    customerName() {
      return this.customer.dni + " - " + this.customer.name;
    },
  },
};
</script>

<style>
.actionsCol .v-btn {
  margin-right: 10px;
}
</style>