<template>
    <div class="renderPage">
        <v-tabs background-color="primary" icons-and-text fixed-tabs center-active v-model="tab2">
            <v-tabs-slider></v-tabs-slider>
            <v-tab key="ingreso_registrados" @click="limpiar()">
                Salidas Registrados
                <v-icon>mdi-content-save</v-icon>
            </v-tab>
            <v-tab key="ingreso_nuevos" @click="limpiar()">
                Salida
                <v-icon>mdi-pencil-box</v-icon>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab2" class="ti">
            <v-tab-item key="ingreso_registrados">
                <v-spacer></v-spacer>
                <v-card>
                    <v-col cols="12" sm="12" md="12"> </v-col>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-dialog ref="dialog" v-model="dateModal" :return-value.sync="dates" persistent
                                    width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="datesFormat" label="Rango de fechas"
                                            prepend-icon="mdi-calendar" readonly required :rules="dateRules"
                                            v-bind="attrs" v-on="on" class="pa-0" outlined dense></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dates" range @click="setDatesFormat()">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateModal = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary" @click="
                                            $refs.dialog.save(dates);
                                        setDatesFormat();
                                        ">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" sm="2" md="2">
                                <v-btn color="primary" @click="getData()" :disabled="!valid">
                                    <v-icon left> mdi-file-find </v-icon>
                                    Buscar
                                </v-btn>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Filtrar resultados"
                                    single-line hide-details outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-data-table :headers="headers" :items="items" :search="search">
                        <template v-slot:top> </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <template v-slot:no-data>
                            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
                        </template></v-data-table>
                </v-card>
            </v-tab-item>
            <v-tab-item key="ingreso_nuevos">
                <v-form v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field readonly v-model="editedItem.codigo" label="Código" required class="pa-0"
                                    :disabled="incluirValid" outlined dense></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.descripcion" label="Descripción" :rules="nameRules"
                                    required class="pa-0" :disabled="incluirValid" outlined dense></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="3" md="3">
                                <v-text-field type="text" v-model="fecha" label="Fecha" class="pa-0" readonly outlined
                                    dense></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-card>
                                    <v-data-table hide-default-footer :headers="headersDetalle" :items="lineasDetalle"
                                        :search="search" :items-per-page="-1">
                                        <template v-slot:top>
                                            <v-toolbar height="48">
                                                <v-spacer></v-spacer>
                                                <template>
                                                    <v-dialog transition="dialog-bottom-transition"
                                                        v-model="dialogBuscarArticulos">
                                                        <div v-if="showNewDataArti">
                                                            <BuscarArticulos v-bind:allwedOnly="true"
                                                                @clicked="selectedArticulos" @closed="closed" />
                                                        </div>
                                                    </v-dialog>
                                                    <v-btn color="green" class="pa-0" :disabled="!btnAgregarArti"
                                                        @click="mostrarBuscarArticulos">
                                                        <v-icon color="white">mdi-cart-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-dialog v-model="dialogArticulos" max-width="700px">
                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="text-h5">{{ formTitle }}</span>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12" sm="5" md="3">
                                                                        <v-text-field
                                                                            v-model="detalleEditedItem.articulo"
                                                                            outlined dense label="Código"
                                                                            readonly></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="6" md="5">
                                                                        <v-text-field v-model="detalleEditedItem.cabys"
                                                                            label="Cabys" outlined dense
                                                                            readonly></v-text-field>
                                                                    </v-col>
                                                                    <!-- 
                                                                    <v-col cols="12" sm="2" md="2">
                                                                        <v-btn color="primary" class="pa-0"
                                                                            @click="mostrarBuscarArticulos">
                                                                            <v-icon>mdi-file-find</v-icon>
                                                                        </v-btn>
                                                                    </v-col> -->

                                                                    <v-dialog transition="dialog-bottom-transition"
                                                                        v-model="dialogBuscarArticulos">
                                                                        <div v-if="showNewDataArti">
                                                                            <BuscarArticulos v-bind:allwedOnly="true"
                                                                                @clicked="selectedArticulos"
                                                                                @closed="closed" />
                                                                        </div>
                                                                    </v-dialog>

                                                                    <v-col cols="12" sm="4" md="4">
                                                                        <v-text-field
                                                                            v-model="detalleEditedItem.cantidad"
                                                                            type="number" label="Cantidad" step="any"
                                                                            min="0" outlined dense></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="8" md="6">
                                                                        <v-text-field v-model="detalleEditedItem.descripcion
                                                                            " label="Descripción" outlined
                                                                            dense></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="5" md="3">
                                                                        <v-text-field v-model="detalleEditedItem.costo
                                                                            " label="Costo" type="number" step="any"
                                                                            min="0" outlined dense></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="5" md="3">
                                                                        <v-text-field v-model="detalleEditedItem.precio"
                                                                            label="Precio" type="number" step="any"
                                                                            min="0" outlined dense></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-card-text>

                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="primary" class="mb-2" @click="close">
                                                                Cancelar
                                                            </v-btn>
                                                            <v-btn color="primary" class="mb-2"
                                                                @click="saveDetalleArticulo">
                                                                Agregar
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                                <v-dialog v-model="dialogDelete" max-width="500px">
                                                    <v-card>
                                                        <v-card-title class="text-h5">Desea descartar el
                                                            articulo?</v-card-title>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="primary" class="mb-2"
                                                                @click="closeDelete">Cancelar</v-btn>
                                                            <v-btn color="primary" class="mb-2"
                                                                @click="deleteItemConfirm">OK</v-btn>
                                                            <v-spacer></v-spacer>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                            </v-toolbar>
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-btn color="primary" class="pa-0" @click="detalleEditItem(item)">
                                                <v-icon color="white"> mdi-pencil </v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:[`item.delete`]="{ item }">
                                            <v-btn color="red" class="pa-0" @click="deleteItem(item)">
                                                <v-icon color="white"> mdi-delete </v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div>
                        <v-expansion-panels multiple v-model="panel"> </v-expansion-panels>
                    </div>

                    <v-container>
                        <v-row align="center" justify="space-around"> </v-row>
                    </v-container>

                    <v-row align="center" justify="space-around">
                        <v-btn color="primary" :disabled="!incluirValid" @click="incluir()">
                            Incluir
                        </v-btn>
                        <v-btn color="primary" :disabled="!guardarValid" @click="save()">
                            Guardar
                        </v-btn>
                        <v-btn color="primary" :disabled="!cancelarValid" @click="cerrar()">
                            Cancelar
                        </v-btn>
                    </v-row>
                    <v-container>
                        <v-row align="center" justify="space-around"> </v-row>
                    </v-container>
                </v-form>
            </v-tab-item>
            <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
        </v-tabs-items>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
import BuscarArticulos from "./BuscarArticulos.vue";

export default {
    components: {
        Snackbar,
        BuscarArticulos,
    },
    // dark: true,
    data() {
        return {
            btnAgregarArti: false,
            iconVisible: false,
            iconVisibleCedula: false,
            visibleBtnConsultar: true,
            visibleProgress: false,
            dialog: false,
            dialogArticulos: false,
            valid: false,
            incluirValid: true,
            consultarEstadoValid: false,
            cancelarValid: false,
            reenviarValid: false,
            imprimirValid: false,
            guardarValid: false,
            tab2: 1,
            search: "",
            snackText: "",
            snackValue: false,
            dialogDelete: false,
            dateModal: false,
            dates: [],
            datesFormat: [],
            panel: [],
            headers: [
                {
                    text: "Código",
                    align: "start",
                    value: "codigo",
                },
                {
                    text: "Descripción",
                    align: "start",
                    value: "descripcion",
                },
                {
                    text: "Fecha",
                    align: "start",
                    value: "fechaString",
                },
                { text: "Editar", value: "actions", width: 50, sortable: false },
            ],
            headersDetalle: [
                {
                    text: "Código",
                    align: "start",
                    value: "articulo",
                },
                {
                    text: "Cabys",
                    align: "start",
                    value: "cabys",
                },
                {
                    text: "Descripción",
                    align: "start",
                    value: "descripcion",
                },
                {
                    text: "Cantidad",
                    align: "start",
                    value: "cantidad",
                },
                {
                    text: "Costo",
                    align: "start",
                    value: "costo",
                },
                {
                    text: "Precio",
                    align: "start",
                    value: "precio",
                },
                { text: "Editar", value: "actions", width: 50, sortable: false },
                { text: "Eliminar", value: "delete", width: 70, sortable: false },
            ],
            items: [],
            articulo: [],
            lineasDetalle: [],
            comprobante: [],
            editedIndex: -1,
            modelMovimiento: {
                movimiento: [],
                detalles: [],
            },
            editedItem: {
                cia: "",
                codigo: "",
                descripcion: "",
                fecha: new Date().toISOString().slice(0, 10),
                tipo: 2,
                fechaStr: "",
            },
            defaultItem: {
                cia: "",
                codigo: "",
                descripcion: "",
                fecha: Date,
                tipo: 2,
                fechaStr: "",
            },
            detalleditedIndex: -1,
            detalleEditedItem: {
                codigo: "",
                numLinea: 0,
                cantidad: 0,
                descripcion: "",
                costo: 0,
                precio: 0,
                costoStr: "",
                precioStr: "",
                articulo: "",
                cabys: "",
            },
            detalleDefaultItem: {
                codigo: "",
                numLinea: 0,
                cantidad: 0,
                descripcion: "",
                costo: 0,
                precio: 0,
                costoStr: "",
                precioStr: "",
                articulo: "",
                cabys: "",
            },
            opcionesSistemaCia: [],
            showNewData: false,
            showNewDataArti: false,
            nameRules: [(v) => !!v || "Campo requerido"],
            tempFile: "",
            modelFile: null,
        };
    },

    computed: {
        ...mapGetters(["user", "strCia"]),
        formTitle() {
            return this.editedIndex === -1 ? "Agregar Articulo" : "Editar Articulo";
        },
    },

    watch: {
        dialog(val) {
            val || this.close();
        },

        dialogArticulos(val) {
            val || this.close();
        },

        search() {
            this.snackValue = false;
        },
    },

    created() {
        this.setDates();
        this.getData();
    },

    methods: {
        signalChange: function (evt) {
            this.snackText = "Si llega al escribir" + evt;
            this.snackValue = true;
        },

        getData() {
            let me = this;
            axios
                .get(
                    "api/Articulos/getArticulosMovimientos?cia=" +
                    this.strCia +
                    "&tipo=2" +
                    "&fechaInicio=" +
                    this.dates[0].toString() +
                    "&fechaFin=" +
                    this.dates[1].toString(),
                    {
                        crossdomain: true,
                    }
                )
                .then(function (response) {
                    me.items = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        printDate: function () {
            return new Date().tolocal;
        },

        setDates() {
            var date = new Date();
            //var dateInicio = new Date(date.getFullYear(), date.getMonth(), 1);
            var dateInicio = new Date(date.getFullYear(), date.getMonth(), 1);
            //var dateFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            this.dates[0] = dateInicio.toISOString().substr(0, 10);
            this.dates[1] = date.toISOString().substr(0, 10);

            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var dt = date.getDate();

            if (dt < 10) {
                dt = "0" + dt;
            }
            if (month < 10) {
                month = "0" + month;
            }

            var yearInicio = dateInicio.getFullYear();
            var monthInicio = dateInicio.getMonth() + 1;
            var dtInicio = dateInicio.getDate();

            if (dtInicio < 10) {
                dtInicio = "0" + dtInicio;
            }
            if (monthInicio < 10) {
                monthInicio = "0" + monthInicio;
            }

            this.datesFormat[0] = dtInicio + "-" + monthInicio + "-" + yearInicio;
            this.datesFormat[1] = dt + "-" + month + "-" + year;
        },

        mostrarBuscarArticulos() {
            this.snackValue = false;
            this.dialogArticulos = false;
            this.dialogBuscarArticulos = true;
            this.showNewDataArti = true;
            this.cancelarValid = true;
        },

        editItem(item) {
            this.snackValue = false;
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.getModeloComprobante();
            this.tab2 = 1;
            this.cancelarValid = false;
            this.guardarValid = false;
            this.incluirValid = true;
        },

        detalleEditItem(item) {
            if (this.guardarValid) {
                this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
                this.detalleEditedItem = Object.assign({}, item);
                this.dialogArticulos = true;
            }
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
                this.detalleEditedIndex = -1;
            });
        },

        deleteItemConfirm() {
            if (this.guardarValid) {
                this.lineasDetalle.splice(this.detalleEditedIndex, 1);
                this.closeDelete();
            }
        },

        getModeloComprobante() {
            this.snackValue = false;
            let me = this;

            axios
                .get(
                    "api/Articulos/getModeloArticulosMovimientos?cia=" +
                    this.strCia +
                    "&codigo=" +
                    this.editedItem.codigo +
                    "&tipo=2"
                )
                .then(function (response) {
                    me.modelMovimiento = response.data;

                    me.editedItem = me.modelMovimiento.movimiento;
                    me.lineasDetalle = me.modelMovimiento.detalles;
                    me.fecha = me.editedItem.fechaString;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getOpcionesSistemaCia() {
            let me = this;
            axios
                .get("api/General/getOpcionesSistemaCia?cia=" + this.strCia)
                .then(function (response) {
                    me.opcionesSistemaCia = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        save() {
            this.snackValue = false;

            let me = this;
            this.visibleProgress = true;
            this.editedItem.fecha = new Date();
            this.editedItem.cia = this.strCia;
            this.modelMovimiento.movimiento = this.editedItem;
            this.modelMovimiento.detalles = this.lineasDetalle;

            axios
                .post("api/Articulos/insertArticulosMovimientos", this.modelMovimiento)
                .then(function () {
                    me.getData();
                })
                .catch(function (error) {
                    console.log(error);
                });

            this.cancelarValid = false;
            this.guardarValid = false;
            this.incluirValid = true;
            this.snackValue = false;
            this.btnAgregarArti = false;
        },

        saveDetalleArticulo() {
            if (this.detalleEditedIndex > -1) {
                Object.assign(
                    this.lineasDetalle[this.detalleEditedIndex],
                    this.detalleEditedItem
                );
            } else {
                this.lineasDetalle.push(this.detalleEditedItem);
            }
            this.close();
            this.cancelarValid = true;
            this.guardarValid = true;
            this.incluirValid = false;
            this.snackValue = false;
        },

        close() {
            this.snackValue = false;
            this.dialogArticulos = false;
            this.$nextTick(() => {
                this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
                this.detalleEditedIndex = -1;
            });
        },

        deleteItem(item) {
            if (this.guardarValid) {
                this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
                this.detalleEditedItem = Object.assign({}, item);
                this.dialogDelete = true;
            }
        },

        closed() {
            this.snackValue = false;
            this.showNewDataArti = false;
            this.dialog = false;
            this.dialogArticulos = false;
            this.dialogBuscarArticulos = false;
        },

        selectedArticulos(value) {
            this.articulo = Object.assign({}, value);
            this.showNewDataArti = false;
            this.dialogArticulos = true;
            this.detalleEditedItem.cia = this.strCia;
            this.detalleEditedItem.codigo = this.editedItem.codigo;
            this.detalleEditedItem.numLinea = 0;
            this.detalleEditedItem.tipo = 2;
            this.detalleEditedItem.costo = this.articulo.costo;
            this.detalleEditedItem.precio = this.articulo.monto;
            this.detalleEditedItem.descripcion = this.articulo.nombre;
            this.detalleEditedItem.cantidad = 1;
            this.detalleEditedItem.cabys = this.articulo.cabys;
            this.detalleEditedItem.articulo = this.articulo.codigo;
        },

        limpiar() {
            this.snackValue = false;
        },

        cerrar() {
            this.snackValue = false;
            this.dialog = false;
            this.dialogArticulos = false;
            this.$nextTick(() => {
                this.editedIndex = -1;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.valid = false;
                this.cancelarValid = false;
                this.guardarValid = false;
                this.incluirValid = true;
                this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
                this.lineasDetalle = [];
                this.btnAgregarArti = false;
            });
        },

        getFechaActual() {
            let me = this;
            axios
                .get("api/Facturacion/getFechaActualString")
                .then(function (response) {
                    me.fecha = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getCodigo() {
            let me = this;
            axios
                .get(
                    "api/Articulos/getCodigoArticulosMovimientos?cia=" +
                    this.strCia
                )
                .then(function (response) {
                    me.editedItem.codigo = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        incluir() {
            this.snackValue = false;
            this.$nextTick(() => {
                this.editedIndex = -1;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.getCodigo();
                this.cancelarValid = true;
                this.guardarValid = true;
                this.incluirValid = false;
                this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
                this.lineasDetalle = [];
                this.getFechaActual();
                this.btnAgregarArti = true;
            });
        },
    },
};
</script>

<style>
.cd {
    overflow: scroll;
}

.v-tabs-items {
    overflow: auto;
}

.botonSalir {
    position: fixed;
    top: 5px;
    right: 5px;
}

.right-input input {
    text-align: right;
}
</style>