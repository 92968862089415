<template>
  <div class="renderPage">
    <v-tabs
      background-color="primary"
      icons-and-text
      fixed-tabs
      center-active
      v-model="tab2"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab key="facturas"> Facturas </v-tab>
      <v-tab key="tiquetes"> Tiquetes </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="facturas">
        <v-data-table :headers="headers" :items="items" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Búsqueda"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn icon @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small @click="selected(item)"> Seleccionar </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item key="tiquetes">
        <v-data-table :headers="headers2" :items="items2" :search="search2">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search2"
                append-icon="mdi-magnify"
                label="Búsqueda"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn icon @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small @click="selected2(item)"> Seleccionar </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

    <!-- <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Seleccionar Factura
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: {
    allwedOnly: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      tab2: 0,
      search: "",
      search2: "",
      headers: [
        {
          text: "Código Factura",
          align: "start",
          value: "codigoFact",
        },
        {
          text: "Cliente",
          align: "start",
          value: "clienteNombre",
        },
        {
          text: "Fecha",
          align: "start",
          value: "fechaString",
        },
        {
          text: "Clave",
          align: "start",
          value: "clavehacienda",
        },
        {
          text: "Total",
          align: "start",
          value: "totalComprobante",
        },
        {
          text: "Estado",
          align: "start",
          value: "estadoHacienda",
        },
        { text: "Acciones", value: "actions", width: 100, sortable: false },
      ],
      headers2: [
        {
          text: "Código Tiquete",
          align: "start",
          value: "codigoFact",
        },
        {
          text: "Cliente",
          align: "start",
          value: "clienteNombre",
        },
        {
          text: "Fecha",
          align: "start",
          value: "fechaString",
        },
        {
          text: "Clave",
          align: "start",
          value: "clavehacienda",
        },
        {
          text: "Total",
          align: "start",
          value: "totalComprobante",
        },
        {
          text: "Estado",
          align: "start",
          value: "estadoHacienda",
        },
        { text: "Acciones", value: "actions", width: 100, sortable: false },
      ],
      items: [],
      items2: [],
      editedIndex: -1,
      editedItem: {
        cia: "",
        codigoFact: "",
        tipoFact: "",
        fecha: new Date().toISOString().slice(0, 10),
        tipoCambio: 1,
        clienteCod: "",
        clienteNombre: "",
        clienteCorreo: "",
        clienteCedula: "",
        clienteAreaTel: "",
        clienteTelefono: "",
        clienteNombreComercial: "",
        clienteProvincia: "",
        clienteCanton: "",
        clienteDistrito: "",
        clienteBarrio: "",
        clienteDireccion: "",
        clienteTipoId: "",
        mensajeHacienda: "",
        moneda: "",
        estadoHacienda: "",
        clavehacienda: "",
        subtotal: 0,
        totalDescuentos: 0,
        totalImpuesto: 0,
        totalComprobante: 0,
        codigoDev: "",
        estadoDev: 0,
        consHacienda: "",
        condicionVenta: "",
        medioPago: "",
        plazoCredito: 0,
        sucursal: "",
        terminal: "",
        xml: "",
        xmlFirmado: "",
        respuestaHacienda: "",
        fechaHacienda: Date,
        totalGravado: 0,
        totalExento: 0,
        consHacReal: "",
        cedulaReceptor: "",
        ordenCompra: "",
        observacion: "",
        usuario: "",
        enviado: false,
        reenviado: false,
        totalServGravados: 0,
        totalServExentos: 0,
        totalServExonerado: 0,
        totalMercanciasExentas: 0,
        totalMercanciasGravadas: 0,
        totalMercExonerada: 0,
        totalExonerado: 0,
        totalVenta: 0,
        totalVentaNeta: 0,
        totalIVADevuelto: 0,
        totalOtrosCargos: 0,
        totalIva1: 0,
        totalIva2: 0,
        totalIva4: 0,
        totalIva8: 0,
        totalIva13: 0,
        codigoActividad: "",
        emisorNombre: "",
        emisorNombreComercial: "",
        emisorTelefono: "",
        emisorCorreo: "",
        emisorDireccion: "",
        subTotal: 0,

        totalIVADevueltoStr: "",
        totalIva1Str: "",
        totalIva2Str: "",
        totalIva4Str: "",
        totalIva13Str: "",
        totalDescuentosStr: "",
        totalImpuestoStr: "",
        subtotalStr: "",
        totalComprobanteStr: "",
        nombreTabla: "",
        documentoOrigen: "",
        nombreTablaReferencia: "",
      },
      defaultItem: {
        cia: "",
        codigoFact: "",
        tipoFact: "",
        fecha: Date,
        tipoCambio: 1,
        clienteCod: "",
        clienteNombre: "",
        clienteCorreo: "",
        clienteCedula: "",
        clienteAreaTel: "",
        clienteTelefono: "",
        clienteNombreComercial: "",
        clienteProvincia: "",
        clienteCanton: "",
        clienteDistrito: "",
        clienteBarrio: "",
        clienteDireccion: "",
        clienteTipoId: "",
        mensajeHacienda: "",
        moneda: "",
        estadoHacienda: "",
        clavehacienda: "",
        subtotal: 0,
        totalDescuentos: 0,
        totalImpuesto: 0,
        totalComprobante: 0,
        codigoDev: "",
        estadoDev: 0,
        consHacienda: "",
        condicionVenta: "",
        medioPago: "",
        plazoCredito: 0,
        sucursal: "",
        terminal: "",
        xml: "",
        xmlFirmado: "",
        respuestaHacienda: "",
        fechaHacienda: Date,
        totalGravado: 0,
        totalExento: 0,
        consHacReal: "",
        cedulaReceptor: "",
        ordenCompra: "",
        observacion: "",
        usuario: "",
        enviado: false,
        reenviado: false,
        totalServGravados: 0,
        totalServExentos: 0,
        totalServExonerado: 0,
        totalMercanciasExentas: 0,
        totalMercanciasGravadas: 0,
        totalMercExonerada: 0,
        totalExonerado: 0,
        totalVenta: 0,
        totalVentaNeta: 0,
        totalIVADevuelto: 0,
        totalOtrosCargos: 0,
        totalIva1: 0,
        totalIva2: 0,
        totalIva4: 0,
        totalIva8: 0,
        totalIva13: 0,
        codigoActividad: "",
        emisorNombre: "",
        emisorNombreComercial: "",
        emisorTelefono: "",
        emisorCorreo: "",
        emisorDireccion: "",
        subTotal: 0,

        totalIVADevueltoStr: "",
        totalIva1Str: "",
        totalIva2Str: "",
        totalIva4Str: "",
        totalIva13Str: "",
        totalDescuentosStr: "",
        totalImpuestoStr: "",
        subtotalStr: "",
        totalComprobanteStr: "",
        nombreTabla: "",
        documentoOrigen: "",
        nombreTablaReferencia: "",
      },
    };
  },

  computed: {
    ...mapGetters(["strCia"]),
  },

  created() {
    this.getData();
    this.getData2();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get(
          "api/Facturacion/getComprobantesFacturasNC?cia=" +
            this.strCia +
            "&docNombre=Facturas&tipoDoc=01",
          {
            crossdomain: true,
          }
        )
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getData2() {
      let me = this;
      axios
        .get(
          "api/Facturacion/getComprobantesFacturasNC?cia=" +
            this.strCia +
            "&docNombre=Tiquetes&tipoDoc=04",
          {
            crossdomain: true,
          }
        )
        .then(function (response) {
          me.items2 = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    selected(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.documentoOrigen = "Factura Electrónica";
      this.editedItem.ordenCompra = "";
      this.editedItem.mensajeHacienda = "";
      this.editedItem.observacion = "";
      this.editedItem.estadoHacienda = "";
      this.editedItem.codigoDev = this.editedItem.clavehacienda;
      this.editedItem.clavehacienda = "";
      this.editedItem.nombreTabla = "NotasCredito";
      this.editedItem.NombreTablaReferencia = "Facturas";

      this.$emit("clicked", this.editedItem);
    },

    selected2(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.documentoOrigen = "Tiquete Electrónico";
      this.editedItem.ordenCompra = "";
      this.editedItem.mensajeHacienda = "";
      this.editedItem.observacion = "";
      this.editedItem.estadoHacienda = "";
      this.editedItem.codigoDev = this.editedItem.clavehacienda;
      this.editedItem.clavehacienda = "";
      this.editedItem.nombreTabla = "NotasCredito";
      this.editedItem.NombreTablaReferencia = "Tiquetes";

      this.$emit("clicked", this.editedItem);
    },

    close() {
      this.dialog = false;
      this.$emit("closed");
    },
  },
};
</script>