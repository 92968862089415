<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize"> Casos </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" to="/home">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            class="text-xs-center"
            v-model="search"
            append-icon="mdi-feature-search-outline"
            label="Búsqueda"
            single-line
            hide-details
            dense
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="showNewData = true"
              >
                Nuevo
              </v-btn>
            </template>

            <div v-if="showNewData">
              <Available
                v-bind:allwedOnly="true"
                @clicked="selected"
                @closed="closed"
              />
            </div>

            <div v-if="!showNewData">
              <v-card height="90vh" class="cd">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                  <v-spacer />
                  <v-btn color="secondary" fab x-small dark @click="close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-tabs fixed-tabs v-model="tab">
                    <v-tab key="general"> Casos </v-tab>
                    <v-tab key="nucleo" :disabled="disabledTab()"> Núcleo </v-tab>
                    <v-tab key="estados" :disabled="disabledTab()"> Estados </v-tab>
                    <v-tab key="terreno" :disabled="disabledTab()"> Terreno </v-tab>
                    <v-tab key="contruccion" :disabled="disabledTab()"> Construcción </v-tab>
                    <v-tab key="archivos" :disabled="disabledTab()"> Archivos </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item key="general" class="ti">
                      <General
                        v-bind:customer="customer"
                        v-bind:caseItem="editedItem"
                        @clicked="newCase"
                        @closed="close"
                      />
                    </v-tab-item>
                    <v-tab-item key="nucleo">
                    <!-- <v-tab-item key="nucleo" :is-disabled="1"> -->
                      <Nucleo v-bind:items="nucleo" @clicked="refreshCase" />
                    </v-tab-item>
                    <v-tab-item key="estados">
                      <Estados v-bind:items="states" @clicked="refreshCase" />
                    </v-tab-item>
                    <v-tab-item key="terreno">
                      <Terreno v-bind:item="lot" @clicked="refreshCase" />
                    </v-tab-item>
                    <v-tab-item key="contruccion">
                      <Construccion
                        v-bind:items="construction"
                        @clicked="refreshCase"
                      />
                    </v-tab-item>
                    <v-tab-item key="archivos">
                      <Archivos
                        v-bind:items="archivos"
                        @clicked="refreshCase"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </div>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-simple-checkbox v-model="item.type" disabled> </v-simple-checkbox>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
      </template> -->
    </v-data-table>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </div>
</template>

<script>
import axios from "axios";
import General from "./caseComponents/General";
import Nucleo from "./caseComponents/Nucleo";
import Estados from "./caseComponents/Estados";
import Terreno from "./caseComponents/Terreno";
import Construccion from "./caseComponents/Construccion.vue";
import Archivos from "./caseComponents/Archivos.vue";
import Available from "./caseComponents/AvailableCustomers";
import Snackbar from "../components/shared/Snackbar.vue";
import { mapMutations } from "vuex";

export default {
  components: {
    Snackbar,
    General,
    Nucleo,
    Estados,
    Terreno,
    Construccion,
    Archivos,
    Available,
  },
  data() {
    return {
      editedIndex: -1,
      dialog: false,
      valid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab: "",
      customer: {
        dni: "",
        name: "",
        profession: "",
        phone: "",
        cellPhone: "",
        salary: "",
        address: "",
        bonusType: 0,
        bonusName: "",
        maritalStatus: "",
        userId: 0,
        email: "",
      },
      showNewData: false,
      headers: [
        {
          text: "# Consecutivo",
          align: "start",
          value: "caseId",
          width: 150,
        },
        {
          text: "Cédula",
          align: "start",
          value: "dni",
          width: 100,
        },
        {
          text: "Cliente",
          align: "start",
          value: "clientName",
        },
        {
          text: "Proposito",
          align: "start",
          value: "purpose",
          width: 200,
        },
        { text: "Acciones", value: "actions", width: 100, sortable: false },
      ],
      items: [],
      nucleo: [],
      states: [],
      construction: [],
      archivos: [],
      lot: {
        id: 0,
        dni: "",
        countryNumber: "",
        volumeNumber: "",
        folioNumber: "",
        entryNumber: "",
        province: "",
        canton: "",
        district: "",
        address: "",
        area: "",
        planeNumber: "",
        userId: 0,
        caseId: 0,
      },
      editedItem: {
        caseId: 0,
        dni: "",
        caseNumber: "",
        request: "",
        postulation: "",
        purpose: "",
        program: 0,
        chiefName: "",
        clientName: "",
        sponsor: 0,
        state: 0,
        chiefDNI: "",
        entity: "",
        userId: 0,
      },
      defaultItem: {
        caseId: 0,
        dni: "",
        caseNumber: "",
        request: "",
        postulation: "",
        purpose: "",
        program: 0,
        chiefName: "",
        clientName: "",
        sponsor: 0,
        state: 0,
        chiefDNI: "",
        entity: "",
        userId: 0,
      },
      nameRules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          v.length <= 100 ||
          "El nombre del caracter debe de tener menos de 100 caracteres",
      ],
      passwordRules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          v.length <= 20 ||
          "La contraseña debe de tener menos de 20 caracteres",
      ],
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Caso" : "Editar Caso";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    ...mapMutations(["setStrCaseId"]),

    newData() {
      this.tab = "general";
      this.cleanData();
      this.showNewData = true;
    },

    getData() {
      let me = this;
      axios
        .get("/api/Case/getCaseAll")
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      let me = this;
      this.editedIndex = this.items.indexOf(item);
      this.showNewData = false;
      this.editedItem = Object.assign({}, item);
      this.setStrCaseId(item.caseId);

      axios
        .get("/api/Case/getCaseModel?caseId=" + item.caseId)
        .then(function (response) {
          me.editedItem = response.data.case;
          me.customer = response.data.client;

          if (response.data.client) {
            me.customer = response.data.client;
          }

          if (response.data.household) {
            me.nucleo = response.data.household;
          }

          if (response.data.states) {
            me.states = response.data.states;
          }

          if (response.data.lot) {
            me.lot = response.data.lot;
          }

          if (response.data.construction) {
            me.construction = response.data.construction;
          }

          if (response.data.archives) {
            me.archivos = response.data.archives;
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      this.dialog = true;
    },

    selected(value) {
      this.customer = Object.assign({}, value);
      this.showNewData = false;
    },

    closed() {
      this.showNewData = false;
      this.dialog = false;
    },

    close() {
      this.cleanData();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.setStrCaseId(0);
        this.tab = "general";
      });
    },

    newCase(value) {
      console.log(value);
      this.cleanData();
      this.editedItem.caseId = value;
      this.setStrCaseId(value);
      let me = this;
      axios
        .get("/api/Case/getCaseModel?caseId=" + value)
        .then(function (response) {
          me.getData();
          me.editedItem = response.data.case;
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log(value);
    },

    refreshCase(value) {
      console.log(value);
      this.cleanData();
      this.editedItem.caseId = value;
      //this.setStrCaseId(value);
      let me = this;
      axios
        .get("/api/Case/getCaseModel?caseId=" + value)
        .then(function (response) {
          me.editedItem = response.data.case;

          if (response.data.household) {
            me.nucleo = response.data.household;
            console.log(me.nucleo);
          }

          if (response.data.states) {
            me.states = response.data.states;
          }

          if (response.data.lot) {
            me.lot = response.data.lot;
          }

          if (response.data.construction) {
            me.construction = response.data.construction;
          }

          if (response.data.archives) {
            me.archivos = response.data.archives;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log(value);
    },

    cleanData() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.nucleo = [];
      this.states = [];
      this.construction = [];
      this.lot = {
        id: 0,
        dni: "",
        countryNumber: "",
        volumeNumber: "",
        folioNumber: "",
        entryNumber: "",
        province: "",
        canton: "",
        district: "",
        address: "",
        area: "",
        planeNumber: "",
        userId: 0,
        caseId: 0,
      };
    },
    disabledTab() {
      console.log(this.editedItem.caseId == 0);
     return this.editedItem.caseId == 0;
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}
</style>