<template dark>
  <div class="renderPage">
    <v-tabs
      background-color="primary"
      icons-and-text
      fixed-tabs
      center-active
      v-model="tab2"
    >
      <v-tabs-slider color="black"></v-tabs-slider>
      <v-tab key="proveedor_registrados" @click="limpiar()">
        Proveedores Registrados
        <v-icon>mdi-content-save</v-icon>
      </v-tab>
      <v-tab key="proveedor_nuevos" @click="limpiar()">
        Proveedor
        <v-icon>mdi-pencil-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="proveedor_registrados">
        <v-data-table :headers="headers" :items="items" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field
                class="text-xs-center"
                v-model="search"
                append-icon="mdi-magnify"
                label="Búsqueda"
                single-line
                hide-details
                dense
              >
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
          </template>
        </v-data-table>
        <!-- <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" /> -->
      </v-tab-item>
      <v-tab-item key="proveedor_nuevos">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  readonly
                  v-model="editedItem.codigo"
                  label="Código"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col col="12" md="3" sm="3">
                <v-select
                  label="Tipo Identificación"
                  item-text="descripcion"
                  item-value="tipoId"
                  v-model="editedItem.tipoId"
                  :items="tiposIdentificacion"
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  type="number"                  
                  class="pa-0 inputPrice"
                  v-model="editedItem.cedula"
                  label="Cedula"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col align="center" cols="12" sm="1" md="1">
                <v-btn
                  color="primary"
                  fab
                  small
                  dark
                  @click="getClienteHacienda"
                  :disabled="incluirValid"
                >
                  <v-icon>mdi-file-find</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  v-model="editedItem.esReceptor"
                  label="Es Receptor"
                  @click="changeEsReceptor"
                  class="pa-0"
                  :disabled="incluirValid"
                >
                </v-checkbox>
              </v-col>

              <v-col cols="12" sm="3" md="6">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Nombre"
                  :rules="nameRules"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="6">
                <v-text-field
                  v-model="editedItem.nombreComercial"
                  label="Nombre Comercial"
                  :rules="nameRules"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col col="12" md="3" sm="3">
                <v-select
                  label="Moneda"
                  item-text="md_nombre"
                  item-value="md_codigo"
                  v-model="editedItem.moneda"
                  :items="monedas"
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="3" md="6">
                <v-text-field
                  v-model="editedItem.correo"
                  label="Correo electrónico "
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="12">
                <v-card :disabled="!esRec">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <div class="text-overline mb-0">
                        Información Adicional
                      </div>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Provincia"
                          item-text="pr_nombre"
                          item-value="pr_codigo"
                          v-model="editedItem.provincia"
                          :items="provincias"
                          @change="changeProvincia"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Canton"
                          item-text="ct_nombre"
                          item-value="ct_codigo"
                          v-model="editedItem.canton"
                          :items="cantones"
                          @change="changeCantones"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Distrito"
                          item-text="dt_nombre"
                          item-value="dt_codigo"
                          v-model="editedItem.distrito"
                          :items="distritos"
                          @change="changeDistritos"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Barrio"
                          item-text="br_nombre"
                          item-value="br_codigo"
                          v-model="editedItem.barrio"
                          :items="barrios"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          type="number"                  
                          class="pa-0 inputPrice"
                          v-model="editedItem.areaTel"
                          label="Area Teléfono"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          type="number"                  
                          class="pa-0 inputPrice"
                          v-model="editedItem.telefono"
                          label="# Teléfono"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="2" md="6">
                        <v-textarea
                          v-model="editedItem.direccion"
                          label="Dirección"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="15"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-textarea>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>

          <v-row align="center" justify="space-around">
            <v-btn color="primary" :disabled="!incluirValid" @click="incluir()">
              Incluir
            </v-btn>
            <v-btn color="primary" :disabled="!guardarValid" @click="save()">
              Guardar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!eliminarValid"
              @click="deleteItem()"
            >
              Eliminar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!cancelarValid"
              @click="cerrar()"
              dark
            >
              Cancelar
            </v-btn>
          </v-row>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";

export default {
  components: {
    Snackbar,
    //Cabys,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      valid: false,
      incluirValid: true,
      cancelarValid: false,
      eliminarValid: false,
      guardarValid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab2: "",
      esRec: false,
      headers: [
        {
          text: "Código",
          align: "start",
          value: "codigo",
        },
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        {
          text: "Nombre Comercial",
          align: "start",
          value: "nombreComercial",
        },
        {
          text: "Cédula",
          align: "start",
          value: "cedula",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
      ],
      items: [],
      tiposIdentificacion: [],
      monedas: [],
      provincias: [],
      cantones: [],
      distritos: [],
      barrios: [],
      codigoArticulo: "",
      editedIndex: -1,
      editedItem: {
        codigo: "",
        nombre: "",
        moneda: "",
        provincia: "",
        canton: "",
        distrito: "",
        barrio: "",
        direccion: "",
        esReceptor: false,
        correo: "",
        tipoId: "",
        nombreComercial: "",
        areaTel: "",
        telefono: "",
        cedula: "",
        cia: "",
      },
      defaultItem: {
        codigo: "",
        nombre: "",
        moneda: "",
        provincia: "",
        canton: "",
        distrito: "",
        barrio: "",
        direccion: "",
        esReceptor: false,
        correo: "",
        tipoId: "",
        nombreComercial: "",
        areaTel: "",
        telefono: "",
        cedula: "",
        cia: "",
      },
      clienteHacienda: {
        nombre: "",
        tipoIdentificacion: "",
        situacion: {
          estado: "",
        },
      },
      showNewData: false,
      nameRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.getData();
    this.getTiposIdentificacion();
    this.getMonedas();
    this.getProvincias();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/Proveedores/getProveedoresByCompany?cia=" + this.strCia, {
          crossdomain: true,
        })
        //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      //this.snackValue = false
    },

    limpiar() {
      this.snackValue = false;
    },

    getCodigoCliente() {
      let me = this;
      axios
        .get("api/Proveedores/getCodigoProveedores?cia=" + this.strCia)
        .then(function (response) {
          //me.codigoArticulo = response.data;
          me.editedItem.codigo = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTiposIdentificacion() {
      let me = this;
      axios
        .get("api/General/getTiposIdentificacion")
        .then(function (response) {
          me.tiposIdentificacion = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeEsReceptor() {
      // this.snackText = "entra y trae: " + this.editedItem.esReceptor;
      // this.snackValue = true;
      if (this.editedItem.esReceptor === true) {
        this.esRec = true;
      } else {
        this.esRec = false;
      }
    },

    getBarrios() {
      let me = this;
      axios
        .get(
          "api/General/getBarrios?provincia=" +
            this.editedItem.provincia +
            "&canton=" +
            this.editedItem.canton +
            "&distrito=" +
            this.editedItem.distrito
        )
        .then(function (response) {
          me.barrios = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeDistritos() {
      this.getBarrios();
    },

    getDistritos() {
      let me = this;
      axios
        .get(
          "api/General/getDistritos?provincia=" +
            this.editedItem.provincia +
            "&canton=" +
            this.editedItem.canton
        )
        .then(function (response) {
          me.distritos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeCantones() {
      this.getDistritos();
    },

    getCantones() {
      let me = this;
      axios
        .get("api/General/getCantones?provincia=" + this.editedItem.provincia)
        .then(function (response) {
          me.cantones = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeProvincia() {
      this.getCantones();
    },

    getProvincias() {
      let me = this;
      axios
        .get("api/General/getProvincias")
        .then(function (response) {
          me.provincias = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMonedas() {
      let me = this;
      axios
        .get("api/General/getMonedas")
        .then(function (response) {
          me.monedas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getClienteHacienda() {
      let me = this;
      axios
        .get(
          "https://api.hacienda.go.cr/fe/ae?identificacion=" +
            me.editedItem.cedula
        )
        .then(function (response) {
          me.clienteHacienda = response.data;
          me.editedItem.nombre = me.clienteHacienda.nombre;
          me.editedItem.nombreComercial = me.clienteHacienda.nombre;
          me.editedItem.tipoId = me.clienteHacienda.tipoIdentificacion;

          if (me.clienteHacienda.situacion.estado === "Inscrito") {
            me.editedItem.esReceptor = true;
            me.esRec = true;
            me.editedItem.areaTel = "506";
          } else {
            me.editedItem.esReceptor = false;
            me.esRec = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.changeEsReceptor();
      this.changeProvincia();
      this.changeCantones();
      this.changeDistritos();
      this.tab2 = 1;
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
    },

    save() {
      let me = this;
      this.editedItem.cia = this.strCia;
      if (this.editedIndex > -1) {
        axios
          .post("api/Proveedores/updateProveedores", this.editedItem)
          .then(function () {
            me.snackText = "Registro modificado satisfactoriamente";
            me.snackValue = true;
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      } else {
        axios
          .post("api/Proveedores/insertProveedores", this.editedItem)
          .then(function () {
            me.snackText = "Registro agregado satisfactoriamente";
            me.snackValue = true;
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
      this.snackValue = false;
      this.changeEsReceptor();
    },

    deleteItem() {
      let me = this;
      if (confirm("¿Está seguro de que desea eliminar el articulo?")) {
        axios
          .get(
            "api/Proveedores/deleteProveedores?cia=" +
              this.strCia +
              "&codigo=" +
              this.editedItem.codigo
          )
          .then(function () {
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
        this.cerrar();
        this.tab2 = 0;
        this.esRec = false;
      }
    },

    closed() {
      this.showNewData = false;
      this.dialog = false;
    },

    cerrar() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.eliminarValid = false;
        this.guardarValid = false;
        this.incluirValid = true;
        this.esRec = false;
      });
    },

    incluir() {
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getCodigoCliente();
        this.cancelarValid = true;
        this.eliminarValid = false;
        this.guardarValid = true;
        this.incluirValid = false;
        this.esRec = false;
      });
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

.inputPrice input[type='number'] {
    -moz-appearance:textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>