<template>
  <div class="renderPage datatable-overflow">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-5">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Punto de Venta
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-row no-gutters>
      <!-- <v-col cols="4">
        <v-text-field
          v-model="codigoBarras"
          label="Código de Barras"
          required
          class="pa-0"
          outlined
          dense
          hide-details
          solo
          single-line
          @keyup.enter.native="onEnter"
          align="center"
        ></v-text-field>
      </v-col> -->

      <!-- <v-col cols="12">  <v-subheader class="pa-0"></v-subheader></v-col> -->

      <v-col cols="12" sm="3" md="3">
        <v-text-field v-model="codigoBarras" label="Código de Barras" required class="pa-0" outlined dense hide-details
          solo single-line @keyup.enter.native="onEnter" ref="barrasFocus" align="center"
          @change="changeValueComponent"></v-text-field>
      </v-col>

      <v-col align="center" cols="12" sm="1" md="1">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn align="center" class="mb-7" v-on="on" color="green" small fab @click="mostrarBuscarArticulos">
              <v-icon color="white">mdi-cart-plus</v-icon>
            </v-btn>
          </template>
          <span>F2 - Buscar Articulo</span>
        </v-tooltip>
      </v-col>

      <v-col cols="4"> <v-text-field v-model="editedItem.clienteNombre" label="Nombre" required class="pa-0" outlined
          dense hide-details solo single-line align="center"></v-text-field></v-col>

      <v-col align="center" cols="12" sm="1" md="1">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn align="center" class="mb-7" v-on="on" color="green" small fab @click="mostrarBuscarClientes">
              <v-icon color="white">mdi-account-search</v-icon>
            </v-btn>
          </template>
          <span>F4 - Buscar Cliente</span>
        </v-tooltip>
      </v-col>


      <v-dialog transition="dialog-bottom-transition" v-model="dialog">
        <div v-if="showNewData">
          <BuscarClientes v-bind:allwedOnly="true" @clicked="selected" @closed="closed" />
        </div>
      </v-dialog>

      <v-col cols="2"> </v-col>
      <!-- <v-col cols="2"> </v-col> -->

      <v-col cols="12" sm="1" md="1">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn align="center" class="mb-7" small fab color="primary" @click="mostrarInformacionAdicional" v-on="on"
              right>
              <v-icon>mdi-library-plus</v-icon>
            </v-btn>
          </template>
          <span>Datos Adicionales</span>
        </v-tooltip>
      </v-col>

      <v-col cols="9" sm="9" md="9">
        <div class="datatable-overflow">
          <v-data-table hide-default-footer :headers="headersDetalle" :items="lineasDetalle" :search="search"
            :items-per-page="-1" class="elevation-1 scroll-x" fixed-header height="65vh">
            <template v-slot:top>
              <v-toolbar height="1">
                <template>
                  <v-dialog transition="dialog-bottom-transition" v-model="dialogBuscarArticulos">
                    <div v-if="showNewDataArti">
                      <BuscarArticulos v-bind:allwedOnly="true" @clicked="selectedArticulos" @closed="closed" />
                    </div>
                  </v-dialog>
                </template>
                <v-dialog v-model="dialogArticulos" max-width="700px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="5" md="3">
                            <v-text-field v-model="detalleEditedItem.articulo" outlined dense label="Código"
                              readonly></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="detalleEditedItem.cabys" label="Cabys" outlined dense
                              readonly></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="2" md="2">
                            <v-btn color="primary" class="pa-0" @click="mostrarBuscarArticulos">
                              <v-icon>mdi-file-find</v-icon>
                            </v-btn>
                          </v-col>

                          <v-dialog transition="dialog-bottom-transition" v-model="dialogBuscarArticulos">
                            <div v-if="showNewDataArti">
                              <BuscarArticulos v-bind:allwedOnly="true" @clicked="selectedArticulos" @closed="closed" />
                            </div>
                          </v-dialog>

                          <v-col cols="12" sm="4" md="3">
                            <v-text-field v-model="detalleEditedItem.cantidad" type="number" label="Cantidad" step="any"
                              min="0" outlined dense></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="6">
                            <v-text-field v-model="detalleEditedItem.descripcion
                              " label="Descripción" outlined dense></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5" md="3">
                            <v-text-field v-model="detalleEditedItem.monto" label="Precio" type="number" step="any"
                              min="0" outlined dense></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5" md="3">
                            <v-text-field v-model="detalleEditedItem.porcDescuento
                              " label="% Descuento" type="number" step="any" min="0" outlined dense></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn color="primary" class="mb-2" @click="close">
                        Cancelar
                      </v-btn>
                      <v-btn color="primary" class="mb-2" @click="saveDetalleArticulo">
                        Agregar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">Desea descartar el articulo?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" class="mb-2" @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="primary" class="mb-2" @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn color="primary" class="pa-0" @click="detalleEditItem(item)">
                <v-icon color="white"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn color="red" class="pa-0" @click="deleteItem(item)">
                <v-icon color="white"> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </v-col>

      <v-col cols="3">
        <v-subheader class="text-h4 justify-center mb-11"></v-subheader>

        <v-subheader class="text-h4 justify-center mb-7">TOTAL A PAGAR</v-subheader>

        <v-subheader class="text-h2 justify-center mb-7" v-text="editedItem.totalComprobanteStr">0.00</v-subheader>

        <v-col lg="12">
          <v-btn class="justify-center mb-4" color="primary" @click="mostrarVentanaCobro()" block>
            Cobrar - F8
          </v-btn>
        </v-col>

        <v-col lg="12">
          <v-btn class="justify-center" color="primary" @click="cancelarVenta()" block>
            Nuevo - F9
          </v-btn>
        </v-col>

        <v-dialog v-model="dialogArticuloError" max-width="350px">
          <v-card prepend-icon="mdi-error" text="No existe ningún articulo con ese código de barras, por favor valide">
            <v-card-title>
              <span class="text-h4">Articulo no existe</span>
            </v-card-title>
            <v-card-text>No existe ningún articulo con ese código de barras, por favor valide
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="dialogArticuloError = false">
                <v-icon left> mdi-information </v-icon>
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogCobrar" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h4">Ventana de cobro</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="3" md="12">
                    <v-text-field v-model="editedItem.totalComprobante
                      " label="Total a Cobrar" outlined dense readonly class="pa-0" prefix="₡"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="12">
                    <v-text-field v-model="cobrarDatos.pagaCon" @change="changePagaCon" label="Paga con" outlined dense
                      prefix="₡" class="pa-0" ref="pagaConFocus" autofocus type="number"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="12">
                    <v-text-field v-model="cobrarDatos.vuelto
                      " label="Vuelto" prefix="₡" outlined dense readonly class="pa-0"></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" class="mb-2" @click="cancelarCobro">
                <v-icon left> mdi-close-circle-outline </v-icon>
                Cerrar
              </v-btn>

              <v-btn color="success" class="mb-2" @click="save">
                <v-icon left> mdi-check-circle-outline </v-icon>
                Cobrar
              </v-btn>

              <v-btn color="success" class="mb-2" @click="getTiqueteReport">
                <v-icon left> mdi-printer </v-icon>
                Tiquete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog transition="dialog-bottom-transition" v-model="dialogReport" max-width="400">
          <div v-if="showNewData">
            <TiqueteReport v-bind:allwedOnly="true" v-bind:tiqueteInfo="tiqueteInfo" @clicked="saveReport"
              @closed2="closed2" />
          </div>
        </v-dialog>

        <v-dialog v-model="dialogInformacionAdicional" max-width="700px">
          <v-card>
            <div v-html="miHtml2"></div>


            <v-card-title>
              <span class="text-h5">Información Adicional</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="12" sm="8" md="8">
                    <v-text-field v-model="editedItem.clientecorreo" label="Correo" class="pa-0" outlined
                      dense></v-text-field>
                  </v-col>

                  <v-col col="12" md="4" sm="4">
                    <v-select label="Condición Venta" item-text="descripcion" item-value="codigo"
                      v-model="editedItem.condicionVenta" :items="condicionesVenta" class="pa-0" outlined
                      dense></v-select>
                  </v-col>

                  <v-col col="12" md="4" sm="4">
                    <v-select label="Medio de pago" item-text="descripcion" item-value="codigo"
                      v-model="editedItem.medioPago" :items="mediosPago" class="pa-0" outlined dense></v-select>
                  </v-col>

                  <v-col col="12" md="4" sm="4">
                    <v-select label="Moneda" item-text="md_nombre" item-value="md_codigo" v-model="editedItem.moneda"
                      :items="monedas" @change="changeMonedas" class="pa-0" outlined dense></v-select>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <v-text-field v-model="editedItem.tipoCambio" label="Tipo de Cambio" class="pa-0" type="number"
                      step="any" min="1" outlined dense></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mb-2" @click="cancelarInformacionAdicional">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
import BuscarClientes from "./BuscarClientes.vue";
import BuscarArticulos from "./BuscarArticulos.vue";
import TiqueteReport from "./TiqueteReport.vue";
//import PagoSinpe from "./PagoSinpe.vue";

export default {
  components: {
    Snackbar,
    BuscarArticulos,
    BuscarClientes,
    TiqueteReport,
    //PagoSinpe,
  },
  // dark: true,
  data() {
    return {
      codigoBarras: "",
      miHtml: `<strong>Soy HTML</strong> y puedo
    <br>Ser
    <br>Inyectado
    <br>
    en Vue. Aquí un link:
    <a href="//parzibyte.me/blog">parzibyte.me/blog</a>`,
      miHtml2: `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Tiquete</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      font-size: 11px;
    }
    .ticket {
      width: 250px;
      margin: 0 auto;
      /* border: 1px solid #000; */
      padding: 5px;
    }
    .header {
      font-weight: bold;
      text-align: center; 
      font-size: 14px;
    }
    .item {
      text-align: center; 
      margin-bottom: 1px;
      font-size: 12px;
    }
    .item:last-child {
      margin-bottom: 0;
    }
    .total {
      font-weight: bold;
      text-align: center; 
      font-size: 12px;
    }
    .footer {
      margin-top: 5px;
      text-align: center; 
    }
  </style>
</head>
<body>

<div class="ticket">
    <div class="header">
        <span>Tiquete</span>
    </div>
    <div class="header">
        <span>Mini Super S&D</span>
    </div>
    <div class="header">
        <span>Tel: 83733396</span>
    </div>

    <hr>
  <!-- <div class="header">
    <h4>Tiquete</h4>
    <h4>Mini Super S&D</h4>
    <h4>Tel: 83733396</h4>
  </div> -->
  
  <div class="item">
    <span>N Factura:</span> <span>F-0000000158</span>
  </div>
  <div class="item">
    <span>Fecha:</span> <span>05-04-2024</span>
  </div>
  <div class="item">
    <span>Cliente:</span> <span>Marcelo López Brenes</span>
  </div>

  <div class="line"></div>

  <hr>

<table>
  <thead>
    <tr>
      <th>Producto</th>
      <th>Cant</th>
      <th>Precio</th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Coca Cola Zero 355ml</td>
      <td>2</td>
      <td>₡700.00</td>
      <td>₡791.00</td>
    </tr>
    <tr>
      <td>Fruta de pan, fresca o refrigerada</td>
      <td>1</td>
      <td>₡6,000.00</td>
      <td>₡6,780.00</td>
    </tr>
  </tbody>
</table>

<hr>

  <div class="item">
    <span>Sub Total:</span> <span>₡6,000.00</span>
  </div>

  <div class="item">
    <span>Descuentos:</span> <span>₡00.00</span>
  </div>

  <hr>

  <div class="item">
    <span>Sub Total:</span> <span>₡6,000.00</span>
  </div>
  <div class="item">
    <span>Impuestos:</span> <span>₡780.00</span>
  </div>

  <div class="total">
    <span>Total:</span> <span>₡6,780.00</span>
  </div>
  <hr>
  <div class="footer">
    <p>Gracias por su compra!</p>
  </div>
</div>

</body>
</html>
`,
      btnAgregarCliente: false,
      btnAgregarArti: false,
      iconVisible: false,
      iconVisibleCedula: false,
      visibleBtnConsultar: true,
      visibleProgress: false,
      dialogSinpe: false,
      dialog: false,
      dialogArticulos: false,
      dialogCobrar: false,
      dialogInformacionAdicional: false,
      dialogArticuloError: false,
      dialogReport: false,
      valid: false,
      incluirValid: true,
      consultarEstadoValid: false,
      cancelarValid: false,
      reenviarValid: false,
      imprimirValid: false,
      guardarValid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab2: 1,
      tabFactura: 0,
      dialogDelete: false,
      verPDF: false,
      verXMLFirmado: false,
      verXMLRespuesta: false,
      panel: [],
      panelResumen: [],
      licenciaValida: [],
      tiqueteInfo: {
        idTiquete: "",
        tipoTiquete: "",
      },
      headers: [
        {
          text: "Código",
          align: "start",
          value: "codigoFact",
        },
        {
          text: "Cliente",
          align: "start",
          value: "clienteNombre",
        },
        {
          text: "Fecha",
          align: "start",
          value: "fechaString",
        },
        {
          text: "Clave",
          align: "start",
          value: "clavehacienda",
        },
        {
          text: "Estado",
          align: "start",
          value: "estadoHacienda",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
      ],
      headersDetalle: [
        {
          text: "Cant",
          align: "start",
          value: "cantidad",
          class: "primary",
        },
        {
          text: "Descripción",
          align: "start",
          value: "descripcion",
          class: "primary",
        },
        {
          text: "Unidad",
          align: "start",
          value: "unidad",
          class: "primary",
        },
        {
          text: "Precio",
          align: "start",
          value: "montoStr",
          class: "primary",
        },
        {
          text: "Total",
          align: "start",
          value: "totalLineaStr",
          class: "primary",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false, class: "primary", },
        { text: "Eliminar", value: "delete", width: 70, sortable: false, class: "primary", },
      ],
      items: [],
      tiposIdentificacion: [],
      actividades: [],
      condicionesVenta: [],
      mediosPago: [],
      sucursales: [],
      terminales: [],
      monedas: [],
      provincias: [],
      cantones: [],
      distritos: [],
      barrios: [],
      cliente: [],
      articulo: [],
      totales: [],
      lineasDetalle: [],
      tipoCambio: [],
      respuestaHacienda: [],
      resultadoGuardarTiquete: false,
      comprobante: [],
      editedIndex: -1,
      modelFactura: {
        comprobante: [],
        comprobanteDetalle: [],
      },
      editedItem: {
        cia: "",
        codigoFact: "",
        tipoFact: "",
        fecha: new Date().toISOString().slice(0, 10),
        tipoCambio: 1,
        clienteCod: "",
        clienteNombre: "",
        clienteCorreo: "",
        clienteCedula: "",
        clienteAreaTel: "",
        clienteTelefono: "",
        clienteNombreComercial: "",
        clienteProvincia: "",
        clienteCanton: "",
        clienteDistrito: "",
        clienteBarrio: "",
        clienteDireccion: "",
        clienteTipoId: "",
        mensajeHacienda: "",
        moneda: "",
        estadoHacienda: "",
        clavehacienda: "",
        subtotal: 0,
        totalDescuentos: 0,
        totalImpuesto: 0,
        totalComprobante: 0,
        codigoDev: "",
        estadoDev: 0,
        consHacienda: "",
        condicionVenta: "",
        medioPago: "",
        plazoCredito: 0,
        sucursal: "",
        terminal: "",
        xml: "",
        xmlFirmado: "",
        respuestaHacienda: "",
        fechaHacienda: Date,
        totalGravado: 0,
        totalExento: 0,
        consHacReal: "",
        cedulaReceptor: "",
        ordenCompra: "",
        observacion: "",
        usuario: "",
        enviado: false,
        reenviado: false,
        totalServGravados: 0,
        totalServExentos: 0,
        totalServExonerado: 0,
        totalMercanciasExentas: 0,
        totalMercanciasGravadas: 0,
        totalMercExonerada: 0,
        totalExonerado: 0,
        totalVenta: 0,
        totalVentaNeta: 0,
        totalIVADevuelto: 0,
        totalOtrosCargos: 0,
        totalIva1: 0,
        totalIva2: 0,
        totalIva4: 0,
        totalIva8: 0,
        totalIva13: 0,
        codigoActividad: "",
        emisorNombre: "",
        emisorNombreComercial: "",
        emisorTelefono: "",
        emisorCorreo: "",
        emisorDireccion: "",
        subTotal: 0,
        totalIVADevueltoStr: "",
        totalIva1Str: "",
        totalIva2Str: "",
        totalIva4Str: "",
        totalIva13Str: "",
        totalDescuentosStr: "",
        totalImpuestoStr: "",
        subtotalStr: "",
        totalComprobanteStr: "0.00",
        nombreTabla: "",
        contingenciaFact: "",
        contingenciaFecha: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      },
      defaultItem: {
        cia: "",
        codigoFact: "",
        tipoFact: "",
        fecha: Date,
        tipoCambio: 1,
        clienteCod: "",
        clienteNombre: "",
        clienteCorreo: "",
        clienteCedula: "",
        clienteAreaTel: "",
        clienteTelefono: "",
        clienteNombreComercial: "",
        clienteProvincia: "",
        clienteCanton: "",
        clienteDistrito: "",
        clienteBarrio: "",
        clienteDireccion: "",
        clienteTipoId: "",
        mensajeHacienda: "",
        moneda: "",
        estadoHacienda: "",
        clavehacienda: "",
        subtotal: 0,
        totalDescuentos: 0,
        totalImpuesto: 0,
        totalComprobante: 0,
        codigoDev: "",
        estadoDev: 0,
        consHacienda: "",
        condicionVenta: "",
        medioPago: "",
        plazoCredito: 0,
        sucursal: "",
        terminal: "",
        xml: "",
        xmlFirmado: "",
        respuestaHacienda: "",
        fechaHacienda: Date,
        totalGravado: 0,
        totalExento: 0,
        consHacReal: "",
        cedulaReceptor: "",
        ordenCompra: "",
        observacion: "",
        usuario: "",
        enviado: false,
        reenviado: false,
        totalServGravados: 0,
        totalServExentos: 0,
        totalServExonerado: 0,
        totalMercanciasExentas: 0,
        totalMercanciasGravadas: 0,
        totalMercExonerada: 0,
        totalExonerado: 0,
        totalVenta: 0,
        totalVentaNeta: 0,
        totalIVADevuelto: 0,
        totalOtrosCargos: 0,
        totalIva1: 0,
        totalIva2: 0,
        totalIva4: 0,
        totalIva8: 0,
        totalIva13: 0,
        codigoActividad: "",
        emisorNombre: "",
        emisorNombreComercial: "",
        emisorTelefono: "",
        emisorCorreo: "",
        emisorDireccion: "",
        subTotal: 0,
        totalIVADevueltoStr: "",
        totalIva1Str: "",
        totalIva2Str: "",
        totalIva4Str: "",
        totalIva13Str: "",
        totalDescuentosStr: "",
        totalImpuestoStr: "",
        subtotalStr: "",
        totalComprobanteStr: "0.00",
        nombreTabla: "",
        contingenciaFact: "",
        contingenciaFecha: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      },
      ciaItem: {
        nombre: "",
        nombreComercial: "",
        cedula: "",
        provincia: "",
        canton: "",
        distrito: "",
        barrio: "",
        direccion: "",
        sucursal: "",
        terminal: "",
        moneda: "",
        moneda2: "",
        usuariohacienda: "",
        clavehacienda: "",
        propietarioCert: "",
        certificado: "",
        claveCert: "",
        esProd: false,
        correo: "",
        claveCcorreo: "",
        smtpCorreo: "",
        puertoCorreo: "",
        usaSeguridad: false,
        areaTel: "",
        telefono: "",
        tipoId: "",
        codigoActividad: "",
        image: "",
      },
      detalleditedIndex: -1,
      detalleEditedItem: {
        cia: "",
        factura: "",
        tipoFact: "",
        numLinea: 0,
        cantidad: 0,
        articulo: "",
        descripcion: "",
        unidad: "",
        monto: 0,
        descuento: 0,
        totalLinea: 0,
        impuestosVentas: 0,
        sucursal: "",
        terminal: "",
        tipoCodigo: "",
        unidadMedidaComercial: "",
        impCodigo: "",
        impCodigoTarifa: "",
        impTarifa: "",
        cabys: "",
        porcDescuento: 0,
        tipoCambio: 0,
        tipoProducto: "",
        montoTotal: 0,
        subTotal: 0,
        impuestoNeto: 0,
        moneda: "",
        montoStr: "",
        totalLineaStr: "",
        cant_Dev: 0,
        linea_Dev: 0,
        clienteCod: "",
        clienteCedula: "",
      },
      detalleDefaultItem: {
        cia: "",
        factura: "",
        tipoFact: "",
        numLinea: 0,
        cantidad: 0,
        articulo: "",
        descripcion: "",
        unidad: "",
        monto: 0,
        descuento: 0,
        totalLinea: 0,
        impuestosVentas: 0,
        sucursal: "",
        terminal: "",
        tipoCodigo: "",
        unidadMedidaComercial: "",
        impCodigo: "",
        impCodigoTarifa: "",
        impTarifa: "",
        cabys: "",
        porcDescuento: 0,
        tipoCambio: 0,
        tipoProducto: "",
        montoTotal: 0,
        subTotal: 0,
        impuestoNeto: 0,
        moneda: "",
        montoStr: "",
        totalLineaStr: "",
        cant_Dev: 0,
        linea_Dev: 0,
        clienteCod: "",
        clienteCedula: "",
      },
      pagoInfo: {
        sucursal: "",
        terminal: "",
        total: 0,
      },
      cobrarDatos: {
        vuelto: "",
        pagaCon: "",
      },
      opcionesSistemaCia: [],
      showNewData: false,
      showNewDataArti: false,
      nameRules: [(v) => !!v || "Campo requerido"],
      tempFile: "",
      modelFile: null,
    };
  },

  mounted() {
    this.scrollToElement();
    //this.focusInput();
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.barrasFocus.$refs.input.focus()
      })
    })
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
    formTitle() {
      return this.editedIndex === -1 ? "Agregar Articulo" : "Editar Articulo";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogArticulos(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.getCia();
    this.getMonedas();
    this.getMedioPago();
    this.getCondicionVenta();
    this.getOpcionesSistemaCia();
    this.getClientePOS();
    this.getCodigoTiquete();

    window.addEventListener('keydown', (e) => {
      if (e.key == 'F4') {
        console.log("se presionó F4");
        this.mostrarBuscarClientes();
      }
      if (e.key == 'F2') {
        console.log("se presionó F2");
        this.mostrarBuscarArticulos();
      }
      if (e.key == 'F8') {
        console.log("se presionó F8");
        this.mostrarVentanaCobro();
      }
      if (e.key == 'F9') {
        console.log("se presionó F9");
        this.cancelarVenta();
      }
    });
  },

  methods: {
    signalChange: function (evt) {
      this.snackText = "Si llega al escribir" + evt;
      this.snackValue = true;
    },

    mostrarVentanaCobro() {
      this.cobrarDatos.pagaCon = "";
      this.cobrarDatos.vuelto = "";
      this.dialogCobrar = true;
      this.$nextTick(() => {
        this.focusInputPagaCon();
      });
    },

    getTiqueteReport() {
      this.generarTiqueteReport();
      this.dialogCobrar = false;
      this.dialogReport = true;
      this.showNewData = true;
      //this.saveReport();
    },

    cancelarVenta() {
      if (confirm("¿Está seguro de que desea descartar la venta en proceso?")) {
        this.cerrar();
      }
    },

    mostrarInformacionAdicional() {
      this.dialogInformacionAdicional = true;
    },

    setFocus: function () {
      this.$refs.barrasFocus.focus();
    },

    focusInput() {
      this.$refs.barrasFocus.focus();
      //this.snackText = "el mae si llega aquí";
      //this.snackValue = true;
    },

    focusInputPagaCon() {
      this.$refs.pagaConFocus.focus();
    },

    scrollToElement() {
      const el = this.$refs.scrollToMe;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },

    onEnter: function () {
      // this.snackText = "on enter event: " + this.codigoBarras;
      this.snackValue = false;
      if (this.codigoBarras !== "") {
        let me = this;
        axios
          .get(
            "api/Articulos/getArticuloByCodigoBarras?cia=" +
            this.strCia +
            "&codigoBarras=" +
            this.codigoBarras,
            {
              crossdomain: true,
            }
          )
          //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
          .then(function (response) {
            me.articulo = response.data;

            if (me.articulo.length == 0) {
              me.dialogArticuloError = true;
              // me.snackText = "El articulo no existe, por favor valide";
              // me.snackValue = true;
            } else {
              me.detalleEditedItem.cia = me.strCia;
              //me.detalleEditedItem.factura = me.editedItem.codigoFact; 
              me.detalleEditedItem.tipoFact = "04";
              me.detalleEditedItem.numLinea = 0;
              me.detalleEditedItem.unidad = me.articulo.unidad;
              me.detalleEditedItem.descuento = 0;
              me.detalleEditedItem.totalLinea = 0;
              me.detalleEditedItem.sucursal = me.editedItem.sucursal;
              me.detalleEditedItem.terminal = me.editedItem.terminal;
              me.detalleEditedItem.tipoCodigo = "01";
              me.detalleEditedItem.unidadMedidaComercial = "M2";
              me.detalleEditedItem.impCodigo = me.articulo.codigoImp;
              me.detalleEditedItem.impCodigoTarifa = me.articulo.codigoTarifa;
              me.detalleEditedItem.impTarifa = me.articulo.tarifa;
              me.detalleEditedItem.porcDescuento = 0;
              me.detalleEditedItem.monto = me.articulo.monto;
              me.detalleEditedItem.descripcion = me.articulo.nombre;
              me.detalleEditedItem.cantidad = 1;
              me.detalleEditedItem.cabys = me.articulo.cabys;
              me.detalleEditedItem.articulo = me.articulo.codigo;
              me.detalleEditedItem.tipoCambio = me.editedItem.tipoCambio;
              me.detalleEditedItem.impuestosVentas = me.articulo.tarifa;
              me.detalleEditedItem.tipoProducto = me.articulo.tipoArt;
              me.detalleEditedItem.moneda = me.editedItem.moneda;
              me.detalleEditedItem.clienteCod = me.editedItem.clienteCod;
              me.detalleEditedItem.clienteCedula = me.editedItem.clienteCedula;
              me.saveDetalleArticulo();
              me.codigoBarras = "";
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    // setBarrasFocus() {
    //   this.$nextTick(() => this.$refs.barrasFocus.$el.focus())
    // },

    changeText() {
      this.iconVisible = true;
    },

    activarIconoCedula() {
      this.iconVisibleCedula = true;
    },

    getData() {
      let me = this;
      axios
        .get(
          "api/Facturacion/GetComprobantes?cia=" +
          this.strCia +
          "&docNombre=Tiquetes&tipoDoc=04",
          {
            crossdomain: true,
          }
        )
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    printDate: function () {
      return new Date().tolocal;
    },

    getActividades() {
      let me = this;
      axios
        .get("api/General/getActividadesByCia?cia=" + this.strCia)
        .then(function (response) {
          me.actividades = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getCondicionVenta() {
      let me = this;
      axios
        .get("api/General/getCondicionVenta")
        .then(function (response) {
          me.condicionesVenta = response.data;
          me.editedItem.condicionVenta = me.condicionesVenta[0]["codigo"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMedioPago() {
      let me = this;
      axios
        .get("api/General/getMediosPago")
        .then(function (response) {
          me.mediosPago = response.data;
          me.editedItem.medioPago = me.mediosPago[0]["codigo"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getCia() {
      let me = this;
      axios
        .get("api/Cias/getCia?ciaId=" + this.strCia)
        .then(function (response) {
          me.ciaItem = response.data;
          me.editedItem.emisorNombre = me.ciaItem.nombre;
          me.editedItem.emisorNombreComercial = me.ciaItem.nombreComercial;
          me.editedItem.codigoActividad = me.ciaItem.codigoActividad;
          me.editedItem.emisorTelefono = me.ciaItem.telefono;
          me.editedItem.emisorCorreo = me.ciaItem.correo;
          me.editedItem.emisorDireccion = me.ciaItem.direccion;
          me.editedItem.moneda = me.ciaItem.moneda;
          me.editedItem.cia = me.ciaItem.cedula;
          me.editedItem.fecha = me.printDate();

          me.getSucursales();
          me.pagoInfo.sucursal = me.ciaItem.sucursal;
          me.getTerminales(me.ciaItem.sucursal);
          me.editedItem.sucursal = me.ciaItem.sucursal;
          me.pagoInfo.terminal = me.ciaItem.terminal;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getSucursales() {
      let me = this;
      axios
        .get("api/Cias/getSucursalesByCia?cia=" + this.strCia)
        .then(function (response) {
          me.sucursales = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTerminales(sucursal) {
      let me = this;
      axios
        .get(
          "api/Cias/getTerminales?cia=" + this.strCia + "&sucursal=" + sucursal
        )
        .then(function (response) {
          me.terminales = response.data;
          me.editedItem.terminal = me.terminales[0]["terminalId"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getBarrios() {
      let me = this;
      axios
        .get(
          "api/General/getBarrios?provincia=" +
          this.editedItem.clienteProvincia +
          "&canton=" +
          this.editedItem.clienteCanton +
          "&distrito=" +
          this.editedItem.clienteDistrito
        )
        .then(function (response) {
          me.barrios = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeDistritos() {
      this.getBarrios();
    },

    getDistritos() {
      let me = this;
      axios
        .get(
          "api/General/getDistritos?provincia=" +
          this.editedItem.clienteProvincia +
          "&canton=" +
          this.editedItem.clienteCanton
        )
        .then(function (response) {
          me.distritos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeCantones() {
      this.getDistritos();
    },

    getCantones() {
      let me = this;
      axios
        .get(
          "api/General/getCantones?provincia=" +
          this.editedItem.clienteProvincia
        )
        .then(function (response) {
          me.cantones = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeProvincia() {
      this.getCantones();
    },

    changeSucursal() {
      this.getTerminales();
      this.pagoInfo.sucursal = this.editedItem.sucursal;
      this.pagoInfo.terminal = this.editedItem.terminal;
    },

    getProvincias() {
      let me = this;
      axios
        .get("api/General/getProvincias")
        .then(function (response) {
          me.provincias = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMonedas() {
      let me = this;
      axios
        .get("api/General/getMonedas")
        .then(function (response) {
          me.monedas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    imprimir() {
      this.snackValue = false;
      axios
        .get(
          "api/Facturacion/getPdf?cia=" +
          this.strCia +
          "&clave=" +
          this.editedItem.clavehacienda
        )
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            window.open(response.data + ".pdf", "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getClientePOS() {
      this.snackValue = false;
      let me = this;

      me.showNewData = false;
      axios
        .get(
          "api/Clientes/getClientePOS?cia=" +
          this.strCia
        )
        .then(function (response) {
          me.cliente = response.data;
          me.showNewData = false;
          me.dialog = false;
          me.dialogArticulos = false;
          me.dalogArticuloError = false;
          me.editedItem.clienteCod = me.cliente.codigo;
          me.editedItem.clienteNombre = me.cliente.nombre;
          me.editedItem.clientecorreo = me.cliente.correo;
          me.editedItem.clienteCedula = me.cliente.cedula;
          me.editedItem.clienteAreaTel = me.cliente.areaTel;
          me.editedItem.clienteTelefono = me.cliente.telefono;
          me.editedItem.clienteNombreComercial = me.cliente.nombreComercial;
          me.getProvincias();
          me.editedItem.clienteProvincia = me.cliente.provincia;
          me.getCantones();
          me.editedItem.clienteCanton = me.cliente.canton;
          me.getDistritos();
          me.editedItem.clienteDistrito = me.cliente.distrito;
          me.getBarrios();
          me.editedItem.clienteBarrio = me.cliente.barrio;
          me.editedItem.clienteDireccion = me.cliente.direccion;
          me.editedItem.clienteTipoId = me.cliente.tipoId;
        })
        .catch(function (error) {
          console.log(error);
        });

    },

    BuscarClienteCodigo() {
      this.snackValue = false;
      let me = this;
      if (me.editedItem.clienteCod !== "") {
        me.showNewData = false;
        axios
          .get(
            "api/Clientes/getCliente?cia=" +
            this.strCia +
            "&codigo=" +
            this.editedItem.clienteCod
          )
          .then(function (response) {
            me.cliente = response.data;
            me.showNewData = false;
            me.dialog = false;
            me.dialogArticulos = false;
            me.dalogArticuloError = false;
            me.editedItem.clienteCod = me.cliente.codigo;
            me.editedItem.clienteNombre = me.cliente.nombre;
            me.editedItem.clientecorreo = me.cliente.correo;
            me.editedItem.clienteCedula = me.cliente.cedula;
            me.editedItem.clienteAreaTel = me.cliente.areaTel;
            me.editedItem.clienteTelefono = me.cliente.telefono;
            me.editedItem.clienteNombreComercial = me.cliente.nombreComercial;
            me.getProvincias();
            me.editedItem.clienteProvincia = me.cliente.provincia;
            me.getCantones();
            me.editedItem.clienteCanton = me.cliente.canton;
            me.getDistritos();
            me.editedItem.clienteDistrito = me.cliente.distrito;
            me.getBarrios();
            me.editedItem.clienteBarrio = me.cliente.barrio;
            me.editedItem.clienteDireccion = me.cliente.direccion;
            me.editedItem.clienteTipoId = me.cliente.tipoId;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    BuscarClienteCedula() {
      this.snackValue = false;
      let me = this;
      if (me.editedItem.clienteCedula !== "") {
        me.showNewData = false;
        axios
          .get(
            "api/Clientes/getCliente?cia=" +
            this.strCia +
            "&cedula=" +
            this.editedItem.clienteCedula
          )
          .then(function (response) {
            me.cliente = response.data;
            me.showNewData = false;
            me.dialog = false;
            me.dialogArticulos = false;
            me.dalogArticuloError = false;
            me.editedItem.clienteCod = me.cliente.codigo;
            me.editedItem.clienteNombre = me.cliente.nombre;
            me.editedItem.clientecorreo = me.cliente.correo;
            me.editedItem.clienteCedula = me.cliente.cedula;
            me.editedItem.clienteAreaTel = me.cliente.areaTel;
            me.editedItem.clienteTelefono = me.cliente.telefono;
            me.editedItem.clienteNombreComercial = me.cliente.nombreComercial;
            me.getProvincias();
            me.editedItem.clienteProvincia = me.cliente.provincia;
            me.getCantones();
            me.editedItem.clienteCanton = me.cliente.canton;
            me.getDistritos();
            me.editedItem.clienteDistrito = me.cliente.distrito;
            me.getBarrios();
            me.editedItem.clienteBarrio = me.cliente.barrio;
            me.editedItem.clienteDireccion = me.cliente.direccion;
            me.editedItem.clienteTipoId = me.cliente.tipoId;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    mostrarBuscarClientes() {
      this.snackValue = false;
      this.dialog = true;
      this.showNewData = true;
      this.cancelarValid = true;
    },

    mostrarBuscarArticulos() {
      this.snackValue = false;
      this.dialogArticulos = false;
      this.dialogBuscarArticulos = true;
      this.showNewDataArti = true;
      this.cancelarValid = true;
    },

    // getClienteHacienda() {
    //   let me = this;
    //   axios
    //     .get(
    //       "https://api.hacienda.go.cr/fe/ae?identificacion=" +
    //         me.editedItem.cedula
    //     )
    //     .then(function (response) {
    //       me.clienteHacienda = response.data;
    //       me.editedItem.nombre = me.clienteHacienda.nombre;
    //       me.editedItem.nombreComercial = me.clienteHacienda.nombre;
    //       me.editedItem.tipoId = me.clienteHacienda.tipoIdentificacion;

    //       if (me.clienteHacienda.situacion.estado === "Inscrito") {
    //         me.editedItem.esProd = true;
    //         me.editedItem.areaTel = "506";
    //       } else {
    //         me.editedItem.esProd = false;
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    changeMonedas() {
      if (this.editedItem.moneda === "USD") {
        this.getTipoCambio();
      }
      if (this.editedItem.moneda === "CRC") {
        this.editedItem.tipoCambio = 0;
      }
    },

    changeValueComponent() {
      this.snackValue = false;
    },

    getTipoCambio() {
      let me = this;
      axios
        .get(
          "https://apis.gometa.org/tdc/tdc.json?fbclid=IwAR0ye7K1k---y1DMcYsWN7AMSpQAB_LPTcbQnSDY72FrnffmeR_zfDfYiO4",
          { crossdomain: true }
        )
        .then(function (response) {
          me.tipoCambio = response.data;
          me.editedItem.tipoCambio = me.tipoCambio.venta;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.snackValue = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.getModeloComprobante();
      this.editedItem.nombreTabla = "Tiquetes";
      this.getCia();
      this.getMonedas();
      this.getActividades();
      this.getCondicionVenta();
      this.getMedioPago();
      this.getSucursales();
      this.changeProvincia();
      this.changeCantones();
      this.changeDistritos();
      this.tab2 = 1;
      this.cancelarValid = false;

      if (
        this.editedItem.estadoHacienda === "ACEPTADO" ||
        this.editedItem.estadoHacienda === "RECHAZADO"
      ) {
        this.imprimirValid = true;
        this.reenviarValid = true;
        this.verPDF = true;
        this.verXMLFirmado = true;
        this.verXMLRespuesta = true;
      } else {
        this.imprimirValid = false;
        this.reenviarValid = false;
        this.verPDF = false;
        this.verXMLFirmado = false;
        this.verXMLRespuesta = false;
      }

      this.guardarValid = false;
      this.incluirValid = true;
      this.panel = [0];
      this.panelResumen = [0];
      if (this.editedItem.estadoHacienda === "PROCESANDO") {
        this.consultarEstadoValid = true;
      }
    },

    detalleEditItem(item) {
      if (this.guardarValid) {
        this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
        this.detalleEditedItem = Object.assign({}, item);
        this.dialogArticulos = true;
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.detalleEditedIndex = -1;
      });
    },

    deleteItemConfirm() {
      if (this.guardarValid) {
        this.lineasDetalle.splice(this.detalleEditedIndex, 1);
        this.closeDelete();

        let me = this;

        axios
          .post(
            "api/Facturacion/realizarCalculosComprobante",
            this.lineasDetalle
          )
          .then(function (response) {
            me.lineasDetalle = response.data.comprobanteDetalle;
            me.totales = response.data.comprobante;
            me.editedItem.totalIVADevuelto = 0; //validar esto
            me.editedItem.subTotal = me.totales.subtotal;
            me.editedItem.totalDescuentos = me.totales.totalDescuentos;
            me.editedItem.totalImpuesto = me.totales.totalImpuesto;
            me.editedItem.totalIva1 = me.totales.totalIva1;
            me.editedItem.totalIva2 = me.totales.totalIva2;
            me.editedItem.totalIva4 = me.totales.totalIva4;
            me.editedItem.totalIva13 = me.totales.totalIva13;
            me.editedItem.totalComprobante = me.totales.totalComprobante;

            me.editedItem.totalServGravados = me.totales.totalServGravados;
            me.editedItem.totalServExentos = me.totales.totalServExentos;
            me.editedItem.totalServExonerado = me.totales.totalServExonerado;
            me.editedItem.totalMercanciasGravadas =
              me.totales.totalMercanciasGravadas;
            me.editedItem.totalMercanciasExentas =
              me.totales.totalMercanciasExentas;
            me.editedItem.totalMercExonerada = me.totales.totalMercExonerada;
            me.editedItem.totalGravado = me.totales.totalGravado;
            me.editedItem.totalExento = me.totales.totalExento;
            me.editedItem.totalExonerado = me.totales.totalExonerado;
            me.editedItem.totalVenta = me.totales.totalVenta;
            me.editedItem.totalVentaNeta = me.totales.totalVentaNeta;
            me.editedItem.totalIVADevuelto = me.totales.totalIVADevuelto;
            me.editedItem.totalOtrosCargos = me.totales.totalOtrosCargos;
            me.editedItem.totalIva13 = me.totales.totalIva13;

            me.editedItem.totalIVADevueltoStr = me.totales.totalIVADevueltoStr; //validar esto
            me.editedItem.totalIva1Str = me.totales.totalIva1Str;
            me.editedItem.totalIva2Str = me.totales.totalIva2Str;
            me.editedItem.totalIva4Str = me.totales.totalIva4Str;
            me.editedItem.totalIva13Str = me.totales.totalIva13Str;
            me.editedItem.totalDescuentosStr = me.totales.totalDescuentosStr;
            me.editedItem.totalImpuestoStr = me.totales.totalImpuestoStr;
            me.editedItem.subtotalStr = me.totales.subtotalStr;
            me.editedItem.totalComprobanteStr = me.totales.totalComprobanteStr;
            //me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      }
    },

    consultarEstadoHacienda() {
      this.snackValue = false;
      this.visibleProgress = true;
      let me = this;

      this.comprobante = this.editedItem;

      axios
        .post("api/Facturacion/obtenerRespuestaHacienda", this.comprobante)
        .then(function (response) {
          me.respuestaHacienda = response.data;
          me.editedItem.estadoHacienda = me.respuestaHacienda.eInvoiceStatus;
          me.editedItem.mensajeHacienda = me.respuestaHacienda.details;
          me.visibleProgress = false;

          if (me.editedItem.estadoHacienda !== "PROCESANDO") {
            me.consultarEstadoValid = false;
          }

          if (
            me.editedItem.estadoHacienda === "ACEPTADO" ||
            me.editedItem.estadoHacienda === "RECHAZADO"
          ) {
            me.imprimirValid = true;
            me.reenviarValid = true;
            me.verPDF = true;
            me.verXMLFirmado = true;
            me.verXMLRespuesta = true;
          } else {
            me.imprimirValid = false;
            me.reenviarValid = false;
            me.verPDF = false;
            me.verXMLFirmado = false;
            me.verXMLRespuesta = false;
          }
          me.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    reenviarCorreo() {
      this.snackValue = false;
      let me = this;

      this.comprobante = this.editedItem;

      axios
        .post("api/Facturacion/reenviarCorreo", this.comprobante)
        .then(function (response) {
          me.snackText = response.data;
          me.snackValue = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getReport() {
      this.snackValue = false;
      axios
        .get(
          "api/Facturacion/getPdf?cia=" +
          this.strCia +
          "&clave=" +
          this.editedItem.clavehacienda
        )
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            window.open(response.data + ".pdf", "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getXMLFirmado() {
      this.snackValue = false;
      axios
        .get(
          "api/Facturacion/getPdf?cia=" +
          this.strCia +
          "&clave=" +
          this.editedItem.clavehacienda
        )
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            window.open(response.data + "_Signed.xml", "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getXMLRespuesta() {
      this.snackValue = false;
      axios
        .get(
          "api/Facturacion/getPdf?cia=" +
          this.strCia +
          "&clave=" +
          this.editedItem.clavehacienda
        )
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            window.open(response.data + "_Response.xml", "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getModeloComprobante() {
      this.snackValue = false;
      let me = this;

      axios
        .get(
          "api/Facturacion/getModeloComprobante?cia=" +
          this.strCia +
          "&clave=" +
          this.editedItem.clavehacienda +
          "&docNombre=Tiquetes"
        )
        .then(function (response) {
          me.modelFactura = response.data;

          me.editedItem = me.modelFactura.comprobante;
          me.lineasDetalle = me.modelFactura.comprobanteDetalle;
          me.fecha = me.editedItem.fechaString;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getOpcionesSistemaCia() {
      let me = this;
      axios
        .get("api/General/getOpcionesSistemaCia?cia=" + this.strCia)
        .then(function (response) {
          me.opcionesSistemaCia = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    save() {
      this.snackValue = false;
      for (const opc of this.opcionesSistemaCia) {
        if (opc.opcionId === "pagos_sinpe") {
          if (opc.valor === "true") {
            this.dialogSinpe = true;
            this.showNewData = true;
          }
        }
      }
      let me = this;
      this.visibleProgress = true;
      this.editedItem.fecha = new Date();
      this.editedItem.fechaHacienda = new Date();
      this.editedItem.cia = this.strCia;
      this.editedItem.usuario = this.user.id;

      this.modelFactura.comprobante = this.editedItem;
      this.modelFactura.comprobanteDetalle = this.lineasDetalle;

      this.pagoInfo.total = this.editedItem.totalComprobante;

      if (this.editedIndex > -1) {
        axios
          .post("api/Facturacion/saveComprobantePOS", this.modelFactura)
          .then(function (response) {
            //me.visibleProgress = false;
            me.resultadoGuardarTiquete = response.data;
            me.getCodigoTiquete();
            me.focusInput();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.editedItem.tipoFact = "04";
        this.editedItem.nombreTabla = "Tiquetes";
        axios
          .post("api/Facturacion/saveComprobantePOS", this.modelFactura)
          .then(function (response) {
            //me.visibleProgress = false;
            me.resultadoGuardarTiquete = response.data;
            me.getCodigoTiquete();
            me.focusInput();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.cancelarValid = false;
      this.guardarValid = false;
      this.incluirValid = true;
      this.snackValue = false;
      this.btnAgregarArti = false;
      this.btnAgregarCliente = false;
      this.focusInput();
      this.cerrar();
      this.incluir();
    },

    saveReport() {
      this.snackValue = false;
      for (const opc of this.opcionesSistemaCia) {
        if (opc.opcionId === "pagos_sinpe") {
          if (opc.valor === "true") {
            this.dialogSinpe = true;
            this.showNewData = true;
          }
        }
      }
      let me = this;
      this.visibleProgress = true;
      this.editedItem.fecha = new Date();
      this.editedItem.fechaHacienda = new Date();
      this.editedItem.cia = this.strCia;
      this.editedItem.usuario = this.user.id;

      this.modelFactura.comprobante = this.editedItem;
      this.modelFactura.comprobanteDetalle = this.lineasDetalle;

      this.pagoInfo.total = this.editedItem.totalComprobante;

      if (this.editedIndex > -1) {
        axios
          .post("api/Facturacion/saveComprobantePOS", this.modelFactura)
          .then(function (response) {
            me.resultadoGuardarTiquete = response.data;
            me.getCodigoTiquete();
            me.focusInput();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.editedItem.tipoFact = "04";
        this.editedItem.nombreTabla = "Tiquetes";
        axios
          .post("api/Facturacion/saveComprobantePOS", this.modelFactura)
          .then(function (response) {
            me.resultadoGuardarTiquete = response.data;
            me.getCodigoTiquete();
            me.focusInput();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.cancelarValid = false;
      this.guardarValid = false;
      this.incluirValid = true;
      this.snackValue = false;
      this.btnAgregarArti = false;
      this.btnAgregarCliente = false;
      this.focusInput();
      this.cerrar();
      this.dialogReport = false;
      //this.incluir();
    },

    scrollToBottom() {
      let element = document.getElementById("yourID");
      element.scrollIntoView(false);
    },

    saveDetalleArticulo() {
      if (this.detalleEditedIndex > -1) {
        Object.assign(
          this.lineasDetalle[this.detalleEditedIndex],
          this.detalleEditedItem
        );
      } else {
        this.lineasDetalle.unshift(this.detalleEditedItem);
      }
      this.close();
      //this.lineasDetalle.reverse();
      // if(this.lineasDetalle.length > 0){
      //   this.lineasDetalle.reverse();
      // }

      let me = this;
      if (this.detalleEditedIndex > -1) {
        axios
          .post(
            "api/Facturacion/realizarCalculosComprobante",
            this.lineasDetalle
          )
          .then(function (response) {
            me.lineasDetalle = response.data.comprobanteDetalle;
            //me.lineasDetalle.reverse();
            me.totales = response.data.comprobante;
            me.editedItem.totalIVADevuelto = 0; //validar esto

            me.editedItem.subTotal = me.totales.subtotal;
            me.editedItem.totalDescuentos = me.totales.totalDescuentos;
            me.editedItem.totalImpuesto = me.totales.totalImpuesto;
            me.editedItem.totalIva1 = me.totales.totalIva1;
            me.editedItem.totalIva2 = me.totales.totalIva2;
            me.editedItem.totalIva4 = me.totales.totalIva4;
            me.editedItem.totalIva13 = me.totales.totalIva13;
            me.editedItem.totalComprobante = me.totales.totalComprobante;

            me.editedItem.totalServGravados = me.totales.totalServGravados;
            me.editedItem.totalServExentos = me.totales.totalServExentos;
            me.editedItem.totalServExonerado = me.totales.totalServExonerado;
            me.editedItem.totalMercanciasGravadas =
              me.totales.totalMercanciasGravadas;
            me.editedItem.totalMercanciasExentas =
              me.totales.totalMercanciasExentas;
            me.editedItem.totalMercExonerada = me.totales.totalMercExonerada;
            me.editedItem.totalGravado = me.totales.totalGravado;
            me.editedItem.totalExento = me.totales.totalExento;
            me.editedItem.totalExonerado = me.totales.totalExonerado;
            me.editedItem.totalVenta = me.totales.totalVenta;
            me.editedItem.totalVentaNeta = me.totales.totalVentaNeta;
            me.editedItem.totalIVADevuelto = me.totales.totalIVADevuelto;
            me.editedItem.totalOtrosCargos = me.totales.totalOtrosCargos;
            me.editedItem.totalIva13 = me.totales.totalIva13;

            me.editedItem.totalIVADevueltoStr = me.totales.totalIVADevueltoStr; //validar esto
            me.editedItem.totalIva1Str = me.totales.totalIva1Str;
            me.editedItem.totalIva2Str = me.totales.totalIva2Str;
            me.editedItem.totalIva4Str = me.totales.totalIva4Str;
            me.editedItem.totalIva13Str = me.totales.totalIva13Str;
            me.editedItem.totalDescuentosStr = me.totales.totalDescuentosStr;
            me.editedItem.totalImpuestoStr = me.totales.totalImpuestoStr;
            me.editedItem.subtotalStr = me.totales.subtotalStr;
            me.editedItem.totalComprobanteStr = me.totales.totalComprobanteStr;
            me.focusInput();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .post(
            "api/Facturacion/realizarCalculosComprobante",
            this.lineasDetalle
          )
          .then(function (response) {
            me.lineasDetalle = response.data.comprobanteDetalle;
            //me.lineasDetalle.reverse();
            me.totales = response.data.comprobante;
            me.editedItem.totalIVADevuelto = 0; //validar esto
            me.editedItem.subTotal = me.totales.subtotal;
            me.editedItem.totalDescuentos = me.totales.totalDescuentos;
            me.editedItem.totalImpuesto = me.totales.totalImpuesto;
            me.editedItem.totalIva1 = me.totales.totalIva1;
            me.editedItem.totalIva2 = me.totales.totalIva2;
            me.editedItem.totalIva4 = me.totales.totalIva4;
            me.editedItem.totalIva13 = me.totales.totalIva13;
            me.editedItem.totalComprobante = me.totales.totalComprobante;

            me.editedItem.totalServGravados = me.totales.totalServGravados;
            me.editedItem.totalServExentos = me.totales.totalServExentos;
            me.editedItem.totalServExonerado = me.totales.totalServExonerado;
            me.editedItem.totalMercanciasGravadas =
              me.totales.totalMercanciasGravadas;
            me.editedItem.totalMercanciasExentas =
              me.totales.totalMercanciasExentas;
            me.editedItem.totalMercExonerada = me.totales.totalMercExonerada;
            me.editedItem.totalGravado = me.totales.totalGravado;
            me.editedItem.totalExento = me.totales.totalExento;
            me.editedItem.totalExonerado = me.totales.totalExonerado;
            me.editedItem.totalVenta = me.totales.totalVenta;
            me.editedItem.totalVentaNeta = me.totales.totalVentaNeta;
            me.editedItem.totalIVADevuelto = me.totales.totalIVADevuelto;
            me.editedItem.totalOtrosCargos = me.totales.totalOtrosCargos;
            me.editedItem.totalIva13 = me.totales.totalIva13;

            me.editedItem.totalIVADevueltoStr = me.totales.totalIVADevueltoStr; //validar esto
            me.editedItem.totalIva1Str = me.totales.totalIva1Str;
            me.editedItem.totalIva2Str = me.totales.totalIva2Str;
            me.editedItem.totalIva4Str = me.totales.totalIva4Str;
            me.editedItem.totalIva13Str = me.totales.totalIva13Str;
            me.editedItem.totalDescuentosStr = me.totales.totalDescuentosStr;
            me.editedItem.totalImpuestoStr = me.totales.totalImpuestoStr;
            me.editedItem.subtotalStr = me.totales.subtotalStr;
            me.editedItem.totalComprobanteStr = me.totales.totalComprobanteStr;
            me.focusInput();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.cancelarValid = true;
      this.imprimirValid = false;
      this.reenviarValid = false;
      this.verPDF = false;
      this.verXMLFirmado = false;
      this.verXMLRespuesta = false;
      this.guardarValid = true;
      this.incluirValid = false;
      this.snackValue = false;
      this.panelResumen = [0];
      this.consultarEstadoValid = false;
      this.changeValueComponent();
      this.setFocus();
    },

    close() {
      this.snackValue = false;
      this.dialogArticulos = false;
      this.dalogArticuloError = false;
      this.$nextTick(() => {
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.detalleEditedIndex = -1;
        this.focusInput();
      });
    },

    deleteItem(item) {
      if (this.guardarValid) {
        this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
        this.detalleEditedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },

    closed() {
      this.snackValue = false;
      this.showNewData = false;
      this.showNewDataArti = false;
      this.dialog = false;
      //this.dialogReport = false;
      this.dialogArticulos = false;
      this.dialogSinpe = false;
      this.dialogBuscarArticulos = false;
      this.dalogArticuloError = false;
    },

    closed2() {
      this.dialogReport = false;
    },

    selected(value) {
      this.setFocus();
      this.focusInput();
      this.cliente = Object.assign({}, value);
      this.showNewData = false;
      this.dialog = false;
      this.dialogArticulos = false;
      this.dalogArticuloError = false;
      this.editedItem.clienteCod = this.cliente.codigo;
      this.editedItem.clienteNombre = this.cliente.nombre;
      this.editedItem.clientecorreo = this.cliente.correo;
      this.editedItem.clienteCedula = this.cliente.cedula;
      this.editedItem.clienteAreaTel = this.cliente.areaTel;
      this.editedItem.clienteTelefono = this.cliente.telefono;
      this.editedItem.clienteNombreComercial = this.cliente.nombreComercial;
      this.getProvincias();
      this.editedItem.clienteProvincia = this.cliente.provincia;
      this.getCantones();
      this.editedItem.clienteCanton = this.cliente.canton;
      this.getDistritos();
      this.editedItem.clienteDistrito = this.cliente.distrito;
      this.getBarrios();
      this.editedItem.clienteBarrio = this.cliente.barrio;
      this.editedItem.clienteDireccion = this.cliente.direccion;
      this.editedItem.clienteTipoId = this.cliente.tipoId;
      this.btnAgregarArti = true;
      this.changeValueComponent();

      let me = this;
      me.setFocus();
      me.focusInput();
    },

    selectedArticulos(value) {
      this.articulo = Object.assign({}, value);
      this.showNewDataArti = false;
      this.dialogArticulos = true;
      this.dalogArticuloError = false;
      this.detalleEditedItem.cia = this.strCia;
      this.detalleEditedItem.factura = this.editedItem.codigoFact;
      this.detalleEditedItem.tipoFact = "04";
      this.detalleEditedItem.numLinea = 0;
      this.detalleEditedItem.unidad = this.articulo.unidad;
      this.detalleEditedItem.descuento = 0;
      this.detalleEditedItem.totalLinea = 0;
      this.detalleEditedItem.sucursal = this.editedItem.sucursal;
      this.detalleEditedItem.terminal = this.editedItem.terminal;
      this.detalleEditedItem.tipoCodigo = "01";
      this.detalleEditedItem.unidadMedidaComercial = "M2";
      this.detalleEditedItem.impCodigo = this.articulo.codigoImp;
      this.detalleEditedItem.impCodigoTarifa = this.articulo.codigoTarifa;
      this.detalleEditedItem.impTarifa = this.articulo.tarifa;
      this.detalleEditedItem.porcDescuento = 0;
      this.detalleEditedItem.monto = this.articulo.monto;
      this.detalleEditedItem.descripcion = this.articulo.nombre;
      this.detalleEditedItem.cantidad = 1;
      this.detalleEditedItem.cabys = this.articulo.cabys;
      this.detalleEditedItem.articulo = this.articulo.codigo;
      this.detalleEditedItem.tipoCambio = this.editedItem.tipoCambio;
      this.detalleEditedItem.impuestosVentas = this.articulo.tarifa;
      this.detalleEditedItem.tipoProducto = this.articulo.tipoArt;
      this.detalleEditedItem.moneda = this.editedItem.moneda;
      this.detalleEditedItem.clienteCod = this.editedItem.clienteCod;
      this.detalleEditedItem.clienteCedula = this.editedItem.clienteCedula;
      this.changeValueComponent();
    },

    limpiar() {
      this.snackValue = false;
    },

    cancelarCobro() {
      this.dialogCobrar = false;
      this.$nextTick(() => {
        this.focusInput();
      });
    },

    cancelarInformacionAdicional() {
      this.dialogInformacionAdicional = false;
      this.$nextTick(() => {
        this.focusInput();
      });
    },

    cerrar() {
      this.changeValueComponent();
      this.snackValue = false;
      this.dialog = false;
      this.dialogArticulos = false;
      this.dialogCobrar = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.imprimirValid = false;
        this.reenviarValid = false;
        this.verPDF = false;
        this.verXMLFirmado = false;
        this.verXMLRespuesta = false;
        this.guardarValid = false;
        this.incluirValid = true;
        this.consultarEstadoValid = false;
        this.panelResumen = [];
        this.panel = [];
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.lineasDetalle = [];
        this.btnAgregarArti = false;
        this.btnAgregarCliente = false;
        this.incluir();
        this.getClientePOS();
        this.getCodigoTiquete();
      });
    },

    onImageChange(ev) {
      this.tempFile = ev;
      this.editedItem.certificado = ev.name;
    },

    changePagaCon() {
      if (this.cobrarDatos.pagaCon > this.editedItem.totalComprobante) {
        this.cobrarDatos.vuelto = this.cobrarDatos.pagaCon - this.editedItem.totalComprobante;
      }
    },

    getCodigoTiquete() {
      let me = this;
      axios
        .get(
          "api/Facturacion/getComprobanteConsInterno?cia=" +
          this.strCia +
          "&docNombre=Tiquetes&tipoDoc=T"
        )
        .then(function (response) {
          me.editedItem.codigoFact = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getFechaActual() {
      let me = this;
      axios
        .get("api/Facturacion/getFechaActualString")
        .then(function (response) {
          me.fecha = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    validarLicencia() {
      let me = this;
      axios
        .get("api/General/licenciaValidar?cia=" + this.strCia)
        .then(function (response) {
          me.licenciaValida = response.data;
          if (me.licenciaValida.valido) {
            me.incluir();
          } else {
            me.snackText = me.licenciaValida.motivo;
            me.snackValue = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // getCodigoTiquete() {
    //   let me = this;
    //   axios
    //     .get(
    //       "api/Facturacion/getComprobanteConsInterno?cia=" +
    //       this.strCia +
    //       "&docNombre=Tiquetes&tipoDoc=T"
    //     )
    //     .then(function (response) {
    //       me.editedItem.codigoFact = response.data;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    incluir() {
      this.snackValue = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getCodigoTiquete();
        this.getCodigoTiquete();
        this.cancelarValid = true;
        this.imprimirValid = false;
        this.reenviarValid = false;
        this.verPDF = false;
        this.verXMLFirmado = false;
        this.verXMLRespuesta = false;
        this.guardarValid = true;
        this.incluirValid = false;
        this.consultarEstadoValid = false;
        this.getCia();
        this.getMonedas();
        this.getActividades();
        this.getCondicionVenta();
        this.getMedioPago();
        this.getSucursales();
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.lineasDetalle = [];
        this.editedItem.estadoHacienda = "PENDIENTE";
        this.panel = [0];
        this.btnAgregarCliente = true;
        this.getFechaActual();
        this.editedItem.clienteCod = "";
        this.BuscarClienteCodigo();
        this.btnAgregarArti = true;
      });
    },

    generarTiqueteReport() {
      const fecha = new Date().toLocaleDateString();
      // Construyendo la cadena HTML de la tabla con los valores dinámicos
      let tablaHTML = '<table class="item">';
      tablaHTML += '<thead><tr><th>Producto</th><th>Cant</th><th>Precio</th><th>Total</th></tr></thead>';
      tablaHTML += '<tbody>';

      // Agregando dinámicamente filas a la tabla para cada producto
      this.lineasDetalle.forEach(producto => {
        tablaHTML += `<tr>
                  <td>${producto.descripcion}</td>
                  <td>${producto.cantidad}</td>
                  <td>${producto.monto}</td>
                  <td>${producto.totalLinea}</td>
                </tr>`;
      });

      tablaHTML += '</tbody></table>';

      this.tiqueteInfo.idTiquete = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Tiquete</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      font-size: 13px;
    }
    .ticket {
      width: 250px;
      margin: 0 auto;
      /* border: 1px solid #000; */
      padding: 0px;
    }
    .header {
      font-weight: bold;
      text-align: center; 
      font-size: 13px;
    }
    .item {
      text-align: center; 
      margin-bottom: 1px;
      font-size: 12px;
    }
    .item:last-child {
      margin-bottom: 0;
    }
    .total {
      font-weight: bold;
      text-align: center; 
      font-size: 13px;
    }
    .footer {
      margin-top: 5px;
      text-align: center; 
    }
  </style>
</head>
<body>

<div class="ticket">
    <div class="header">
        <span>Tiquete</span>
    </div>
    <div class="header">
        <span>${this.editedItem.emisorNombreComercial}</span>
    </div>
    <div class="header">
        <span>${this.editedItem.emisorTelefono}</span>
    </div>
    <div class="header">
        <span>${this.editedItem.emisorCorreo}</span>
    </div>

    <hr>
  
  <div class="item">
    <span>Nº Tiquete:</span> <span>${this.editedItem.codigoFact}</span>
  </div>
  <div class="item">
    <span>Fecha:</span> <span>${fecha}</span>
  </div>
  <div class="item">
    <span>Cliente:</span> <span>${this.editedItem.clienteNombreComercial}</span>
  </div>

  <div class="line"></div>

  <hr>

  ${tablaHTML} <!-- Aquí se inserta la tabla dinámica -->

<hr>

  <div class="item">
    <span>Sub Total:</span> <span>${this.editedItem.subtotalStr}</span>
  </div>

  <div class="item">
    <span>Descuentos:</span> <span>${this.editedItem.totalDescuentosStr}</span>
  </div>

  <div class="item">
    <span>Impuestos:</span> <span>${this.editedItem.totalImpuestoStr}</span>
  </div>

  <div class="total">
    <span>Total:</span> <span>${this.editedItem.totalComprobanteStr}</span>
  </div>
  <hr>
  <div class="footer">
    <p>Gracias por su compra!</p>
  </div>
</div>

</body>
</html>
`;
    },

  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

.right-input input {
  text-align: right;
}

.datatable-overflow {
  overflow: visible !important;
}
</style>