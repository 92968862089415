import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/shared.css'

import axios from 'axios'

Vue.config.productionTip = false
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
//axios.defaults.baseURL = 'http://20.15.107.30:82/'
//axios.defaults.baseURL = 'http://localhost:54961/' este no
//axios.defaults.baseURL = 'http://localhost:52807/'
axios.defaults.baseURL = 'https://www.eduvafeapi.online/'

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: function (h) { return h(App) }
}).$mount('#app')
