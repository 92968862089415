<template>
  <div class="renderPage">
    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            class="text-xs-center"
            v-model="search"
            append-icon="mdi-feature-search-outline"
            label="Búsqueda"
            single-line
            hide-details
            dense
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nuevo
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="editedItem.area"
                          label="Área"
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="editedItem.jobs"
                          label="Trabajo"
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close()">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="blue darken-1"
                      text
                      @click="save()"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    items: [],
  },
  data() {
    return {
      dialog: false,
      valid: false,
      search: "",
      states: [],
      headers: [
        {
          text: "Área",
          align: "start",
          value: "area",
        },
        {
          text: "Trabajo",
          align: "start",
          value: "jobs",
        },
        { text: "Acciones", value: "actions", width: 100, sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        dni: "",
        area: "",
        jobs: "",
        caseId: 0,
      },
      defaultItem: {
        id: 0,
        dni: "",
        area: "",
        jobs: "",
        caseId: 0,
      },
      requiredRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapGetters(["user", "strCaseId"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Contrucción" : "Editar Contrucción";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("/api/Construction/getConstruction?caseId=" + this.strCaseId)
        .then(function (response) {
          if (response.data) {
            me.items = response.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    save() {
      let me = this;
      if (this.editedIndex > -1) {
        axios
          .post("/api/Construction/updateConstruction", this.editedItem)
          .then(function () {
            //me.getData();
            me.$emit("clicked", me.strCaseId);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.editedItem.caseId = this.strCaseId;
        axios
          .post("/api/Construction/insertConstruction", this.editedItem)
          .then(function () {
            //me.getData();
            me.$emit("clicked", me.strCaseId);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.close();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.$refs.form.resetValidation();
      });
    },
  },
};
</script>