import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    light: true ,
    themes: {
      dark: {
        primary: '#029151',
        secondary: '#9C27b0',
        accent: '#9C27b0',
        info: '#00CAE3',
      },
      light: {
        primary: '#029151',
        secondary: '#424242',
        tertiary: 'FFFFFF',
        accent: '#E6EDFF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      }
    },
    
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons:{
    logo: "../assets/icono.ico",
  },
});
