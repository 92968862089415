<template dark>
  <div class="renderPage">
    <v-tabs
      background-color="primary"
      icons-and-text
      fixed-tabs
      center-active
      v-model="tab2"
    >
      <v-tabs-slider color="black"></v-tabs-slider>
      <v-tab key="cl_registrados" @click="limpiar()">
        Clientes Registrados
        <v-icon>mdi-content-save</v-icon>
      </v-tab>
      <v-tab key="cl_nuevos" @click="limpiar()">
        Cliente
        <v-icon>mdi-pencil-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="cl_registrados">
        <v-data-table :headers="headers" :items="items" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field
                class="text-xs-center"
                v-model="search"
                append-icon="mdi-magnify"
                label="Búsqueda"
                single-line
                hide-details
                dense
              >
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
          </template>
        </v-data-table>
        <!-- <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" /> -->
      </v-tab-item>
      <v-tab-item key="cl_nuevos">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  readonly
                  v-model="editedItem.codigo"
                  label="Código"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col col="12" md="3" sm="3">
                <v-select
                  label="Tipo Identificación"
                  item-text="descripcion"
                  item-value="tipoId"
                  v-model="editedItem.tipoId"
                  :items="tiposIdentificacion"
                  class="pa-0"
                  :disabled="incluirValid"
                  @change="changeValueComponent"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  type="number"
                  class="pa-0 inputPrice"
                  v-model="editedItem.cedula"
                  label="Cedula"
                  :disabled="incluirValid"
                  @change="changeValueComponent"
                ></v-text-field>
              </v-col>

              <v-col align="center" cols="12" sm="1" md="1">
                <v-btn
                  color="primary"
                  fab
                  small
                  dark
                  @click="getClienteHacienda"
                  :disabled="incluirValid"
                >
                  <v-icon>mdi-file-find</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  v-model="editedItem.esReceptor"
                  label="Es Receptor"
                  @click="changeEsReceptor"
                  class="pa-0"
                  :disabled="incluirValid"
                  @change="changeValueComponent"
                >
                </v-checkbox>
              </v-col>

              <v-col cols="12" sm="3" md="6">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Nombre"
                  :rules="nameRules"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                  @change="changeValueComponent"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="6">
                <v-text-field
                  v-model="editedItem.nombreComercial"
                  label="Nombre Comercial"
                  :rules="nameRules"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                  @change="changeValueComponent"
                ></v-text-field>
              </v-col>

              <v-col col="12" md="3" sm="3">
                <v-select
                  label="Moneda"
                  item-text="md_nombre"
                  item-value="md_codigo"
                  v-model="editedItem.moneda"
                  :items="monedas"
                  class="pa-0"
                  :disabled="incluirValid"
                  @change="changeValueComponent"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="3" md="6">
                <v-text-field
                  v-model="editedItem.correo"
                  label="Correo electrónico "
                  class="pa-0"
                  :disabled="incluirValid"
                  @change="changeValueComponent"
                ></v-text-field>
              </v-col>

              <v-tabs
                background-color="primary"
                icons-and-text
                fixed-tabs
                center-active
                v-model="tabCliente"
                height="40"
              >
                <v-tabs-slider color="white"></v-tabs-slider>
                <v-tab key="informacion"> Ubicación </v-tab>
                <v-tab key="exoneracion"> Exoneración </v-tab>
              </v-tabs>

              <v-col cols="12" sm="12" md="12">
                <v-card>
                  <v-tabs-items v-model="tabCliente">
                    <v-tab-item key="informacion">
                      <v-card :disabled="!esRec">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-col col="12" md="3" sm="3">
                              <v-select
                                label="Provincia"
                                item-text="pr_nombre"
                                item-value="pr_codigo"
                                v-model="editedItem.provincia"
                                :items="provincias"
                                @change="changeProvincia"
                                class="pa-0"
                                :disabled="incluirValid"
                              ></v-select>
                            </v-col>

                            <v-col col="12" md="3" sm="3">
                              <v-select
                                label="Canton"
                                item-text="ct_nombre"
                                item-value="ct_codigo"
                                v-model="editedItem.canton"
                                :items="cantones"
                                @change="changeCantones"
                                class="pa-0"
                                :disabled="incluirValid"
                              ></v-select>
                            </v-col>

                            <v-col col="12" md="3" sm="3">
                              <v-select
                                label="Distrito"
                                item-text="dt_nombre"
                                item-value="dt_codigo"
                                v-model="editedItem.distrito"
                                :items="distritos"
                                @change="changeDistritos"
                                class="pa-0"
                                :disabled="incluirValid"
                              ></v-select>
                            </v-col>

                            <v-col col="12" md="3" sm="3">
                              <v-select
                                label="Barrio"
                                item-text="br_nombre"
                                item-value="br_codigo"
                                v-model="editedItem.barrio"
                                :items="barrios"
                                class="pa-0"
                                :disabled="incluirValid"
                              ></v-select>
                            </v-col>

                            <v-col cols="12" sm="3" md="3">
                              <v-text-field
                                type="number"
                                class="pa-0 inputPrice"
                                v-model="editedItem.areaTel"
                                label="Area Teléfono"
                                :disabled="incluirValid"
                                @change="changeValueComponent"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="3" md="3">
                              <v-text-field
                                type="number"
                                class="pa-0 inputPrice"
                                v-model="editedItem.telefono"
                                label="# Teléfono"
                                :disabled="incluirValid"
                                @change="changeValueComponent"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="2" md="6">
                              <v-textarea
                                v-model="editedItem.direccion"
                                label="Dirección"
                                auto-grow
                                outlined
                                rows="1"
                                row-height="15"
                                class="pa-0"
                                :disabled="incluirValid"
                                @change="changeValueComponent"
                              ></v-textarea>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-tab-item>

                    <v-tab-item key="exoneracion">
                      <!-- <v-card>
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-col cols="12" sm="3" md="3">
                              <v-checkbox
                                v-model="editedItem.esExonerado"
                                label="Es Exonerado"
                                @click="changeEsExonerado"
                                class="pa-0"
                                :disabled="incluirValid"
                              >
                              </v-checkbox>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card> -->
                      <v-card>
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-col cols="12" sm="3" md="3">
                              <v-checkbox
                                v-model="editedItem.esExonerado"
                                label="Es Exonerado"
                                @click="changeEsExonerado"
                                class="pa-0"
                                :disabled="incluirValid"
                                @change="changeValueComponent"
                              >
                              </v-checkbox>
                            </v-col>

                            <v-col cols="12" sm="9" md="9">
                              <v-subheader
                                >*Nota: Esta información solo aplica para
                                clientes que son exonerados</v-subheader
                              >
                            </v-col>

                            <v-col col="12" md="3" sm="3">
                              <v-select
                                label="Tipo Exoneración"
                                item-text="descripcion"
                                item-value="codigo"
                                v-model="editedItem.exoTipo"
                                :items="tiposExoneraciones"
                                class="pa-0"
                                :disabled="!esExo"
                                @change="changeValueComponent"
                              ></v-select>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                              <v-text-field
                                v-model="editedItem.exoNumDocumento"
                                label="Número Documento"
                                class="pa-0"
                                :disabled="!esExo"
                                @change="changeValueComponent"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="3" md="3">
                              <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="editedItem.exoFecha"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItem.exoFecha"
                                    label="Fecha Emisión"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!esExo"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editedItem.exoFecha"
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      $refs.dialog.save(editedItem.exoFecha)
                                    "
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-col>

                            <v-col cols="12" sm="3" md="3">
                              <v-text-field
                                type="number"
                                class="pa-0 inputPrice"
                                v-model="editedItem.exoPorcentaje"
                                label="Porcentaje"
                                :disabled="!esExo"
                                @change="changeValueComponent"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="7" md="7">
                              <v-text-field
                                v-model="editedItem.exoNomInstitucion"
                                label="Nombre Institución"
                                class="pa-0"
                                :disabled="!esExo"
                                @change="changeValueComponent"
                              ></v-text-field>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>

              <!-- <v-col cols="12" sm="3" md="12">
                <v-card :disabled="!esRec">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <div class="text-overline mb-0">
                        Información Adicional
                      </div>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Provincia"
                          item-text="pr_nombre"
                          item-value="pr_codigo"
                          v-model="editedItem.provincia"
                          :items="provincias"
                          @change="changeProvincia"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Canton"
                          item-text="ct_nombre"
                          item-value="ct_codigo"
                          v-model="editedItem.canton"
                          :items="cantones"
                          @change="changeCantones"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Distrito"
                          item-text="dt_nombre"
                          item-value="dt_codigo"
                          v-model="editedItem.distrito"
                          :items="distritos"
                          @change="changeDistritos"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Barrio"
                          item-text="br_nombre"
                          item-value="br_codigo"
                          v-model="editedItem.barrio"
                          :items="barrios"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          type="number"
                          class="pa-0 inputPrice"
                          v-model="editedItem.areaTel"
                          label="Area Teléfono"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          type="number"
                          class="pa-0 inputPrice"
                          v-model="editedItem.telefono"
                          label="# Teléfono"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="2" md="6">
                        <v-textarea
                          v-model="editedItem.direccion"
                          label="Dirección"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="15"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-textarea>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col> -->
            </v-row>
          </v-container>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>

          <v-row align="center" justify="space-around">
            <v-btn color="primary" :disabled="!incluirValid" @click="incluir()">
              Incluir
            </v-btn>
            <v-btn color="primary" :disabled="!guardarValid" @click="save()">
              Guardar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!eliminarValid"
              @click="deleteItem()"
            >
              Eliminar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!cancelarValid"
              @click="cerrar()"
              dark
            >
              Cancelar
            </v-btn>
          </v-row>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
//import Cabys from "./Cabys.vue";

export default {
  components: {
    Snackbar,
    //Cabys,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      valid: false,
      incluirValid: true,
      cancelarValid: false,
      eliminarValid: false,
      guardarValid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab2: "",
      esRec: false,
      esExo: false,
      tabCliente: 0,
      modal: false,
      headers: [
        {
          text: "Código",
          align: "start",
          value: "codigo",
        },
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        {
          text: "Nombre Comercial",
          align: "start",
          value: "nombreComercial",
        },
        {
          text: "Cédula",
          align: "start",
          value: "cedula",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
      ],
      items: [],
      tiposIdentificacion: [],
      tiposExoneraciones: [],
      monedas: [],
      provincias: [],
      cantones: [],
      distritos: [],
      barrios: [],
      codigoArticulo: "",
      editedIndex: -1,
      editedItem: {
        codigo: "",
        nombre: "",
        moneda: "",
        provincia: "",
        canton: "",
        distrito: "",
        barrio: "",
        direccion: "",
        esReceptor: false,
        correo: "",
        tipoId: "",
        nombreComercial: "",
        areaTel: "",
        telefono: "",
        cedula: "",
        cia: "",
        esExonerado: false,
        exoTipo: "",
        exoNumDocumento: "",
        exoNomInstitucion: "",
        exoFecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        exoPorcentaje: 0,
      },
      defaultItem: {
        codigo: "",
        nombre: "",
        moneda: "",
        provincia: "",
        canton: "",
        distrito: "",
        barrio: "",
        direccion: "",
        esReceptor: false,
        correo: "",
        tipoId: "",
        nombreComercial: "",
        areaTel: "",
        telefono: "",
        cedula: "",
        cia: "",
        esExonerado: false,
        exoTipo: "",
        exoNumDocumento: "",
        exoNomInstitucion: "",
        exoFecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        exoPorcentaje: 0,
      },
      clienteHacienda: {
        nombre: "",
        tipoIdentificacion: "",
        situacion: {
          estado: "",
        },
      },
      showNewData: false,
      nameRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.getData();
    this.getTiposIdentificacion();
    this.getTiposExoneracion();
    this.getMonedas();
    this.getProvincias();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/Clientes/getClientesByCompany?cia=" + this.strCia, {
          crossdomain: true,
        })
        //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      //this.snackValue = false
    },

    limpiar() {
      this.snackValue = false;
    },

    getCodigoCliente() {
      let me = this;
      axios
        .get("api/Clientes/getCodigoCliente?cia=" + this.strCia)
        .then(function (response) {
          //me.codigoArticulo = response.data;
          me.editedItem.codigo = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTiposIdentificacion() {
      let me = this;
      axios
        .get("api/General/getTiposIdentificacion")
        .then(function (response) {
          me.tiposIdentificacion = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTiposExoneracion() {
      let me = this;
      axios
        .get("api/General/getTipoExoneraciones")
        .then(function (response) {
          me.tiposExoneraciones = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeEsReceptor() {
      // this.snackText = "entra y trae: " + this.editedItem.esReceptor;
      // this.snackValue = true;
      if (this.editedItem.esReceptor === true) {
        this.esRec = true;
      } else {
        this.esRec = false;
      }
    },

    changeEsExonerado() {
      if (this.editedItem.esExonerado === true) {
        this.esExo = true;
      } else {
        this.esExo = false;
      }
    },

    getBarrios() {
      let me = this;
      axios
        .get(
          "api/General/getBarrios?provincia=" +
            this.editedItem.provincia +
            "&canton=" +
            this.editedItem.canton +
            "&distrito=" +
            this.editedItem.distrito
        )
        .then(function (response) {
          me.barrios = response.data;

          if (me.guardarValid === true) {
            me.editedItem.barrio = "01";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeDistritos() {
      this.getBarrios();
      this.changeValueComponent();
    },

    getDistritos() {
      let me = this;
      axios
        .get(
          "api/General/getDistritos?provincia=" +
            this.editedItem.provincia +
            "&canton=" +
            this.editedItem.canton
        )
        .then(function (response) {
          me.distritos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeCantones() {
      this.getDistritos();
      this.changeValueComponent();
    },

    getCantones() {
      let me = this;
      axios
        .get("api/General/getCantones?provincia=" + this.editedItem.provincia)
        .then(function (response) {
          me.cantones = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeProvincia() {
      this.getCantones();
      this.changeValueComponent();
    },

    getProvincias() {
      let me = this;
      axios
        .get("api/General/getProvincias")
        .then(function (response) {
          me.provincias = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMonedas() {
      let me = this;
      axios
        .get("api/General/getMonedas")
        .then(function (response) {
          me.monedas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getClienteHacienda() {
      let me = this;
      axios
        .get(
          "https://api.hacienda.go.cr/fe/ae?identificacion=" +
            me.editedItem.cedula
        )
        .then(function (response) {
          me.clienteHacienda = response.data;
          me.editedItem.nombre = me.clienteHacienda.nombre;
          me.editedItem.nombreComercial = me.clienteHacienda.nombre;
          me.editedItem.tipoId = me.clienteHacienda.tipoIdentificacion;

          if (me.clienteHacienda.situacion.estado === "Inscrito") {
            me.editedItem.esReceptor = true;
            me.esRec = true;
            me.editedItem.areaTel = "506";
          } else {
            me.editedItem.esReceptor = false;
            me.editedItem.areaTel = "";
            me.esRec = false;
          }

          //   me.snackText = "estado hacienda"+ me.clienteHacienda.situacion.estado;
          //   me.snackValue = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.snackValue = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.changeEsReceptor();
      this.changeProvincia();
      this.changeCantones();
      this.changeDistritos();
      this.tab2 = 1;
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
    },

    // mostrarCabys() {
    //   this.dialog = true;
    //   this.showNewData = true;
    //   this.cancelarValid = true;
    // },

    save() {
      this.snackValue = false;
      let me = this;
      this.editedItem.cia = this.strCia;
      var correcto = true;
      var error = "";

      if (
        this.editedItem.nombre === "" ||
        this.editedItem.nombreComercial === "" ||
        this.editedItem.moneda === "" ||
        this.editedItem.correo === "" ||
        this.editedItem.cedula === "" ||
        this.editedItem.tipoId === ""
      ) {
        correcto = false;
        error = "Por favor valide la información del cliente a registrar";
      }

      if (this.editedItem.esReceptor) {
        if (
          this.editedItem.provincia === "" ||
          this.editedItem.canton === "" ||
          this.editedItem.distrito === "" ||
          this.editedItem.barrio === "" ||
          this.editedItem.areaTel === "" ||
          this.editedItem.telefono === "" ||
          this.editedItem.direccion === ""
        ) {
          correcto = false;
          error = "Por favor valide la información de ubicación";
        }
      }

      if (this.editedItem.esExonerado) {
        if (
          this.editedItem.exoTipo === "" ||
          this.editedItem.exoNumDocumento === "" ||
          this.editedItem.exoNomInstitucion === "" ||
          this.editedItem.exoPorcentaje < 1 ||
          this.editedItem.exoPorcentaje > 13
        ) {
          correcto = false;
          error = "Por favor valide la información de exoneración";
        }
      }

      if (!correcto) {
        me.snackText = error;
        me.snackValue = true;
      } else {
        if (this.editedIndex > -1) {
          axios
            .post("api/Clientes/updateClientes", this.editedItem)
            .then(function () {
              me.snackText = "Registro modificado satisfactoriamente";
              me.snackValue = true;
              me.getData();
            })
            .catch(function (error) {
              console.log(error);
              // me.saved(false);
            });
        } else {
          axios
            .post("api/Clientes/insertClientes", this.editedItem)
            .then(function () {
              me.snackText = "Registro agregado satisfactoriamente";
              me.snackValue = true;
              me.getData();
            })
            .catch(function (error) {
              console.log(error);
              // me.saved(false);
            });
        }
        this.cancelarValid = true;
        this.eliminarValid = true;
        this.guardarValid = true;
        this.incluirValid = false;
        this.snackValue = false;
        this.changeEsReceptor();
      }
    },

    changeValueComponent() {
      this.snackValue = false;
    },

    deleteItem() {
      this.snackValue = false;
      let me = this;
      if (confirm("¿Está seguro de que desea eliminar el articulo?")) {
        axios
          .get(
            "api/Clientes/deleteClientes?cia=" +
              this.strCia +
              "&codigo=" +
              this.editedItem.codigo
          )
          .then(function () {
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
        this.cerrar();
        this.tab2 = 0;
        this.esRec = false;
      }
    },

    // selected(value) {
    //   this.cabys = Object.assign({}, value);
    //   this.showNewData = false;
    //   this.dialog = false;
    //   //   this.snackText = "codigo: " + this.cabys.codigo + "descripcion: "+ this.cabys.descripcion + "impuesto: " +this.cabys.impuesto ;
    //   //   this.snackValue = true;
    //   this.editedItem.nombre = this.cabys.descripcion;
    //   this.editedItem.cabys = this.cabys.codigo;
    //   this.editedItem.tarifa = this.cabys.impuesto;
    // },

    closed() {
      this.snackValue = false;
      this.showNewData = false;
      this.dialog = false;
    },

    cerrar() {
      this.snackValue = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.eliminarValid = false;
        this.guardarValid = false;
        this.incluirValid = true;
        this.esRec = false;
        this.esExo = false;
        //this.$refs.form.resetValidation();
      });
    },

    incluir() {
      this.snackValue = false;
      //this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getCodigoCliente();
        this.editedItem.areaTel = "506";
        this.editedItem.moneda = "CRC";
        //this.valid = false;
        this.cancelarValid = true;
        this.eliminarValid = false;
        this.guardarValid = true;
        this.incluirValid = false;
        this.esRec = false;
        this.esExo = false;
        //this.$refs.form.resetValidation();
      });
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>