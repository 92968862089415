<template>
  <div class="renderPage" dark>
    <v-toolbar color="primary" elevation="1">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Seleccionar Entidad Bancaria
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-expansion-panels multiple v-model="panelBancos" class="pa-0">
      <v-expansion-panel class="pa-0">
        <v-expansion-panel-header></v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-container>
            <v-col cols="12" sm="12" md="12">
              <v-row align="center" justify="space-around">
                <div v-for="item in items" :key="item.bancoSinpeNum">
                  <v-btn height="130" @click="selectedBank(item)">
                    <img
                      height="130"
                      width="150"
                      :src="require(`@/assets/bancos/${item.icono}`)"
                    />
                    <!-- <img height="150" src="../assets/bancos/logo_bacredomatic.png"  alt="" /> -->
                  </v-btn>
                </div>
              </v-row>
            </v-col>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- <v-col cols="12" sm="3" md="12">
      <v-card>
        <v-list-item two-line>
          <v-list-item-content> </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-spacer></v-spacer> -->

    <v-expansion-panels multiple v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <v-row align="center" justify="space-around">
              <qrcode-vue
                :value="textToQR"
                :size="options.size"
                :level="options.level"
                v-if="validQR"
                class="pa-0"
              />
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue,
  },
  props: {
    pagoInfo: {
      sucursal: "",
      terminal: "",
      total: 0,
    },
    allwedOnly: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Object],
      default: null,
    },
    options: {
      type: Object,
      default: function () {
        return {
          size: 250,
          background: "#ffffff",
          foreground: "#000000",
          className: "",
          level: "H",
          padding: 20,
        };
      },
    },
    type: {
      type: String,
      default: "canvas",
    },
    // Helpers options
    helpers: { type: String, default: "sms" },
  },
  data() {
    return {
      panel: [],
      panelBancos: [0],
      items: [],
      ciaSinpe: [],
      validQR: false,
      textToQR: "",
      numberSMS: "",
      numberCia: "",
      messageSMS: "",
      editedItem: {
        codigo: "",
        descripcion: "",
        impuesto: "",
      },
      defaultItem: {
        codigo: "",
        descripcion: "",
        impuesto: "",
      },
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  created() {
    this.getData();
    this.getCiaSinpe();
    this.setTextToQr();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/General/getBancoSinpe")
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getCiaSinpe() {
      let me = this;
      axios
        .get(
          "api/General/getCiaSinpe?cia=" +
            this.strCia +
            "&sucursal=" +
            this.pagoInfo.sucursal +
            "&terminal=" +
            this.pagoInfo.terminal
        )
        .then(function (response) {
          me.ciaSinpe = response.data;
          me.numberCia = me.ciaSinpe.ciaSinpeNum;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    selectedBank(item) {
      this.numberSMS = item.bancoSinpeNum;
      this.messageSMS =
        "Pase " +
        this.pagoInfo.total +
        " " +
        this.numberCia +
        " Pago de factura";
      this.setTextToQr();
      this.panel = [0];
      this.validQR = true;
    },

    setTextToQr() {
      switch (this.helpers) {
        case "call":
          this.textToQR = `tel:${this.numberSMS || 0}`;
          break;
        case "sms":
          this.textToQR = `SMSTO:${this.numberSMS || 0}${
            this.messageSMS ? ":" + this.messageSMS : ""
          }`;
          break;
        default:
          this.textToQR = this.value.toString();
          break;
      }
    },

    // selected(item) {
    //   this.editedIndex = this.items.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.$emit("clicked", this.editedItem);
    // },

    close() {
      this.dialog = false;
      this.$emit("closed");
    },
  },
};
</script>