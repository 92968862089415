<template dark>
  <div class="renderPage">
    <v-tabs
      background-color="primary"
      icons-and-text
      fixed-tabs
      center-active
      v-model="tab2"
    >
      <v-tabs-slider color="black"></v-tabs-slider>
      <v-tab key="art_registrados" @click="limpiar()">
        Articulos Registrados
        <v-icon>mdi-content-save</v-icon>
      </v-tab>
      <v-tab key="art_nuevos" @click="limpiar()">
        Articulo
        <v-icon>mdi-pencil-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="art_registrados">
        <v-data-table :headers="headers" :items="items" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field
                class="text-xs-center"
                v-model="search"
                append-icon="mdi-magnify"
                label="Búsqueda"
                single-line
                hide-details
                dense
              >
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
          </template>
        </v-data-table>
        <!-- <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" /> -->
      </v-tab-item>
      <v-tab-item key="art_nuevos">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  readonly
                  v-model="editedItem.codigo"
                  label="Código"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="9">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Descripción"
                  :rules="nameRules"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <!-- <v-col col="12" md="3" sm="6">
                <v-select
                  label="Tipo Articulo"
                  item-text="descripcion"
                  item-value="codigo"
                  v-model="editedItem.tipoArt"
                  :items="tiposArticulos"
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-select>
              </v-col> -->

              <v-col col="12" md="3" sm="6">
                <v-select
                  label="Unidad de medida"
                  item-text="un_nombre"
                  item-value="un_codigo"
                  v-model="editedItem.unidad"
                  :items="unidades"
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  readonly
                  v-model="editedItem.tarifa"
                  type="number"
                  label="Tarifa"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.costo"
                  type="number"
                  label="Costo"
                  :rules="nameRules"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.monto"
                  type="number"
                  label="Precio"
                  :rules="nameRules"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.existencia"
                  type="number"
                  label="Existencia"
                  :rules="nameRules"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="editedItem.barras"
                  label=" Código Barras"
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  readonly
                  v-model="editedItem.cabys"
                  label="Código Cabys"
                  required
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <!-- <v-col align="center" cols="12" sm="6" md="3">
                <v-btn color="primary" dark class="mb-2" @click="mostrarCabys">
                  Buscar Cabys
                </v-btn>
              </v-col> -->

              <v-col align="center" cols="12" sm="1" md="1">
                <v-btn
                  color="primary"
                  fab
                  small
                  dark
                  @click="mostrarCabys"
                  :disabled="incluirValid"
                >
                  <v-icon>mdi-file-find</v-icon>
                </v-btn>
              </v-col>

              <v-dialog transition="dialog-bottom-transition" v-model="dialog">
                <!--width="1000px"  -->
                <div v-if="showNewData">
                  <Cabys
                    v-bind:allwedOnly="true"
                    @clicked="selected"
                    @closed="closed"
                  />
                </div>
              </v-dialog>

              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-if="visible"
                  v-model="editedItem.codigoTarifa"
                  label="Código Tarifa"
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-if="visible"
                  v-model="editedItem.codigoImp"
                  label="Código Impuesto"
                  class="pa-0"
                  :disabled="incluirValid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-if="visible"
                  v-model="editedItem.peso"
                  label="Peso"
                  class="pa-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-if="visible"
                  v-model="editedItem.costo"
                  label="Costo"
                  class="pa-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-row align="center" justify="space-around">
            <v-btn color="primary" :disabled="!incluirValid" @click="incluir()">
              Incluir
            </v-btn>
            <v-btn color="primary" :disabled="!guardarValid" @click="save()">
              Guardar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!eliminarValid"
              @click="deleteItem()"
            >
              Eliminar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!cancelarValid"
              @click="cerrar()"
              dark
            >
              Cancelar
            </v-btn>
          </v-row>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <Snackbar v-bind:text="snackText" v-bind:snackbar="snackValue" />
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
import Cabys from "./Cabys.vue";

export default {
  components: {
    Snackbar,
    Cabys,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      valid: false,
      incluirValid: true,
      cancelarValid: false,
      eliminarValid: false,
      guardarValid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab2: "",
      headers: [
        {
          text: "Código",
          align: "start",
          value: "codigo",
        },
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        {
          text: "Costo",
          align: "start",
          value: "costo",
        },
        {
          text: "Precio",
          align: "start",
          value: "monto",
        },
        {
          text: "Existencia",
          align: "start",
          value: "existencia",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
      ],
      items: [],
      tiposArticulos: [],
      unidades: [],
      codigoArticulo: "",
      editedIndex: -1,
      editedItem: {
        codigo: "",
        nombre: "",
        tipoArt: "",
        unidad: "",
        tarifa: 0,
        monto: 0,
        barras: "",
        existencia: 0,
        costo: 0,
        peso: "",
        codigoImp: "",
        codigoTarifa: "",
        cabys: "",
        cia: "",
      },
      defaultItem: {
        codigo: "",
        nombre: "",
        tipoArt: "",
        unidad: "",
        tarifa: 0,
        monto: 0,
        barras: "",
        existencia: 0,
        costo: 0,
        peso: 0,
        codigoImp: "",
        codigoTarifa: "",
        cabys: "",
        cia: "",
      },
      cabys: {
        codigo: "",
        descripcion: "",
        impuesto: "",
      },
      showNewData: false,
      nameRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getData();
    this.getTiposArticulos();
    this.getUnidades();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/Articulos/getArticulosByCompany?cia=" + this.strCia, {
          crossdomain: true,
        })
        //.get("api/Sender/GetTipoDocumento", {crossdomain: true})
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      //this.snackValue = false
    },

    limpiar() {
      this.snackValue = false;
    },
    getCodigoArticulo() {
      let me = this;
      axios
        .get("api/Articulos/getCodigoArticulo?cia=" + this.strCia)
        .then(function (response) {
          //me.codigoArticulo = response.data;
          me.editedItem.codigo = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTiposArticulos() {
      let me = this;
      axios
        .get("api/Articulos/getTipoArticulos")
        .then(function (response) {
          me.tiposArticulos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getUnidades() {
      let me = this;
      axios
        .get("api/Articulos/getUnidades")
        .then(function (response) {
          me.unidades = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.snackValue = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      //this.dialog = true;
      this.tab2 = 1;
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
    },

    mostrarCabys() {
      this.snackValue = false;
      this.dialog = true;
      this.showNewData = true;
      this.cancelarValid = true;
    },

    save() {
      this.snackValue = false;
      let me = this;
      this.editedItem.cia = this.strCia;

      if (
        this.editedItem.unidad != "" &&
        this.editedItem.nombre != "" &&
        this.editedItem.monto >= 0 &&
        this.editedItem.existencia >= 0
      ) {
        for (const unid of this.unidades) {
          if (unid.un_codigo === this.editedItem.unidad) {
            this.editedItem.tipoArt = unid.tipoArt;
          }
        }

        if (this.editedIndex > -1) {
          axios
            .post("api/Articulos/updateArticulo", this.editedItem)
            .then(function () {
              me.snackText = "Registro modificado satisfactoriamente";
              me.snackValue = true;
              me.getData();
            })
            .catch(function (error) {
              console.log(error);
              // me.saved(false);
            });
        } else {
          axios
            .post("api/Articulos/insertArticulo", this.editedItem)
            .then(function () {
              me.snackText = "Registro agregado satisfactoriamente";
              me.snackValue = true;
              me.getData();
            })
            .catch(function (error) {
              console.log(error);
              // me.saved(false);
            });
        }
        this.snackValue = false;
        this.cancelarValid = true;
        this.eliminarValid = true;
        this.guardarValid = true;
        this.incluirValid = false;
      } else {
        me.snackText =
          "Por favor valide la información de los campos obligatorios";
        me.snackValue = true;
      }
      //this.close();
    },

    // validarCampos(){
    //     let me = this;

    //     if(this.editedItem.tipoArt === "") {
    //         return false;
    //     }
    //     else
    //     {
    //         return TRUE;
    //     }

    // },

    deleteItem() {
      this.snackValue = false;
      let me = this;
      if (confirm("¿Está seguro de que desea eliminar el articulo?")) {
        axios
          .get(
            "api/Articulos/deleteArticulo?cia=" +
              this.strCia +
              "&codigo=" +
              this.editedItem.codigo
          )
          .then(function () {
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
        this.cerrar();
        this.tab2 = 0;
      }
    },

    selected(value) {
      this.snackValue = false;
      this.cabys = Object.assign({}, value);
      this.showNewData = false;
      this.dialog = false;
      this.editedItem.nombre = this.cabys.descripcion;
      this.editedItem.cabys = this.cabys.codigo;
      this.editedItem.tarifa = this.cabys.impuesto;
    },

    closed() {
      this.snackValue = false;
      this.showNewData = false;
      this.dialog = false;
    },

    cerrar() {
      this.snackValue = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.eliminarValid = false;
        this.guardarValid = false;
        this.incluirValid = true;
        //this.$refs.form.resetValidation();
      });
    },

    incluir() {
      this.snackValue = false;
      //this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getCodigoArticulo();
        //this.valid = false;
        this.cancelarValid = true;
        this.eliminarValid = false;
        this.guardarValid = true;
        this.incluirValid = false;
        //this.$refs.form.resetValidation();
      });
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}
</style>