<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Seleccionar Cliente
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            class="text-xs-center"
            v-model="search"
            append-icon="mdi-feature-search-outline"
            label="Búsqueda"
            single-line
            hide-details
            dense
          >
          </v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-simple-checkbox v-model="item.type" disabled> </v-simple-checkbox>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn small @click="selected(item)"> Seleccionar </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    allwedOnly: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Cédula",
          align: "start",
          value: "dni",
          width: 100,
        },
        {
          text: "Nombre",
          align: "start",
          value: "name",
        },
        {
          text: "Teléfono",
          align: "start",
          value: "phone",
          width: 150,
        },
        {
          text: "Celular",
          align: "start",
          value: "cellPhone",
          width: 150,
        },
        { text: "Acciones", value: "actions", width: 100, sortable: false },
      ],
      items: [],
      bonusTypes: [],
      editedIndex: -1,
      editedItem: {
        dni: "",
        name: "",
        profession: "",
        phone: "",
        cellPhone: "",
        salary: "",
        address: "",
        bonusType: 0,
        bonusName: "",
        maritalStatus: "",
        userId: 0,
        email: "",
      },
      defaultItem: {
        dni: "",
        name: "",
        phone: "",
        cellPhone: "",
        email: "",
        profession: "",
        salary: "",
        address: "",
        bonusType: 0,
        maritalStatus: "",
        userId: 0,
      },
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("/api/Client/getClientAll?hasCase=" + this.allwedOnly)
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    selected(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$emit("clicked", this.editedItem);
    },

    close() {
      this.dialog = false;
      this.$emit("closed");
    },
  },
};
</script>