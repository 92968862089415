<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="2" sm="3">
              <v-text-field
                label="# Consecutivo"
                v-model="caseItem.caseId"
                readonly
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="2" sm="3">
              <v-text-field
                label="# Caso Banhvi"
                v-model="caseItem.caseNumber"
              ></v-text-field>
            </v-col> -->

            <v-col cols="12" md="10" sm="6">
              <v-text-field
                label="Cliente"
                :value="ClientName"
                readonly
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="4" sm="6">
              <v-text-field
                label="Solicitud"
                v-model="caseItem.request"
              >
              </v-text-field>
            </v-col> -->

            <v-col cols="12" md="3" sm="6">
              <v-text-field
                label="Tipo de bono"
                v-model="caseItem.purpose"
                required
                :rules="requiredRules"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" sm="6"
              v-if="customer.bonusType == 4">
              <v-text-field
                label="Medida de acceso al patio posterior"
                v-model="caseItem.postulation"
              >
              </v-text-field>
            </v-col>

           <!-- <v-col cols="12" md="4" sm="6">
              <v-text-field
                label="Programa"
                v-model="caseItem.program"
              >
              </v-text-field>
            </v-col>-->

            <v-col cols="12" md="3" sm="6"
            v-if="customer.bonusType == 5">
              <v-select
                v-model="caseItem.program"
                :items="art59"
                item-text="name"
                item-value="art59TypeId"
                label="Tipo de caso Art59"
                required
                :rules="requiredRules"
              ></v-select>
            </v-col>

            <!-- <v-col cols="12" md="4" sm="6">
              <v-text-field
                label="Cédula de Jefe"
                v-model="caseItem.chiefDNI"
                required
                :rules="requiredRules"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                label="Nombre de Jefe"
                v-model="caseItem.chiefName"
                required
                :rules="requiredRules"
              >
              </v-text-field>
            </v-col> -->

            <v-col cols="12" md="3" sm="6">
              <v-text-field
                label="Entidad o Fiscal"
                v-model="caseItem.entity"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" sm="6">
              <v-select
                v-model="caseItem.sponsor"
                :items="sponsors"
                item-text="name"
                item-value="sponsorId"
                label="Promotor"
                required
                :rules="requiredRules"
              ></v-select>
            </v-col>

            <v-col cols="12" md="3" sm="6">
              <v-select
                v-model="caseItem.state"
                :items="states"
                item-text="name"
                item-value="bonusStateId"
                label="Estado"
                readonly
              ></v-select>
            </v-col>

            <v-col cols="12" md="3" sm="6">
              <v-text-field label="Teléfono" v-model="customer.phone" readonly>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" sm="6">
              <v-text-field
                label="Celular"
                v-model="customer.cellPhone"
                readonly
              >
              </v-text-field>
            </v-col>
             <v-col cols="12" md="12" sm="6">
              <v-text-field
                v-model="caseItem.request"
                label="Anotaciones del caso"
              ></v-text-field>
            </v-col>

          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="blue darken-1" text @click="close()">
              Cancelar
            </v-btn> -->
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="save()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <Snackbar v-bind:text="snackText" v-bind:snackbar="snackValue" />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Snackbar from "../shared/Snackbar.vue";
export default {
  props: {
    caseItem: {
      caseId: 0,
      dni: "",
      caseNumber: "",
      request: "",
      postulation: "",
      purpose: "",
      program: 0,
      chiefName: "",
      sponsor: 0,
      state: 0,
      chiefDNI: "",
      entity: "",
      userId: 0,
    },
    customer: {
      dni: "",
      name: "",
      profession: "",
      phone: "",
      cellPhone: "",
      salary: "",
      address: "",
      bonusType: 0,
      bonusName: "",
      maritalStatus: "",
      userId: 0,
      email: "",
    },
  },

  components: {
    Snackbar,
  },

  data() {
    return {
      valid: false,
      editedIndex: -1,
      states: [],
      sponsors: [],
      art59: [],
      snackText: "",
      snackValue: false,
      requiredRules: [(v) => !!v || "Campo requerido"],
    };
  },

  computed: {
    ClientName() {
      return this.customer.dni + " - " + this.customer.name;
    },

    ...mapGetters(["user"]),
  },

  created() {
    this.getStates();
    this.getSponsors();
    this.getArt59();
  },

  methods: {
    getStates() {
      let me = this;
      axios
        .get("/api/Configuration/getBonusStateAll")
        .then(function (response) {
          me.states = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getSponsors() {
      let me = this;
      axios
        .get("/api/Configuration/getSponsorAll")
        .then(function (response) {
          me.sponsors = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

      getArt59() {
      let me = this;
      axios
        .get("/api/Configuration/getArt59TypesAll")
        .then(function (response) {
          me.art59 = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    save() {
      let me = this;

      if (this.caseItem.caseId > 0) {
        axios
          .post("/api/Case/updateCase", this.caseItem)
          .then(function () {
            me.snackText = "Registro modificado satisfactoriamente";
            me.snackValue = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.caseItem.userId = this.user.userId;
        this.caseItem.dni = this.customer.dni;

        axios
          .post("/api/Case/insertCase", this.caseItem)
          .then(function (response) {
            me.$emit("clicked", response.data);

            me.snackText = "Registro agregado satisfactoriamente";
            me.snackValue = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    close() 
    {
      this.$emit("closed")
    }
  },
};
</script>

<style>
</style>