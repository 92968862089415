<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-model="item.countryNumber"
                label="Número de Finca"
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-model="item.volumeNumber"
                label="Número de Tomo"
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-model="item.folioNumber"
                label="Número de Folio"
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-model="item.entryNumber"
                label="Número de Asiento"
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col> -->

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-model="item.province"
                label="Provincia"
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="3" sm="6">
              <v-select
                v-model="provinciaSeleccionada"
                :items="provincia"
                item-text="provincia"
                item-value="value"
                label="Provincia"
              ></v-select>
            </v-col> -->
            
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-model="item.canton"
                label="Cantón"
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="3" sm="6">
              <v-select
                v-model="cantonSeleccionado"
                :items="cantones"
                item-text="canton"
                item-value="canton"
                label="canton"
              ></v-select>
            </v-col> -->

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-model="item.district"
                label="Distrito"
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            
            <!-- <v-col cols="12" md="3" sm="6">
              <v-select
                v-model="distritoSeleccionado"
                :items="distritos"
                item-text="distrito"
                item-value="distrito"
                label="distrito"
              ></v-select>
            </v-col> -->

            <v-col cols="12" md="8" sm="6">
              <v-text-field
                v-model="item.address"
                label="Dirección"
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-model="item.area"
                label="Área"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                v-model="item.planeNumber"
                label="Número de Plano"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="blue darken-1" text @click="close()">
              Cancelar
            </v-btn> -->
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="save()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <Snackbar v-bind:text="snackText" v-bind:snackbar="snackValue" />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Snackbar from "../shared/Snackbar.vue";
export default {
  props: {
    item: {
      id: 0,
      dni: "",
      countryNumber: "",
      volumeNumber: "",
      folioNumber: "",
      entryNumber: "",
      province: "",
      canton: "",
      district: "",
      address: "",
      area: "",
      planeNumber: "",
      userId: 0,
      caseId: 0,
    },
  },

  components: {
    Snackbar,
  },

  data() {
    return {
      valid: false,
      snackText: "",
      snackValue: false,
      requiredRules: [(v) => !!v || "Campo requerido"],
      provincia: [],
      cantones: [],
      distritos: [],
      provinciaSeleccionada: "",
      cantonSeleccionado: "",
      distritoSeleccionado: "",
    };
  },

  computed: {
    ...mapGetters(["user", "strCaseId"]),
  },

  watch: {
    provinciaSeleccionada(val) {
      this.cantones = this.provincia.cantones.value[val];
    },

    cantonSeleccionado(val) {
      this.distritos = this.cantones(val).distrito;
    },
  },

  created() {
    //cargar la lista para provincia, crear un metodo y llamarlo aqui
    //this.getUbicacion();
    this.getLot();
  },

  methods: {
    getLot() {
      let me = this;
      axios
        .get("/api/Lot/getLot?caseId=" + this.strCaseId)
        .then(function (response) {
          if (response.data) {
            me.item = response.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

      getUbicacion() {
      let me = this;
      axios
        .get("/api/Ubicacion/getUbicacion")
        .then(function (response) {
          me.provincia = response.data;
        })
        .catch(function (error) {
          console.log(error)
        });
    },

    save() {
      let me = this;
      this.item.caseId = this.strCaseId;

      if (this.item.id > 0) {
        axios
          .post("/api/Lot/updateLot", this.item)
          .then(function () {
            me.$emit("clicked", me.strCaseId);
            me.snackText = "Registro modificado satisfactoriamente";
            me.snackValue = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.item.userId = this.user.userId;
        axios
          .post("/api/Lot/insertLot", this.item)
          .then(function () {
            me.$emit("clicked", me.strCaseId);
            me.snackText = "Registro agregado satisfactoriamente";
            me.snackValue = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
</style>