<template>
  <div class="renderPage">
    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            class="text-xs-center"
            v-model="search"
            append-icon="mdi-feature-search-outline"
            label="Búsqueda"
            single-line
            hide-details
            dense
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nuevo
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="editedItem.description"
                          label="Descripción"
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <v-file-input
                          label="Archivo"
                          dense
                          filled
                          accept=".png, .jpeg, .jpg, .pdf"
                          v-model="modelFile"
                          @change="onImageChange"
                          required
                          :rules="requiredRules"
                        />
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close()">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="blue darken-1"
                      text
                      @click="save()"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="viewItem(item)"> mdi-eye </v-icon>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-icon small class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    items: [],
  },
  data() {
    return {
      dialog: false,
      valid: false,
      search: "",
      states: [],
      headers: [
        {
          text: "Descripción",
          align: "start",
          value: "description",
        },
        { text: "Ver", value: "actions", width: 50, sortable: false },
        { text: "Eliminar", value: "delete", width: 70, sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        dni: "",
        description: "",
        caseId: "",
        type: "",
        actionSP: "",
        pathFile: "",
        image: "",
      },
      defaultItem: {
        id: 0,
        dni: "",
        description: "",
        caseId: "",
        type: "",
        actionSP: "",
        pathFile: "",
        image: "",
      },
      requiredRules: [(v) => !!v || "Campo requerido"],
      tempFile: "",
      modelFile: null,
    };
  },

  computed: {
    ...mapGetters(["user", "strCaseId"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Archivo" : "Nuevo Archivo";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("/api/Image/GetImage?caseId=" + this.strCaseId)
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            me.items = response.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    viewItem(item) {
      window.open(item.pathFile, "_blank");
    },

    save() {
      let me = this;
      let formData = new FormData();

      this.editedItem.caseId = this.strCaseId;
      
      if (this.tempFile != "") {
        formData.append("mainImage", this.tempFile);
      }
      formData.append("imageFile", JSON.stringify(this.editedItem));

      axios
        .post("/api/Image/UploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          //me.getData();
          me.$emit("clicked", me.strCaseId);
        })
        .catch(function (error) {
          console.log(error);
        });

      this.close();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.modelFile = null;
        this.$refs.form.resetValidation();
      });
    },

    // deleteItem(item) {
    //   let me = this
    //     axios
    //       .delete("/api/Image/deleteImage/" + item.id)
    //       .then(function() {
    //         me.getData()
    //       })
    //       .catch(function(error) {
    //         console.log(error)
    //         // me.saved(false);
    //       });    
    //   this.close()
    // },

    deleteItem(item) {
      let me = this
      if(confirm("¿Está seguro de que desea eliminar el archivo?")){
        axios
          .delete("/api/Image/deleteImage/" + item.id)
          .then(function() {
            me.getData()
          })
          .catch(function(error) {
            console.log(error)
            // me.saved(false);
          });    
      this.close()
      }
    },

    onImageChange(ev) {
      this.tempFile = ev;
    },
  },
};
</script>