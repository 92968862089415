<template>
  <div class="renderPage" dark>
    <v-toolbar color="primary" elevation="1">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Seleccionar Forma de Reenvio
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-expansion-panels multiple v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  v-model="editedItem.numero"
                  label="WhatsApp"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col align="center" cols="12" sm="4" md="4">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click="selectedWhatsApp"
                >
                  Enviar
                  <v-icon right dark> mdi-whatsapp </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  v-model="editedItem.correo"
                  label="Correo"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col align="center" cols="12" sm="4" md="4">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click="selectedCorreo"
                >
                  Enviar
                  <v-icon right dark> mdi-email-outline </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  props: {
    reenvioInfo: {
      numero: "",
      correo: "",
    },
    allwedOnly: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      search: "",
      panel: [],
      items: [],
      editedIndex: -1,
      cabysDescripcion: "",
      editedItem: {
        numero: "",
        correo: "",
        porwhatsapp: false,
        porcorreo: false,
      },
      defaultItem: {
        numero: "",
        correo: "",
        porwhatsapp: false,
        porcorreo: false,
      },
    };
  },

  created() {
    this.panel = [0];
    this.editedItem.numero = this.reenvioInfo.numero;
    this.editedItem.correo = this.reenvioInfo.correo;
  },

  methods: {
    selectedWhatsApp() {
      this.editedItem.porwhatsapp = true;
      this.editedItem.porcorreo = false;
      this.$emit("clicked", this.editedItem);
    },

    selectedCorreo() {
      this.editedItem.porwhatsapp = false;
      this.editedItem.porcorreo = true;
      this.$emit("clicked", this.editedItem);
    },

    close() {
      this.dialog = false;
      this.$emit("closed");
    },
  },
};
</script>