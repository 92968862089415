<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Reporte Contable
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" to="/home">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="4" md="3">
              <v-dialog
                ref="dialog"
                v-model="dateModal"
                :return-value.sync="dates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dates"
                    label="Periodo"
                    prepend-icon="mdi-calendar"
                    readonly
                    required
                    :rules="dateRules"
                    v-bind="attrs"
                    v-on="on"
                    class="pa-0"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dates" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateModal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col align="center" cols="12" sm="3" md="3">
              <v-btn color="primary" @click="getReport()" :disabled="!valid">
                Generar Reporte
              </v-btn>
            </v-col>
            <v-col align="center" cols="12" sm="2" md="2">
              <v-btn
                color="primary"
                @click="downloadReport()"
                :disabled="!validDecargar"
              >
                Descargar
              </v-btn>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <v-btn color="primary" @click="sendEmailReport()" :disabled="!validEnviarCorreo">
                <v-icon left> mdi-email-alert </v-icon>
                Enviar por correo
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col cols="12" md="4" sm="4" class="actionsCol">
              <v-btn small :disabled="!valid" @click="getReport()"
                >Generar Reporte</v-btn
              >
              <vue-excel-xlsx
                v-if="items.length > 0"
                :data="items"
                :columns="columns"
                :filename="'Reporte_De_Caso'"
                :sheetname="'Hoja'"
                class="
                  v-btn v-btn--is-elevated v-btn--has-bg
                  theme--light
                  v-size--small
                "
              >
                Descargar
              </vue-excel-xlsx>
            </v-col>
          </v-row> -->
        </v-form>

        <v-tabs
          background-color="primary"
          icons-and-text
          fixed-tabs
          center-active
          v-model="tab2"
          height="40"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab key="emision"> Documentos Emitidos </v-tab>
          <v-tab key="recibidos"> Documentos Recibidos </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab2" class="ti">
          <v-tab-item key="emision">
            <v-data-table :headers="headers" :items="items">
              <template v-slot:[`item.pdf`]="{ item }">
                <v-btn color="primary" class="pa-0" @click="viewPDF(item)">
                  <v-icon color="white"> mdi-file-pdf </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.enviado`]="{ item }">
                <v-btn color="primary" class="pa-0" @click="viewEnviado(item)">
                  <v-icon color="white"> mdi-file-export </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.recibido`]="{ item }">
                <v-btn color="primary" class="pa-0" @click="viewRecibido(item)">
                  <v-icon color="white"> mdi-file-import </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item key="recibidos">
            <v-data-table :headers="headers2" :items="items2">
              <template v-slot:[`item.xmlRecibido`]="{ item }">
                <v-btn
                  color="primary"
                  class="pa-0"
                  @click="viewXMLRecibido(item)"
                >
                  <v-icon color="white"> mdi-file-import </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.xmlFirmado`]="{ item }">
                <v-btn
                  color="primary"
                  class="pa-0"
                  @click="viewXMLFirmado(item)"
                >
                  <v-icon color="white"> mdi-file-export </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.xmlRespuesta`]="{ item }">
                <v-btn
                  color="primary"
                  class="pa-0"
                  @click="viewXMLRespuesta(item)"
                >
                  <v-icon color="white"> mdi-file-import </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>

        <!-- <v-data-table :headers="headers" :items="items"> </v-data-table> -->
      </v-card-text>
    </v-card>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Snackbar from "./shared/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
  data() {
    return {
      valid: false,
      validDecargar: false,
      validEnviarCorreo: false,
      dateModal: false,
      dates: [],
      items: [],
      items2: [],
      opcionesSistemaCia: [],
      snackText: "",
      snackValue: false,
      tab2: 0,
      dateRules: [
        (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
      ],
      headers: [
        {
          text: "Clave",
          align: "start",
          value: "clavehacienda",
          width: 450,
        },
        {
          text: "Emisor",
          align: "start",
          value: "emisorNombre",
          width: 450,
        },
        {
          text: "Cédula Emisor",
          align: "start",
          value: "cia",
          width: 140,
        },
        {
          text: "Receptor",
          align: "start",
          value: "clienteNombre",
          width: 450,
        },
        {
          text: "Cédula Receptor",
          align: "start",
          value: "clienteCedula",
          width: 140,
        },
        {
          text: "Fecha",
          align: "start",
          value: "fechaString",
          width: 110,
        },
        {
          text: "Moneda",
          align: "start",
          value: "moneda",
          width: 110,
        },
        // {
        //   text: "Tipo Cambio",
        //   align: "start",
        //   value: "tipoCambio",
        //   width: 130,
        // },
        {
          text: "Estado Hacienda",
          align: "start",
          value: "estadoHacienda",
          width: 160,
        },
        {
          text: "Consecutivo",
          align: "start",
          value: "consHacReal",
          width: 200,
        },
        {
          text: "Documento",
          align: "start",
          value: "tipoDocumento",
          width: 220,
        },
        {
          text: "Sub Total",
          align: "start",
          value: "totalVentaStr",
          width: 120,
        },
        {
          text: "Total Descuentos",
          align: "start",
          value: "totalDescuentosStr",
          width: 150,
        },
        {
          text: "Total IVA",
          align: "start",
          value: "totalImpuestoStr",
          width: 120,
        },
        // {
        //   text: "Total Iva 1",
        //   align: "start",
        //   value: "totalIva1Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 2",
        //   align: "start",
        //   value: "totalIva2Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 4",
        //   align: "start",
        //   value: "totalIva4Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 8",
        //   align: "start",
        //   value: "totalIva8Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 13",
        //   align: "start",
        //   value: "totalIva13Str",
        //   width: 120,
        // },
        {
          text: "Total Comprobante",
          align: "start",
          value: "totalComprobanteStr",
          width: 160,
        },
        { text: "Archivo PDF", value: "pdf", sortable: false },
        { text: "XML Enviado", value: "enviado", sortable: false },
        { text: "XML Respuesta", value: "recibido", sortable: false },
      ],
      headers2: [
        {
          text: "Clave",
          align: "start",
          value: "clavehacienda",
          width: 450,
        },
        {
          text: "Emisor",
          align: "start",
          value: "clienteNombre",
          width: 400,
        },
        {
          text: "Cédula Emisor",
          align: "start",
          value: "cedulaReceptor",
          width: 140,
        },
        {
          text: "Fecha",
          align: "start",
          value: "fechaString",
          width: 110,
        },
        {
          text: "Moneda",
          align: "start",
          value: "moneda",
          width: 110,
        },
        // {
        //   text: "Tipo Cambio",
        //   align: "start",
        //   value: "tipoCambio",
        //   width: 130,
        // },
        {
          text: "Consecutivo Emisor",
          align: "start",
          value: "consecutivoEmisor",
          width: 200,
        },
        {
          text: "Estado Doc Hacienda",
          align: "start",
          value: "estadoHaciendaEmisor",
          width: 160,
        },
        {
          text: "Estado Aceptación",
          align: "start",
          value: "estadoAceptacion",
          width: 160,
        },
        {
          text: "Cons Aceptación",
          align: "start",
          value: "consHacReal",
          width: 200,
        },
        {
          text: "Documento",
          align: "start",
          value: "tipoDocumento",
          width: 220,
        },
        {
          text: "Sub Total",
          align: "start",
          value: "totalVentaStr",
          width: 120,
        },
        {
          text: "Total Descuentos",
          align: "start",
          value: "totalDescuentosStr",
          width: 150,
        },
        {
          text: "Total IVA",
          align: "start",
          value: "totalImpuestoStr",
          width: 120,
        },
        // {
        //   text: "Total Iva 1",
        //   align: "start",
        //   value: "totalIva1Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 2",
        //   align: "start",
        //   value: "totalIva2Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 4",
        //   align: "start",
        //   value: "totalIva4Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 8",
        //   align: "start",
        //   value: "totalIva8Str",
        //   width: 110,
        // },
        // {
        //   text: "Total Iva 13",
        //   align: "start",
        //   value: "totalIva13Str",
        //   width: 120,
        // },
        {
          text: "Total Comprobante",
          align: "start",
          value: "totalComprobanteStr",
          width: 160,
        },
        { text: "XML Recibido", value: "xmlRecibido", sortable: false },
        { text: "Mensaje Receptor", value: "xmlFirmado", sortable: false },
        { text: "XML Respuesta", value: "xmlRespuesta", sortable: false },
      ],
      columns: [
        {
          label: "Número de Caso",
          field: "caseNumber",
        },
        {
          label: "Promotor",
          field: "sponsorName",
        },
        {
          label: "Analista/Usuario",
          field: "analyst_User",
        },
        {
          label: "Nombre Cliente",
          field: "clientName",
        },
        {
          label: "Dirección Cliente",
          field: "adressClient",
        },
        {
          label: "Teléfono Cliente",
          field: "phone",
        },
        {
          label: "Celular Cliente",
          field: "cellPhone",
        },
        {
          label: "Última Actualización",
          field: "dateState",
        },
        {
          label: "Estado/Descripcion",
          field: "actualState",
        },
        {
          label: "Observaciones",
          field: "observation",
        },
      ],
    };
  },

  created() {
    this.getOpcionesSistemaCia();
  },

  methods: {
    getReport() {
      this.getEmitidos();
      this.getRecibidos();
    },

    getEmitidos() {
      let me = this;
      axios
        .get(
          "api/Reportes/getFacturasEmitidas?cia=" +
            this.strCia +
            "&fechaInicio=" +
            this.dates[0].toString() +
            "&fechaFin=" +
            this.dates[1].toString()
        )
        .then(function (response) {
          me.items = response.data;
          me.validDecargar = true;
          if (me.opcionesSistemaCia) {
            for (const opc of me.opcionesSistemaCia) {
              if (opc.opcionId === "correo_contador") {
                if (opc.valor === "true") {
                  me.validEnviarCorreo = true;
                }
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getOpcionesSistemaCia() {
      let me = this;
      axios
        .get("api/General/getOpcionesSistemaCia?cia=" + this.strCia)
        .then(function (response) {
          me.opcionesSistemaCia = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    sendEmailReport() {
      let me = this;
      axios
        .get(
          "api/Reportes/enviarCorreoContable?cia=" +
            this.strCia +
            "&fechaInicio=" +
            this.dates[0].toString() +
            "&fechaFin=" +
            this.dates[1].toString()
        )
        .then(function (response) {
          me.snackText = response.data;
          me.snackValue = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getRecibidos() {
      let me = this;
      axios
        .get(
          "api/Reportes/getFacturasRecibidas?cia=" +
            this.strCia +
            "&fechaInicio=" +
            this.dates[0].toString() +
            "&fechaFin=" +
            this.dates[1].toString()
        )
        .then(function (response) {
          me.items2 = response.data;
          me.validDecargar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    downloadReport() {
      axios
        .get(
          "api/Reportes/getReporteContable?cia=" +
            this.strCia +
            "&fechaInicio=" +
            this.dates[0].toString() +
            "&fechaFin=" +
            this.dates[1].toString(),
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ReporteContable.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    viewPDF(item) {
      window.open(
        "EduvaFE\\" +
          item.cia +
          "\\XML_EMISION\\" +
          item.clavehacienda +
          "\\" +
          item.clavehacienda +
          ".pdf",
        "_blank"
      );
    },

    viewEnviado(item) {
      window.open(
        "EduvaFE\\" +
          item.cia +
          "\\XML_EMISION\\" +
          item.clavehacienda +
          "\\" +
          item.clavehacienda +
          "_Signed.xml",
        "_blank"
      );
    },

    viewRecibido(item) {
      window.open(
        "EduvaFE\\" +
          item.cia +
          "\\XML_EMISION\\" +
          item.clavehacienda +
          "\\" +
          item.clavehacienda +
          "_Response.xml",
        "_blank"
      );
    },

    viewXMLRecibido(item) {
      window.open(
        "EduvaFE\\" +
          item.cia +
          "\\XML_RECEPCION\\" +
          item.clavehacienda +
          ".xml",
        "_blank"
      );
      // window.open(item.pathFile, "_blank");
    },

    viewXMLFirmado(item) {
      window.open(
        "EduvaFE\\" +
          item.cia +
          "\\MensajeReceptor\\" +
          item.consHacReal +
          "\\" +
          item.consHacReal +
          "_Signed.xml",
        "_blank"
      );
      // window.open(item.pathFile, "_blank");
    },

    viewXMLRespuesta(item) {
      window.open(
        "EduvaFE\\" +
          item.cia +
          "\\MensajeReceptor\\" +
          item.consHacReal +
          "\\" +
          item.consHacReal +
          "_Response.xml",
        "_blank"
      );
      // window.open(item.pathFile, "_blank");
    },

    closed() {
      this.customerModal = false;
    },
  },
  computed: {
    ...mapGetters(["user", "strCia"]),
  },
};
</script>

<style>
.actionsCol .v-btn {
  margin-right: 10px;
}
</style>