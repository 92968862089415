<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Promotores
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" to="/home">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            class="text-xs-center"
            v-model="search"
            append-icon="mdi-feature-search-outline"
            label="Búsqueda"
            single-line
            hide-details
            dense
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nuevo
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nombre"
                          :rules="nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close()">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="blue darken-1"
                      text
                      @click="save()"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-icon small class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
      </template>
    </v-data-table>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue"/>
  </div>
</template>

<script>
import axios from "axios";
import Snackbar from './shared/Snackbar.vue';

export default {
  components: {
    Snackbar
  },
  data() {
    return {
      dialog: false,
      valid: false,
      search: "",
      snackText: "",
      snackValue: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "name",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
        { text: "Eliminar", value: "delete", width: 70, sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        sponsorId: 0,
        name: "",
      },
      defaultItem: {
        sponsorId: 0,
        name: "",
      },
      nameRules: [
        v => !!v || 'Campo requerido',
        v => v.length <= 200 || 'El nombre del caracter debe de tener menos de 200 caracteres',
      ]
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Promotor" : "Editar Promotor"
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    search() {
      this.snackValue = false
    }
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/Configuration/getSponsorAll")
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error)
        });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    save() {
      let me = this

      if (this.editedIndex > -1) {
        axios
          .post("api/Configuration/updateSponsor", this.editedItem)
          .then(function() {
            me.snackText = "Registro modificado satisfactoriamente"
            me.snackValue = true
            me.getData()
          })
          .catch(function(error) {
            console.log(error)
            // me.saved(false);
          });
      } else {
        axios
          .post("api/Configuration/insertSponsor", this.editedItem)
          .then(function() {
            me.snackText = "Registro agregado satisfactoriamente"
            me.snackValue = true
            me.getData()
          })
          .catch(function(error) {
            console.log(error)
            // me.saved(false);
          });
      }
      this.close()
    },

    // deleteItem(item) {
    //   let me = this
    //     axios
    //       .delete("/api/Configuration/deleteSponsor/" + item.sponsorId)
    //       .then(function() {
    //         me.getData()
    //       })
    //       .catch(function(error) {
    //         console.log(error)
    //         // me.saved(false);
    //       });    
    //   this.close()
    // },

    deleteItem(item) {
      let me = this
      if(confirm("¿Está seguro de que desea eliminar el registro?")){
          axios
          .delete("/api/Configuration/deleteSponsor/" + item.sponsorId)
          .then(function() {
            me.getData()
          })
          .catch(function(error) {
            console.log(error)
            // me.saved(false);
          });    
      this.close()
      }
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.$refs.form.resetValidation()
      });
    },
  },
};
</script>