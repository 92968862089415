<template>
  <div class="renderPage">
    <v-toolbar color="primary" dark elevation="1" class="mb-1 mb-md-2 mb-lg-2">
      <v-spacer />
      <v-toolbar-title class="text-capitalize">
        Reporte de Visita
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" to="/home">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="4" md="8">
              <v-dialog
                ref="dialog"
                v-model="customerModal"
                :return-value.sync="dates"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Cliente"
                    prepend-icon="mdi-account"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    v-bind:value="customerName"
                    @click:clear="clearCustomer()"
                  ></v-text-field>
                </template>
                <Available
                  v-bind:allwedOnly="false"
                  @clicked="selected"
                  @closed="closed"
                />
              </v-dialog>
            </v-col>
            <v-col cols="12" md="4" sm="4" class="actionsCol">
              <v-btn small :disabled="!valid" @click="getReport()"
                >Generar Reporte</v-btn
              >

            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Available from "./caseComponents/AvailableCustomers";

export default {
  components: {
    Available,
  },
  data() {
    return {
      valid: false,
      dateModal: false,
      customerModal: false,
      items: [],
      dateRules: [
        (v) => v.length == 2 || "Fecha Inicial y Fecha Final Requeridas",
      ],
      customer: {
        dni: "",
        name: "",
        profession: "",
        phone: "",
        cellPhone: "",
        salary: "",
        address: "",
        bonusType: 0,
        bonusName: "",
        maritalStatus: "",
        userId: 0,
        email: "",
      },
    };
  },

  methods: {
    getReport() {
      let client = this.getCustomer();
      axios
        .get("/api/Report/DownloadVisitReport?clientDNI=" + client)
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            window.open(response.data, "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getCustomer() {
      return this.customer.dni.length > 0 ? this.customer.dni : "-1";
    },

    selected(value) {
      this.customer = Object.assign({}, value);
      this.customerModal = false;
    },

    clearCustomer() {
      console.log("clear");
      this.customer = {
        dni: "",
        name: "",
        profession: "",
        phone: "",
        cellPhone: "",
        salary: "",
        address: "",
        bonusType: 0,
        bonusName: "",
        maritalStatus: "",
        userId: 0,
        email: "",
      };
    },

    closed() {
      this.customerModal = false;
    },
  },
  computed: {
    customerName() {
      return this.customer.dni + " - " + this.customer.name;
    },
  },
};
</script>

<style>
.actionsCol .v-btn {
  margin-right: 10px;
}
</style>