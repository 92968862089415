import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import Layout from '../views/Layout.vue';
import Customers from '../components/Customers.vue'
import Cases from '../components/Cases.vue'
import Users from '../components/Users.vue'
import BonusStatus from '../components/BonusStatus.vue'
import BonusType from '../components/BonusType.vue'
import Sponsors from '../components/Sponsors.vue'
import Art59 from '../components/ART59.vue'
import Report from '../components/Report.vue'
import Report_Visit from '../components/Report_Visit.vue'
import Report_NewCase from '../components/Report_NewCase.vue'
import Art59_pruebas from '../components/ART59_PRUEBAS.vue'

import Articulos from '../components/Articulos.vue'
import Clientes from '../components/Clientes.vue'
import Cias from '../components/Cias.vue'
import FacturaElectronica from '../components/FacturaElectronica.vue'
import TiqueteElectronico from '../components/TiqueteElectronico.vue'
import NotaCreditoElectronica from '../components/NotaCreditoElectronica.vue'
import Usuarios from '../components/Usuarios.vue'
import Compras from '../components/Compras.vue'
import NotaDebitoElectronica from '../components/NotaDebitoElectronica.vue'
import FacturaCompraElectronica from '../components/FacturaCompraElectronica.vue'
import Proveedores from '../components/Proveedores.vue'
import ReporteContable from '../components/ReporteContable.vue'
import ReporteRecibidos from '../components/ReporteRecibidos.vue'
import ReporteEmitidos from '../components/ReporteEmitidos.vue'
import QRGenerator from '../components/QRGenerator.vue'
import PagoSinpe from '../components/PagoSinpe.vue'
import UsuariosEditar from '../components/UsuariosEditar.vue'
import POS from '../components/POS.vue'
import Dashboard from '../components/Dashboard.vue'
import IngresoArticulos from '../components/IngresoArticulos.vue'
import SalidaArticulos from '../components/SalidaArticulos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Layout,
    children: [
      {
        path: '/articulos',
        name: 'Articulos',
        component: Articulos
      },
      {
        path: '/clientes',
        name: 'Clientes',
        component: Clientes
      },
      {
        path: '/cias',
        name: 'Cias',
        component: Cias
      },
      {
        path: '/facturas',
        name: 'FacturaElectronica',
        component: FacturaElectronica
      },
      {
        path: '/tiquetes',
        name: 'TiqueteElectronico',
        component: TiqueteElectronico
      },
      {
        path: '/notasdebito',
        name: 'NotaDebitoElectronica',
        component: NotaDebitoElectronica
      },
      {
        path: '/notascredito',
        name: 'NotaCreditoElectronica',
        component: NotaCreditoElectronica
      },
      {
        path: '/usuarios',
        name: 'Usuarios',
        component: Usuarios
      },
      {
        path: '/compras',
        name: 'Compras',
        component: Compras
      },
      {
        path: '/facturascompras',
        name: 'FacturaCompraElectronica',
        component: FacturaCompraElectronica
      },
      {
        path: '/proveedores',
        name: 'Proveedores',
        component: Proveedores
      },
      {
        path: '/reportecontable',
        name: 'ReporteContable',
        component: ReporteContable
      },
      {
        path: '/reporterecibidos',
        name: 'ReporteRecibidos',
        component: ReporteRecibidos
      },
      {
        path: '/reporteemitidos',
        name: 'ReporteEmitidos',
        component: ReporteEmitidos
      },
      {
        path: '/usuarioseditar',
        name: 'UsuariosEditar',
        component: UsuariosEditar
      },
      {
        path: '/qrGenerator',
        name: 'QRGenerator',
        component: QRGenerator
      },
      {
        path: '/pagoSinpe',
        name: 'PagoSinpe',
        component: PagoSinpe
      },
      {
        path: '/pos',
        name: 'POS',
        component: POS
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/ingresoarticulos',
        name: 'IngresoArticulos',
        component: IngresoArticulos
      },
      {
        path: '/salidaarticulos',
        name: 'SalidaArticulos',
        component: SalidaArticulos
      },
      {
        path: '/cases',
        name: 'Cases',
        component: Cases
      },
      {
        path: '/customers',
        name: 'Customers',
        component: Customers
      },
      {
        path: '/users',
        name: 'Users',
        component: Users
      },
      {
        path: '/bonusType',
        name: 'BonusType',
        component: BonusType
      },
      {
        path: '/BonusStatus',
        name: 'BonusStatus',
        component: BonusStatus
      },
      {
        path: '/sponsors',
        name: 'Sponsors',
        component: Sponsors
      },
      {
        path: '/art59',
        name: 'Art59',
        component: Art59
      },
      {
        path: '/report',
        name: 'Report',
        component: Report
      },
      {
        path: '/report_Visit',
        name: 'Report_Visit',
        component: Report_Visit
      },
      {
        path: '/report_NewCase',
        name: 'Report_NewCase',
        component: Report_NewCase
      },
      {
        path: '/art59_pruebas',
        name: 'pruebas',
        component: Art59_pruebas
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
