<template>
  <div>
    <v-snackbar v-model="snackbar" color="primary" elevation="2">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: ''
        },
        snackbar: {
            type: Boolean,
            default: false
        }
    },
}
</script>