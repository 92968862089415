<template>
  <div class="renderPage">
    <v-tabs
      background-color="primary"
      icons-and-text
      fixed-tabs
      center-active
      v-model="tab2"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab key="tiquetes_registrados" @click="limpiar()">
        Tiquetes Registrados
        <v-icon>mdi-content-save</v-icon>
      </v-tab>
      <v-tab key="tiquetes_nuevos" @click="limpiar()">
        Tiquete
        <v-icon>mdi-pencil-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="tiquetes_registrados">
        <v-spacer></v-spacer>
        <v-card>
          <v-col cols="12" sm="12" md="12"> </v-col>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-dialog
                  ref="dialog"
                  v-model="dateModal"
                  :return-value.sync="dates"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="datesFormat"
                      label="Rango de fechas"
                      prepend-icon="mdi-calendar"
                      readonly
                      required
                      :rules="dateRules"
                      v-bind="attrs"
                      v-on="on"
                      class="pa-0"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    range
                    @click="setDatesFormat()"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateModal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dialog.save(dates);
                        setDatesFormat();
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-btn color="primary" @click="getData()" :disabled="!valid">
                  <v-icon left> mdi-file-find </v-icon>
                  Buscar
                </v-btn>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Filtrar resultados"
                  single-line
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-data-table :headers="headers" :items="items" :search="search">
            <template v-slot:top> </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
            </template></v-data-table
          >
        </v-card>

        <!-- <v-data-table :headers="headers" :items="items" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field
                class="text-xs-center"
                v-model="search"
                append-icon="mdi-magnify"
                label="Búsqueda"
                single-line
                hide-details
                dense
              >
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
          </template>
        </v-data-table> -->
        <!-- <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" /> -->
      </v-tab-item>
      <v-tab-item key="tiquetes_nuevos">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="12">
                <v-expansion-panels multiple v-model="panel">
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Seleccionar Cliente</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              label="# Cliente"
                              v-model="editedItem.clienteCod"
                              class="pa-0"
                              outlined
                              dense
                              @input="changeText"
                              :disabled="incluirValid"
                            >
                              <v-icon
                                slot="append-outer"
                                @click="BuscarClienteCodigo"
                                v-if="iconVisible"
                                :disabled="incluirValid"
                              >
                                mdi-file-find
                              </v-icon>
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              label="Cédula"
                              v-model="editedItem.clienteCedula"
                              class="pa-0"
                              @input="activarIconoCedula"
                              outlined
                              dense
                              :disabled="incluirValid"
                            >
                              <v-icon
                                slot="append-outer"
                                @click="BuscarClienteCedula"
                                v-if="iconVisibleCedula"
                                :disabled="incluirValid"
                              >
                                mdi-file-find
                              </v-icon>
                            </v-text-field>
                          </v-col>

                          <v-col align="center" cols="12" sm="1" md="1">
                            <v-btn
                              color="primary"
                              @click="mostrarBuscarClientes"
                              class="pa-0"
                              :disabled="!btnAgregarCliente"
                            >
                              <v-icon>mdi-file-find</v-icon>
                            </v-btn>
                          </v-col>

                          <v-dialog
                            transition="dialog-bottom-transition"
                            v-model="dialog"
                          >
                            <div v-if="showNewData">
                              <BuscarClientes
                                v-bind:allwedOnly="true"
                                @clicked="selected"
                                @closed="closed"
                              />
                            </div>
                          </v-dialog>

                          <v-dialog
                            transition="dialog-bottom-transition"
                            v-model="dialogSinpe"
                          >
                            <div v-if="showNewData">
                              <PagoSinpe
                                v-bind:allwedOnly="true"
                                v-bind:pagoInfo="pagoInfo"
                                @closed="closed"
                              />
                            </div>
                          </v-dialog>

                          <v-dialog
                            transition="dialog-bottom-transition"
                            v-model="dialogFormaReenvio"
                            max-width="600"
                          >
                            <div v-if="showNewData">
                              <FormaReenvio
                                v-bind:allwedOnly="true"
                                v-bind:reenvioInfo="reenvioInfo"
                                @clicked="selectedReenvio"
                                @closed="closed"
                              />
                            </div>
                          </v-dialog>

                          <v-col cols="12" sm="5" md="5">
                            <v-text-field
                              class="pa-0"
                              v-model="editedItem.clienteNombre"
                              label="Nombre"
                              outlined
                              dense
                              :disabled="incluirValid"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <v-tabs
                background-color="primary"
                icons-and-text
                fixed-tabs
                center-active
                v-model="tabFactura"
                height="40"
              >
                <v-tabs-slider color="white"></v-tabs-slider>
                <v-tab key="detalle" @click="limpiar()">
                  Lineas de detalle
                </v-tab>
                <v-tab key="emisor" @click="limpiar()"> Emisor </v-tab>
                <v-tab key="receptor" @click="limpiar()"> Receptor </v-tab>
                <v-tab key="hacienda" @click="limpiar()">
                  Información adicional
                </v-tab>
              </v-tabs>

              <v-col cols="12" sm="12" md="12">
                <v-card>
                  <v-tabs-items v-model="tabFactura">
                    <v-tab-item key="detalle">
                      <v-data-table
                        hide-default-footer
                        :headers="headersDetalle"
                        :items="lineasDetalle"
                        :search="search"
                        :items-per-page="-1"
                      >
                        <template v-slot:top>
                          <v-toolbar height="48">
                            <v-spacer></v-spacer>
                            <template>
                              <v-dialog
                                transition="dialog-bottom-transition"
                                v-model="dialogBuscarArticulos"
                              >
                                <div v-if="showNewDataArti">
                                  <BuscarArticulos
                                    v-bind:allwedOnly="true"
                                    @clicked="selectedArticulos"
                                    @closed="closed"
                                  />
                                </div>
                              </v-dialog>
                              <v-btn
                                color="green"
                                class="pa-0"
                                :disabled="!btnAgregarArti"
                                @click="mostrarBuscarArticulos"
                              >
                                <v-icon color="white">mdi-cart-plus</v-icon>
                              </v-btn>
                            </template>
                            <v-dialog
                              v-model="dialogArticulos"
                              max-width="700px"
                            >
                              <!-- <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="green"
                                  class="pa-0"
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="!btnAgregarArti"
                                  @click="mostrarBuscarArticulos"
                                >
                                  <v-icon color="white">mdi-cart-plus</v-icon>
                                </v-btn>
                              </template> -->

                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="5" md="3">
                                        <v-text-field
                                          v-model="detalleEditedItem.articulo"
                                          outlined
                                          dense
                                          label="Código"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                          v-model="detalleEditedItem.cabys"
                                          label="Cabys"
                                          outlined
                                          dense
                                          readonly
                                        ></v-text-field>
                                      </v-col>

                                      <v-col cols="12" sm="2" md="2">
                                        <v-btn
                                          color="primary"
                                          class="pa-0"
                                          @click="mostrarBuscarArticulos"
                                        >
                                          <v-icon>mdi-file-find</v-icon>
                                        </v-btn>
                                      </v-col>

                                      <v-dialog
                                        transition="dialog-bottom-transition"
                                        v-model="dialogBuscarArticulos"
                                      >
                                        <div v-if="showNewDataArti">
                                          <BuscarArticulos
                                            v-bind:allwedOnly="true"
                                            @clicked="selectedArticulos"
                                            @closed="closed"
                                          />
                                        </div>
                                      </v-dialog>

                                      <v-col cols="12" sm="4" md="3">
                                        <v-text-field
                                          v-model="detalleEditedItem.cantidad"
                                          type="number"
                                          label="Cantidad"
                                          step="any"
                                          min="0"
                                          outlined
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="8" md="6">
                                        <v-text-field
                                          v-model="
                                            detalleEditedItem.descripcion
                                          "
                                          label="Descripción"
                                          outlined
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="5" md="3">
                                        <v-text-field
                                          v-model="detalleEditedItem.monto"
                                          label="Precio"
                                          type="number"
                                          step="any"
                                          min="0"
                                          outlined
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="5" md="3">
                                        <v-text-field
                                          v-model="
                                            detalleEditedItem.porcDescuento
                                          "
                                          label="% Descuento"
                                          type="number"
                                          step="any"
                                          min="0"
                                          outlined
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>

                                  <v-btn
                                    color="primary"
                                    class="mb-2"
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    class="mb-2"
                                    @click="saveDetalleArticulo"
                                  >
                                    Agregar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5"
                                  >Desea descartar el articulo?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    class="mb-2"
                                    @click="closeDelete"
                                    >Cancelar</v-btn
                                  >
                                  <v-btn
                                    color="primary"
                                    class="mb-2"
                                    @click="deleteItemConfirm"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            color="primary"
                            class="pa-0"
                            @click="detalleEditItem(item)"
                          >
                            <v-icon color="white"> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:[`item.delete`]="{ item }">
                          <v-btn
                            color="red"
                            class="pa-0"
                            @click="deleteItem(item)"
                          >
                            <v-icon color="white"> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>

                      <v-container>
                        <v-row>
                          <v-expansion-panels multiple v-model="panelResumen">
                            <v-expansion-panel>
                              <v-expansion-panel-header
                                >Resumen Tiquete</v-expansion-panel-header
                              >
                              <v-expansion-panel-content>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field
                                        v-model="editedItem.estadoHacienda"
                                        label="Estado"
                                        class="pa-0"
                                        readonly
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="1" md="1">
                                      <v-tooltip bottom>
                                        <template #activator="{ on }">
                                          <v-btn
                                            color="primary"
                                            @click="consultarEstadoHacienda"
                                            class="pa-0"
                                            v-on="on"
                                            :disabled="!consultarEstadoValid"
                                          >
                                            <v-icon>mdi-cloud-sync</v-icon>
                                          </v-btn>
                                        </template>
                                        <span>Consultar estado hacienda</span>
                                      </v-tooltip>
                                    </v-col>

                                    <v-col cols="12" sm="1" md="1">
                                      <v-progress-circular
                                        :size="50"
                                        :width="10"
                                        color="primary"
                                        indeterminate
                                        v-if="visibleProgress"
                                      ></v-progress-circular>
                                    </v-col>

                                    <v-col cols="12" sm="1" md="1">
                                      <v-text-field
                                        label="Espacio separacion"
                                        class="pa-0"
                                        v-if="visible"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field
                                        v-model="editedItem.totalIVADevueltoStr"
                                        label="Iva Devuelto"
                                        step="any"
                                        min="0"
                                        readonly
                                        class="right-input pa-0"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field
                                        v-model="editedItem.subtotalStr"
                                        label="Sub Total"
                                        step="any"
                                        min="0"
                                        readonly
                                        class="right-input pa-0"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-model="editedItem.clavehacienda"
                                        label="Clave Hacienda"
                                        class="pa-0"
                                        readonly
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field
                                        v-model="editedItem.totalDescuentosStr"
                                        label="Total Descuentos"
                                        step="any"
                                        min="0"
                                        readonly
                                        class="right-input pa-0"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field
                                        v-model="editedItem.totalImpuestoStr"
                                        label="Total Impuestos"
                                        step="any"
                                        min="0"
                                        readonly
                                        class="right-input pa-0"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                      <v-text-field
                                        v-model="editedItem.totalIva1Str"
                                        label="Iva 1%"
                                        step="any"
                                        min="0"
                                        readonly
                                        class="right-input pa-0"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                      <v-text-field
                                        v-model="editedItem.totalIva2Str"
                                        label="Iva 2%"
                                        step="any"
                                        min="0"
                                        readonly
                                        class="right-input pa-0"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                      <v-text-field
                                        v-model="editedItem.totalIva4Str"
                                        label="Iva 4%"
                                        step="any"
                                        min="0"
                                        readonly
                                        class="right-input pa-0"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                      <v-text-field
                                        v-model="editedItem.totalIva13Str"
                                        label="Iva 13%"
                                        step="any"
                                        min="0"
                                        readonly
                                        class="right-input pa-0"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="1" md="1">
                                      <v-text-field
                                        label="Espacio separacion"
                                        class="pa-0"
                                        v-if="visible"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field
                                        v-model="editedItem.totalComprobanteStr"
                                        label="Total Comprobante"
                                        step="any"
                                        min="0.00"
                                        readonly
                                        class="right-input pa-0"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                    <v-tab-item key="emisor">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="3" md="2">
                            <v-text-field
                              v-model="editedItem.codigoFact"
                              label="# Factura Interno"
                              class="pa-0"
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="3" md="2">
                            <v-text-field
                              v-model="editedItem.cia"
                              label="Cédula"
                              class="pa-0"
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col col="12" md="2" sm="2">
                            <v-select
                              label="Moneda"
                              item-text="md_nombre"
                              item-value="md_codigo"
                              v-model="editedItem.moneda"
                              :items="monedas"
                              @change="changeMonedas"
                              class="pa-0"
                              outlined
                              dense
                            ></v-select>
                          </v-col>

                          <v-col cols="12" sm="3" md="6">
                            <v-text-field
                              v-model="editedItem.emisorNombre"
                              label="Nombre"
                              :rules="nameRules"
                              required
                              class="pa-0"
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.emisorNombreComercial"
                              label="Nombre Comercial"
                              :rules="nameRules"
                              required
                              class="pa-0"
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col col="12" md="2" sm="2">
                            <v-select
                              label="Actividad Economica"
                              item-text="descripcion"
                              item-value="codigo"
                              v-model="editedItem.codigoActividad"
                              :items="actividades"
                              class="pa-0"
                              outlined
                              dense
                            ></v-select>
                          </v-col>

                          <v-col cols="12" sm="3" md="2">
                            <v-text-field
                              v-model="editedItem.emisorTelefono"
                              label="Teléfono"
                              class="pa-0"
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="3" md="2">
                            <v-text-field
                              v-model="editedItem.tipoCambio"
                              label="Tipo de Cambio"
                              class="pa-0"
                              type="number"
                              step="any"
                              min="1"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="3" md="2">
                            <v-text-field
                              type="text"
                              v-model="fecha"
                              label="Fecha"
                              class="pa-0"
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="3" md="4">
                            <v-text-field
                              v-model="editedItem.emisorCorreo"
                              label="Correo"
                              class="pa-0"
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col col="12" md="2" sm="2">
                            <v-select
                              label="Condición Venta"
                              item-text="descripcion"
                              item-value="codigo"
                              v-model="editedItem.condicionVenta"
                              :items="condicionesVenta"
                              class="pa-0"
                              outlined
                              dense
                            ></v-select>
                          </v-col>

                          <v-col col="12" md="2" sm="2">
                            <v-select
                              label="Medio de pago"
                              item-text="descripcion"
                              item-value="codigo"
                              v-model="editedItem.medioPago"
                              :items="mediosPago"
                              class="pa-0"
                              outlined
                              dense
                            ></v-select>
                          </v-col>

                          <v-col col="12" md="2" sm="2">
                            <v-select
                              label="Sucursal"
                              item-text="sucursalId"
                              item-value="sucursalId"
                              v-model="editedItem.sucursal"
                              :items="sucursales"
                              v-on:change="onChangeSite($event)"
                              class="pa-0"
                              outlined
                              dense
                            ></v-select>
                          </v-col>

                          <v-col col="12" md="2" sm="2">
                            <v-select
                              label="Terminal"
                              item-text="terminalId"
                              item-value="terminalId"
                              v-model="editedItem.terminal"
                              :items="terminales"
                              class="pa-0"
                              outlined
                              dense
                            ></v-select>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-textarea
                              v-model="editedItem.emisorDireccion"
                              label="Dirección"
                              auto-grow
                              outlined
                              rows="1"
                              row-height="15"
                              class="pa-0"
                              readonly
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>

                    <v-tab-item key="receptor">
                      <v-container>
                        <v-row>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="2" md="2">
                                <v-text-field
                                  v-model="editedItem.clienteAreaTel"
                                  label="Area Tel"
                                  class="pa-0"
                                  readonly
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="2" md="2">
                                <v-text-field
                                  v-model="editedItem.clienteTelefono"
                                  label="Teléfono"
                                  class="pa-0"
                                  readonly
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="4" md="4">
                                <v-text-field
                                  v-model="editedItem.clienteNombreComercial"
                                  label="Nombre Comercial"
                                  class="pa-0"
                                  readonly
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="4" md="4">
                                <v-text-field
                                  v-model="editedItem.clientecorreo"
                                  label="Correo"
                                  class="pa-0"
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>

                              <v-col col="12" md="3" sm="3">
                                <v-select
                                  label="Provincia"
                                  item-text="pr_nombre"
                                  item-value="pr_codigo"
                                  v-model="editedItem.clienteProvincia"
                                  :items="provincias"
                                  @change="changeProvincia"
                                  class="pa-0"
                                  readonly
                                  outlined
                                  dense
                                ></v-select>
                              </v-col>

                              <v-col col="12" md="3" sm="3">
                                <v-select
                                  label="Canton"
                                  item-text="ct_nombre"
                                  item-value="ct_codigo"
                                  v-model="editedItem.clienteCanton"
                                  :items="cantones"
                                  @change="changeCantones"
                                  class="pa-0"
                                  readonly
                                  outlined
                                  dense
                                ></v-select>
                              </v-col>

                              <v-col col="12" md="3" sm="3">
                                <v-select
                                  label="Distrito"
                                  item-text="dt_nombre"
                                  item-value="dt_codigo"
                                  v-model="editedItem.clienteDistrito"
                                  :items="distritos"
                                  @change="changeDistritos"
                                  class="pa-0"
                                  readonly
                                  outlined
                                  dense
                                ></v-select>
                              </v-col>

                              <v-col col="12" md="3" sm="3">
                                <v-select
                                  label="Barrio"
                                  item-text="br_nombre"
                                  item-value="br_codigo"
                                  v-model="editedItem.clienteBarrio"
                                  :items="barrios"
                                  class="pa-0"
                                  readonly
                                  outlined
                                  dense
                                ></v-select>
                              </v-col>

                              <v-col cols="12" sm="2" md="6">
                                <v-textarea
                                  v-model="editedItem.clienteDireccion"
                                  label="Dirección"
                                  auto-grow
                                  outlined
                                  rows="1"
                                  row-height="15"
                                  class="pa-0"
                                  readonly
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-row>
                      </v-container>
                    </v-tab-item>

                    <v-tab-item key="hacienda">
                      <v-container>
                        <v-row>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                  v-model="editedItem.ordenCompra"
                                  label="Orden de compra"
                                  class="pa-0"
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="1" md="1">
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-btn
                                      color="primary"
                                      @click="getReport"
                                      class="pa-0"
                                      v-on="on"
                                      :disabled="!verPDF"
                                    >
                                      <v-icon>mdi-file-pdf</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Ver PDF</span>
                                </v-tooltip>
                              </v-col>

                              <v-col cols="12" sm="1" md="1">
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-btn
                                      color="primary"
                                      @click="getXMLFirmado"
                                      class="pa-0"
                                      v-on="on"
                                      :disabled="!verXMLFirmado"
                                    >
                                      <v-icon>mdi-file-export</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Ver XML Enviado</span>
                                </v-tooltip>
                              </v-col>

                              <v-col cols="12" sm="1" md="1">
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-btn
                                      color="primary"
                                      @click="getXMLRespuesta"
                                      class="pa-0"
                                      v-on="on"
                                      :disabled="!verXMLRespuesta"
                                    >
                                      <v-icon>mdi-file-import</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Ver XML Respuesta</span>
                                </v-tooltip>
                              </v-col>

                              <v-col cols="12" sm="9" md="9">
                                <v-textarea
                                  v-model="editedItem.observacion"
                                  label="Observación"
                                  outlined
                                  rows="1"
                                  row-height="15"
                                  class="pa-0"
                                ></v-textarea>
                              </v-col>

                              <v-col cols="12" sm="9" md="9">
                                <v-textarea
                                  v-model="editedItem.mensajeHacienda"
                                  label="Mensaje Hacienda"
                                  outlined
                                  class="pa-0"
                                  readonly
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <div>
            <v-expansion-panels multiple v-model="panel"> </v-expansion-panels>
          </div>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>

          <v-row align="center" justify="space-around">
            <v-btn
              color="primary"
              :disabled="!incluirValid"
              @click="validarLicencia()"
            >
              Incluir
            </v-btn>
            <v-btn color="primary" :disabled="!guardarValid" @click="save()">
              Guardar
            </v-btn>
            <v-btn color="primary" :disabled="!cancelarValid" @click="cerrar()">
              Cancelar
            </v-btn>

            <v-btn
              color="primary"
              :disabled="!imprimirValid"
              @click="imprimir()"
            >
              Imprimir
            </v-btn>

            <v-btn
              color="primary"
              :disabled="!reenviarValid"
              @click="reenviar()"
            >
              Reenviar
            </v-btn>
          </v-row>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
import BuscarClientes from "./BuscarClientes.vue";
import BuscarArticulos from "./BuscarArticulos.vue";
import PagoSinpe from "./PagoSinpe.vue";
import FormaReenvio from "./FormaReenvio.vue";

export default {
  components: {
    Snackbar,
    BuscarClientes,
    BuscarArticulos,
    PagoSinpe,
    FormaReenvio,
  },
  // dark: true,
  data() {
    return {
      btnAgregarCliente: false,
      btnAgregarArti: false,
      iconVisible: false,
      iconVisibleCedula: false,
      visibleBtnConsultar: true,
      visibleProgress: false,
      dialogSinpe: false,
      dialogFormaReenvio: false,
      dialog: false,
      dialogArticulos: false,
      valid: false,
      incluirValid: true,
      consultarEstadoValid: false,
      cancelarValid: false,
      reenviarValid: false,
      imprimirValid: false,
      guardarValid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab2: 1,
      tabFactura: 0,
      dialogDelete: false,
      verPDF: false,
      verXMLFirmado: false,
      verXMLRespuesta: false,
      dateModal: false,
      dates: [],
      datesFormat: [],
      panel: [],
      panelResumen: [],
      licenciaValida: [],
      headers: [
        {
          text: "Código",
          align: "start",
          value: "codigoFact",
        },
        {
          text: "Cliente",
          align: "start",
          value: "clienteNombre",
        },
        {
          text: "Fecha",
          align: "start",
          value: "fechaString",
        },
        {
          text: "Clave",
          align: "start",
          value: "clavehacienda",
        },
        {
          text: "Estado",
          align: "start",
          value: "estadoHacienda",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
      ],
      headersDetalle: [
        {
          text: "Cant",
          align: "start",
          value: "cantidad",
        },
        {
          text: "Código",
          align: "start",
          value: "articulo",
        },
        {
          text: "Descripción",
          align: "start",
          value: "descripcion",
        },
        {
          text: "Unidad",
          align: "start",
          value: "unidad",
        },
        {
          text: "Precio",
          align: "start",
          value: "montoStr",
        },
        {
          text: "Total",
          align: "start",
          value: "totalLineaStr",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
        { text: "Eliminar", value: "delete", width: 70, sortable: false },
      ],
      items: [],
      tiposIdentificacion: [],
      actividades: [],
      condicionesVenta: [],
      mediosPago: [],
      sucursales: [],
      terminales: [],
      monedas: [],
      provincias: [],
      cantones: [],
      distritos: [],
      barrios: [],
      cliente: [],
      articulo: [],
      totales: [],
      lineasDetalle: [],
      tipoCambio: [],
      respuestaHacienda: [],
      comprobante: [],
      editedIndex: -1,
      modelFactura: {
        comprobante: [],
        comprobanteDetalle: [],
      },
      editedItem: {
        cia: "",
        codigoFact: "",
        tipoFact: "",
        fecha: new Date().toISOString().slice(0, 10),
        tipoCambio: 1,
        clienteCod: "",
        clienteNombre: "",
        clienteCorreo: "",
        clienteCedula: "",
        clienteAreaTel: "",
        clienteTelefono: "",
        clienteNombreComercial: "",
        clienteProvincia: "",
        clienteCanton: "",
        clienteDistrito: "",
        clienteBarrio: "",
        clienteDireccion: "",
        clienteTipoId: "",
        mensajeHacienda: "",
        moneda: "",
        estadoHacienda: "",
        clavehacienda: "",
        subtotal: 0,
        totalDescuentos: 0,
        totalImpuesto: 0,
        totalComprobante: 0,
        codigoDev: "",
        estadoDev: 0,
        consHacienda: "",
        condicionVenta: "",
        medioPago: "",
        plazoCredito: 0,
        sucursal: "",
        terminal: "",
        xml: "",
        xmlFirmado: "",
        respuestaHacienda: "",
        fechaHacienda: Date,
        totalGravado: 0,
        totalExento: 0,
        consHacReal: "",
        cedulaReceptor: "",
        ordenCompra: "",
        observacion: "",
        usuario: "",
        enviado: false,
        reenviado: false,
        totalServGravados: 0,
        totalServExentos: 0,
        totalServExonerado: 0,
        totalMercanciasExentas: 0,
        totalMercanciasGravadas: 0,
        totalMercExonerada: 0,
        totalExonerado: 0,
        totalVenta: 0,
        totalVentaNeta: 0,
        totalIVADevuelto: 0,
        totalOtrosCargos: 0,
        totalIva1: 0,
        totalIva2: 0,
        totalIva4: 0,
        totalIva8: 0,
        totalIva13: 0,
        codigoActividad: "",
        emisorNombre: "",
        emisorNombreComercial: "",
        emisorTelefono: "",
        emisorCorreo: "",
        emisorDireccion: "",
        subTotal: 0,
        totalIVADevueltoStr: "",
        totalIva1Str: "",
        totalIva2Str: "",
        totalIva4Str: "",
        totalIva13Str: "",
        totalDescuentosStr: "",
        totalImpuestoStr: "",
        subtotalStr: "",
        totalComprobanteStr: "",
        nombreTabla: "",
        contingenciaFact: "",
        contingenciaFecha: Date,
      },
      defaultItem: {
        cia: "",
        codigoFact: "",
        tipoFact: "",
        fecha: Date,
        tipoCambio: 1,
        clienteCod: "",
        clienteNombre: "",
        clienteCorreo: "",
        clienteCedula: "",
        clienteAreaTel: "",
        clienteTelefono: "",
        clienteNombreComercial: "",
        clienteProvincia: "",
        clienteCanton: "",
        clienteDistrito: "",
        clienteBarrio: "",
        clienteDireccion: "",
        clienteTipoId: "",
        mensajeHacienda: "",
        moneda: "",
        estadoHacienda: "",
        clavehacienda: "",
        subtotal: 0,
        totalDescuentos: 0,
        totalImpuesto: 0,
        totalComprobante: 0,
        codigoDev: "",
        estadoDev: 0,
        consHacienda: "",
        condicionVenta: "",
        medioPago: "",
        plazoCredito: 0,
        sucursal: "",
        terminal: "",
        xml: "",
        xmlFirmado: "",
        respuestaHacienda: "",
        fechaHacienda: Date,
        totalGravado: 0,
        totalExento: 0,
        consHacReal: "",
        cedulaReceptor: "",
        ordenCompra: "",
        observacion: "",
        usuario: "",
        enviado: false,
        reenviado: false,
        totalServGravados: 0,
        totalServExentos: 0,
        totalServExonerado: 0,
        totalMercanciasExentas: 0,
        totalMercanciasGravadas: 0,
        totalMercExonerada: 0,
        totalExonerado: 0,
        totalVenta: 0,
        totalVentaNeta: 0,
        totalIVADevuelto: 0,
        totalOtrosCargos: 0,
        totalIva1: 0,
        totalIva2: 0,
        totalIva4: 0,
        totalIva8: 0,
        totalIva13: 0,
        codigoActividad: "",
        emisorNombre: "",
        emisorNombreComercial: "",
        emisorTelefono: "",
        emisorCorreo: "",
        emisorDireccion: "",
        subTotal: 0,
        totalIVADevueltoStr: "",
        totalIva1Str: "",
        totalIva2Str: "",
        totalIva4Str: "",
        totalIva13Str: "",
        totalDescuentosStr: "",
        totalImpuestoStr: "",
        subtotalStr: "",
        totalComprobanteStr: "",
        nombreTabla: "",
        contingenciaFact: "",
        contingenciaFecha: Date,
      },
      ciaItem: {
        nombre: "",
        nombreComercial: "",
        cedula: "",
        provincia: "",
        canton: "",
        distrito: "",
        barrio: "",
        direccion: "",
        sucursal: "",
        terminal: "",
        moneda: "",
        moneda2: "",
        usuariohacienda: "",
        clavehacienda: "",
        propietarioCert: "",
        certificado: "",
        claveCert: "",
        esProd: false,
        correo: "",
        claveCcorreo: "",
        smtpCorreo: "",
        puertoCorreo: "",
        usaSeguridad: false,
        areaTel: "",
        telefono: "",
        tipoId: "",
        codigoActividad: "",
        image: "",
      },
      detalleditedIndex: -1,
      detalleEditedItem: {
        cia: "",
        factura: "",
        tipoFact: "",
        numLinea: 0,
        cantidad: 0,
        articulo: "",
        descripcion: "",
        unidad: "",
        monto: 0,
        descuento: 0,
        totalLinea: 0,
        impuestosVentas: 0,
        sucursal: "",
        terminal: "",
        tipoCodigo: "",
        unidadMedidaComercial: "",
        impCodigo: "",
        impCodigoTarifa: "",
        impTarifa: "",
        cabys: "",
        porcDescuento: 0,
        tipoCambio: 0,
        tipoProducto: "",
        montoTotal: 0,
        subTotal: 0,
        impuestoNeto: 0,
        moneda: "",
        montoStr: "",
        totalLineaStr: "",
        cant_Dev: 0,
        linea_Dev: 0,
        clienteCod: "",
        clienteCedula: "",
      },
      detalleDefaultItem: {
        cia: "",
        factura: "",
        tipoFact: "",
        numLinea: 0,
        cantidad: 0,
        articulo: "",
        descripcion: "",
        unidad: "",
        monto: 0,
        descuento: 0,
        totalLinea: 0,
        impuestosVentas: 0,
        sucursal: "",
        terminal: "",
        tipoCodigo: "",
        unidadMedidaComercial: "",
        impCodigo: "",
        impCodigoTarifa: "",
        impTarifa: "",
        cabys: "",
        porcDescuento: 0,
        tipoCambio: 0,
        tipoProducto: "",
        montoTotal: 0,
        subTotal: 0,
        impuestoNeto: 0,
        moneda: "",
        montoStr: "",
        totalLineaStr: "",
        cant_Dev: 0,
        linea_Dev: 0,
        clienteCod: "",
        clienteCedula: "",
      },
      pagoInfo: {
        sucursal: "",
        terminal: "",
        total: 0,
      },
      reenvioInfo: {
        numero: "",
        correo: "",
        porwhatsapp: false,
        porcorreo: false,
      },
      opcionesSistemaCia: [],
      showNewData: false,
      showNewDataArti: false,
      nameRules: [(v) => !!v || "Campo requerido"],
      tempFile: "",
      modelFile: null,
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
    formTitle() {
      return this.editedIndex === -1 ? "Agregar Articulo" : "Editar Articulo";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogArticulos(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.setDates();
    this.getData();
    this.getMonedas();
    this.getProvincias();
    this.getActividades();
    this.getCondicionVenta();
    this.getMedioPago();
    this.getOpcionesSistemaCia();
  },

  methods: {
    signalChange: function (evt) {
      this.snackText = "Si llega al escribir" + evt;
      this.snackValue = true;
    },

    changeText() {
      this.iconVisible = true;
    },

    activarIconoCedula() {
      this.iconVisibleCedula = true;
    },

    getData() {
      let me = this;
      axios
        .get(
          "api/Facturacion/GetComprobantes?cia=" +
            this.strCia +
            "&docNombre=Tiquetes&tipoDoc=04" +
            "&fechaInicio=" +
            this.dates[0].toString() +
            "&fechaFin=" +
            this.dates[1].toString(),
          {
            crossdomain: true,
          }
        )
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    printDate: function () {
      return new Date().tolocal;
    },

    getActividades() {
      let me = this;
      axios
        .get("api/General/getActividadesByCia?cia=" + this.strCia)
        .then(function (response) {
          me.actividades = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    setDates() {
      var date = new Date();
      //var dateInicio = new Date(date.getFullYear(), date.getMonth(), 1);
      var dateInicio = new Date(date.getFullYear(), date.getMonth(), 1);
      //var dateFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dates[0] = dateInicio.toISOString().substr(0, 10);
      this.dates[1] = date.toISOString().substr(0, 10);

      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      var yearInicio = dateInicio.getFullYear();
      var monthInicio = dateInicio.getMonth() + 1;
      var dtInicio = dateInicio.getDate();

      if (dtInicio < 10) {
        dtInicio = "0" + dtInicio;
      }
      if (monthInicio < 10) {
        monthInicio = "0" + monthInicio;
      }

      // this.snackText = "llega" + dt + '-' + month + '-' + year;
      // this.snackValue = true;

      this.datesFormat[0] = dtInicio + "-" + monthInicio + "-" + yearInicio;
      this.datesFormat[1] = dt + "-" + month + "-" + year;
    },

    setDatesFormat() {
      //eslint-disable-next-line
      var date = new Date(
        this.dates[0].toString().replace(/-/g, "/").replace(/T.+/, "")
      );

      //eslint-disable-next-line
      var date2 = new Date(
        this.dates[1].toString().replace(/-/g, "/").replace(/T.+/, "")
      );

      var year = date2.getFullYear();
      var month = date2.getMonth() + 1;
      var dt = date2.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      var yearInicio = date.getFullYear();
      var monthInicio = date.getMonth() + 1;
      var dtInicio = date.getDate();

      if (dtInicio < 10) {
        dtInicio = "0" + dtInicio;
      }
      if (monthInicio < 10) {
        monthInicio = "0" + monthInicio;
      }

      // this.snackText = "llega antes de sumar" + this.dates[0] + "-" + this.dates[1] + "luego de sumar: " + dtInicio + '-' + monthInicio + '-' + yearInicio + "-" + dt + '-' + month + '-' + year;
      // this.snackValue = true;

      this.datesFormat[0] = dtInicio + "-" + monthInicio + "-" + yearInicio;
      this.datesFormat[1] = dt + "-" + month + "-" + year;
    },

    getCondicionVenta() {
      let me = this;
      axios
        .get("api/General/getCondicionVenta")
        .then(function (response) {
          me.condicionesVenta = response.data;
          me.editedItem.condicionVenta = me.condicionesVenta[0]["codigo"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMedioPago() {
      let me = this;
      axios
        .get("api/General/getMediosPago")
        .then(function (response) {
          me.mediosPago = response.data;
          me.editedItem.medioPago = me.mediosPago[0]["codigo"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getCia() {
      let me = this;
      axios
        .get("api/Cias/getCia?ciaId=" + this.strCia)
        .then(function (response) {
          me.ciaItem = response.data;
          me.editedItem.emisorNombre = me.ciaItem.nombre;
          me.editedItem.emisorNombreComercial = me.ciaItem.nombreComercial;
          me.editedItem.codigoActividad = me.ciaItem.codigoActividad;
          me.editedItem.emisorTelefono = me.ciaItem.telefono;
          me.editedItem.emisorCorreo = me.ciaItem.correo;
          me.editedItem.emisorDireccion = me.ciaItem.direccion;
          me.editedItem.moneda = me.ciaItem.moneda;
          me.editedItem.cia = me.ciaItem.cedula;
          me.editedItem.fecha = me.printDate();

          me.getSucursales();
          me.pagoInfo.sucursal = me.ciaItem.sucursal;
          me.getTerminales(me.ciaItem.sucursal);
          me.editedItem.sucursal = me.ciaItem.sucursal;
          me.pagoInfo.terminal = me.ciaItem.terminal;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getSucursales() {
      let me = this;
      axios
        .get("api/Cias/getSucursalesByCia?cia=" + this.strCia)
        .then(function (response) {
          me.sucursales = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTerminales(sucursal) {
      let me = this;
      axios
        .get(
          "api/Cias/getTerminales?cia=" + this.strCia + "&sucursal=" + sucursal
        )
        .then(function (response) {
          me.terminales = response.data;
          me.editedItem.terminal = me.terminales[0]["terminalId"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getBarrios() {
      let me = this;
      axios
        .get(
          "api/General/getBarrios?provincia=" +
            this.editedItem.clienteProvincia +
            "&canton=" +
            this.editedItem.clienteCanton +
            "&distrito=" +
            this.editedItem.clienteDistrito
        )
        .then(function (response) {
          me.barrios = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeDistritos() {
      this.getBarrios();
    },

    getDistritos() {
      let me = this;
      axios
        .get(
          "api/General/getDistritos?provincia=" +
            this.editedItem.clienteProvincia +
            "&canton=" +
            this.editedItem.clienteCanton
        )
        .then(function (response) {
          me.distritos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeCantones() {
      this.getDistritos();
    },

    getCantones() {
      let me = this;
      axios
        .get(
          "api/General/getCantones?provincia=" +
            this.editedItem.clienteProvincia
        )
        .then(function (response) {
          me.cantones = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeProvincia() {
      this.getCantones();
    },

    changeSucursal() {
      this.getTerminales();
      this.pagoInfo.sucursal = this.editedItem.sucursal;
      this.pagoInfo.terminal = this.editedItem.terminal;
    },

    getProvincias() {
      let me = this;
      axios
        .get("api/General/getProvincias")
        .then(function (response) {
          me.provincias = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMonedas() {
      let me = this;
      axios
        .get("api/General/getMonedas")
        .then(function (response) {
          me.monedas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    imprimir() {
      this.snackValue = false;
      axios
        .get(
          "api/Facturacion/getPdf?cia=" +
            this.strCia +
            "&clave=" +
            this.editedItem.clavehacienda
        )
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            window.open(response.data + ".pdf", "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    BuscarClienteCodigo() {
      this.snackValue = false;
      let me = this;
      if (me.editedItem.clienteCod !== "") {
        me.showNewData = false;
        axios
          .get(
            "api/Clientes/getCliente?cia=" +
              this.strCia +
              "&codigo=" +
              this.editedItem.clienteCod
          )
          .then(function (response) {
            me.cliente = response.data;
            me.showNewData = false;
            me.dialog = false;
            me.dialogArticulos = false;
            me.editedItem.clienteCod = me.cliente.codigo;
            me.editedItem.clienteNombre = me.cliente.nombre;
            me.editedItem.clientecorreo = me.cliente.correo;
            me.editedItem.clienteCedula = me.cliente.cedula;
            me.editedItem.clienteAreaTel = me.cliente.areaTel;
            me.editedItem.clienteTelefono = me.cliente.telefono;
            me.editedItem.clienteNombreComercial = me.cliente.nombreComercial;
            me.getProvincias();
            me.editedItem.clienteProvincia = me.cliente.provincia;
            me.getCantones();
            me.editedItem.clienteCanton = me.cliente.canton;
            me.getDistritos();
            me.editedItem.clienteDistrito = me.cliente.distrito;
            me.getBarrios();
            me.editedItem.clienteBarrio = me.cliente.barrio;
            me.editedItem.clienteDireccion = me.cliente.direccion;
            me.editedItem.clienteTipoId = me.cliente.tipoId;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    BuscarClienteCedula() {
      this.snackValue = false;
      let me = this;
      if (me.editedItem.clienteCedula !== "") {
        me.showNewData = false;
        axios
          .get(
            "api/Clientes/getCliente?cia=" +
              this.strCia +
              "&cedula=" +
              this.editedItem.clienteCedula
          )
          .then(function (response) {
            me.cliente = response.data;
            me.showNewData = false;
            me.dialog = false;
            me.dialogArticulos = false;
            me.editedItem.clienteCod = me.cliente.codigo;
            me.editedItem.clienteNombre = me.cliente.nombre;
            me.editedItem.clientecorreo = me.cliente.correo;
            me.editedItem.clienteCedula = me.cliente.cedula;
            me.editedItem.clienteAreaTel = me.cliente.areaTel;
            me.editedItem.clienteTelefono = me.cliente.telefono;
            me.editedItem.clienteNombreComercial = me.cliente.nombreComercial;
            me.getProvincias();
            me.editedItem.clienteProvincia = me.cliente.provincia;
            me.getCantones();
            me.editedItem.clienteCanton = me.cliente.canton;
            me.getDistritos();
            me.editedItem.clienteDistrito = me.cliente.distrito;
            me.getBarrios();
            me.editedItem.clienteBarrio = me.cliente.barrio;
            me.editedItem.clienteDireccion = me.cliente.direccion;
            me.editedItem.clienteTipoId = me.cliente.tipoId;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    mostrarBuscarClientes() {
      this.snackValue = false;
      this.dialog = true;
      this.showNewData = true;
      this.cancelarValid = true;
    },

    mostrarBuscarArticulos() {
      this.snackValue = false;
      this.dialogArticulos = false;
      this.dialogBuscarArticulos = true;
      this.showNewDataArti = true;
      this.cancelarValid = true;
    },

    // getClienteHacienda() {
    //   let me = this;
    //   axios
    //     .get(
    //       "https://api.hacienda.go.cr/fe/ae?identificacion=" +
    //         me.editedItem.cedula
    //     )
    //     .then(function (response) {
    //       me.clienteHacienda = response.data;
    //       me.editedItem.nombre = me.clienteHacienda.nombre;
    //       me.editedItem.nombreComercial = me.clienteHacienda.nombre;
    //       me.editedItem.tipoId = me.clienteHacienda.tipoIdentificacion;

    //       if (me.clienteHacienda.situacion.estado === "Inscrito") {
    //         me.editedItem.esProd = true;
    //         me.editedItem.areaTel = "506";
    //       } else {
    //         me.editedItem.esProd = false;
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    changeMonedas() {
      if (this.editedItem.moneda === "USD") {
        this.getTipoCambio();
      }
      if (this.editedItem.moneda === "CRC") {
        this.editedItem.tipoCambio = 0;
      }
    },

    getTipoCambio() {
      let me = this;
      axios
        .get(
          "https://apis.gometa.org/tdc/tdc.json?fbclid=IwAR0ye7K1k---y1DMcYsWN7AMSpQAB_LPTcbQnSDY72FrnffmeR_zfDfYiO4",
          { crossdomain: true }
        )
        .then(function (response) {
          me.tipoCambio = response.data;
          me.editedItem.tipoCambio = me.tipoCambio.venta;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.snackValue = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.getModeloComprobante();
      this.editedItem.nombreTabla = "Tiquetes";
      this.getCia();
      this.getMonedas();
      this.getActividades();
      this.getCondicionVenta();
      this.getMedioPago();
      this.getSucursales();
      this.changeProvincia();
      this.changeCantones();
      this.changeDistritos();
      this.tab2 = 1;
      this.cancelarValid = false;

      if (
        this.editedItem.estadoHacienda === "ACEPTADO" ||
        this.editedItem.estadoHacienda === "RECHAZADO"
      ) {
        this.imprimirValid = true;
        this.reenviarValid = true;
        this.verPDF = true;
        this.verXMLFirmado = true;
        this.verXMLRespuesta = true;
      } else {
        this.imprimirValid = false;
        this.reenviarValid = false;
        this.verPDF = false;
        this.verXMLFirmado = false;
        this.verXMLRespuesta = false;
      }

      this.guardarValid = false;
      this.incluirValid = true;
      this.panel = [0];
      this.panelResumen = [0];
      if (this.editedItem.estadoHacienda === "PROCESANDO") {
        this.consultarEstadoValid = true;
      }
    },

    detalleEditItem(item) {
      if (this.guardarValid) {
        this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
        this.detalleEditedItem = Object.assign({}, item);
        this.dialogArticulos = true;
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.detalleEditedIndex = -1;
      });
    },

    deleteItemConfirm() {
      if (this.guardarValid) {
        this.lineasDetalle.splice(this.detalleEditedIndex, 1);
        this.closeDelete();

        let me = this;

        axios
          .post(
            "api/Facturacion/realizarCalculosComprobante",
            this.lineasDetalle
          )
          .then(function (response) {
            me.lineasDetalle = response.data.comprobanteDetalle;

            me.totales = response.data.comprobante;
            me.editedItem.totalIVADevuelto = 0; //validar esto
            me.editedItem.subTotal = me.totales.subtotal;
            me.editedItem.totalDescuentos = me.totales.totalDescuentos;
            me.editedItem.totalImpuesto = me.totales.totalImpuesto;
            me.editedItem.totalIva1 = me.totales.totalIva1;
            me.editedItem.totalIva2 = me.totales.totalIva2;
            me.editedItem.totalIva4 = me.totales.totalIva4;
            me.editedItem.totalIva13 = me.totales.totalIva13;
            me.editedItem.totalComprobante = me.totales.totalComprobante;

            me.editedItem.totalServGravados = me.totales.totalServGravados;
            me.editedItem.totalServExentos = me.totales.totalServExentos;
            me.editedItem.totalServExonerado = me.totales.totalServExonerado;
            me.editedItem.totalMercanciasGravadas =
              me.totales.totalMercanciasGravadas;
            me.editedItem.totalMercanciasExentas =
              me.totales.totalMercanciasExentas;
            me.editedItem.totalMercExonerada = me.totales.totalMercExonerada;
            me.editedItem.totalGravado = me.totales.totalGravado;
            me.editedItem.totalExento = me.totales.totalExento;
            me.editedItem.totalExonerado = me.totales.totalExonerado;
            me.editedItem.totalVenta = me.totales.totalVenta;
            me.editedItem.totalVentaNeta = me.totales.totalVentaNeta;
            me.editedItem.totalIVADevuelto = me.totales.totalIVADevuelto;
            me.editedItem.totalOtrosCargos = me.totales.totalOtrosCargos;
            me.editedItem.totalIva13 = me.totales.totalIva13;

            me.editedItem.totalIVADevueltoStr = me.totales.totalIVADevueltoStr; //validar esto
            me.editedItem.totalIva1Str = me.totales.totalIva1Str;
            me.editedItem.totalIva2Str = me.totales.totalIva2Str;
            me.editedItem.totalIva4Str = me.totales.totalIva4Str;
            me.editedItem.totalIva13Str = me.totales.totalIva13Str;
            me.editedItem.totalDescuentosStr = me.totales.totalDescuentosStr;
            me.editedItem.totalImpuestoStr = me.totales.totalImpuestoStr;
            me.editedItem.subtotalStr = me.totales.subtotalStr;
            me.editedItem.totalComprobanteStr = me.totales.totalComprobanteStr;
            //me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      }
    },

    consultarEstadoHacienda() {
      this.snackValue = false;
      this.visibleProgress = true;
      let me = this;

      this.comprobante = this.editedItem;

      axios
        .post("api/Facturacion/obtenerRespuestaHacienda", this.comprobante)
        .then(function (response) {
          me.respuestaHacienda = response.data;
          me.editedItem.estadoHacienda = me.respuestaHacienda.eInvoiceStatus;
          me.editedItem.mensajeHacienda = me.respuestaHacienda.details;
          me.visibleProgress = false;

          if (me.editedItem.estadoHacienda !== "PROCESANDO") {
            me.consultarEstadoValid = false;
          }

          if (
            me.editedItem.estadoHacienda === "ACEPTADO" ||
            me.editedItem.estadoHacienda === "RECHAZADO"
          ) {
            me.imprimirValid = true;
            me.reenviarValid = true;
            me.verPDF = true;
            me.verXMLFirmado = true;
            me.verXMLRespuesta = true;
          } else {
            me.imprimirValid = false;
            me.reenviarValid = false;
            me.verPDF = false;
            me.verXMLFirmado = false;
            me.verXMLRespuesta = false;
          }
          me.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    reenviarWhatsApp() {
      this.snackValue = false;
      let me = this;

      axios
        .get(
          "api/WhatsAppClient/sendInvoice?numeroReceptor=" +
            this.reenvioInfo.numero +
            "&cia=" +
            this.strCia +
            "&clave=" +
            this.editedItem.clavehacienda
        )
        .then(function (response) {
          me.snackText = response.data;
          me.snackValue = true;
        })
        .catch(function (error) {
          console.log(error);
        });

      this.dialogFormaReenvio = false;
      this.showNewData = false;
    },

    reenviarCorreo() {
      this.snackValue = false;
      let me = this;

      this.editedItem.clientecorreo = this.reenvioInfo.correo;
      this.comprobante = this.editedItem;

      axios
        .post("api/Facturacion/reenviarCorreo", this.comprobante)
        .then(function (response) {
          me.snackText = response.data;
          me.snackValue = true;
        })
        .catch(function (error) {
          console.log(error);
        });

      this.dialogFormaReenvio = false;
      this.showNewData = false;
    },

    getReport() {
      this.snackValue = false;
      axios
        .get(
          "api/Facturacion/getPdf?cia=" +
            this.strCia +
            "&clave=" +
            this.editedItem.clavehacienda
        )
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            window.open(response.data + ".pdf", "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getXMLFirmado() {
      this.snackValue = false;
      axios
        .get(
          "api/Facturacion/getPdf?cia=" +
            this.strCia +
            "&clave=" +
            this.editedItem.clavehacienda
        )
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            window.open(response.data + "_Signed.xml", "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getXMLRespuesta() {
      this.snackValue = false;
      axios
        .get(
          "api/Facturacion/getPdf?cia=" +
            this.strCia +
            "&clave=" +
            this.editedItem.clavehacienda
        )
        .then(function (response) {
          if (response.data) {
            console.log(response.data);
            window.open(response.data + "_Response.xml", "_blank");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getModeloComprobante() {
      this.snackValue = false;
      let me = this;

      axios
        .get(
          "api/Facturacion/getModeloComprobanteByCodigoFactura?cia=" +
            this.strCia +
            "&codigoFact=" +
            this.editedItem.codigoFact +
            "&docNombre=Tiquetes"
        )
        .then(function (response) {
          me.modelFactura = response.data;

          me.editedItem = me.modelFactura.comprobante;
          me.lineasDetalle = me.modelFactura.comprobanteDetalle;
          me.fecha = me.editedItem.fechaString;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getOpcionesSistemaCia() {
      let me = this;
      axios
        .get("api/General/getOpcionesSistemaCia?cia=" + this.strCia)
        .then(function (response) {
          me.opcionesSistemaCia = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    save() {
      this.snackValue = false;
      for (const opc of this.opcionesSistemaCia) {
        if (opc.opcionId === "pagos_sinpe") {
          if (opc.valor === "true") {
            this.dialogSinpe = true;
            this.showNewData = true;
          }
        }
      }
      let me = this;
      this.visibleProgress = true;
      this.editedItem.fecha = new Date();
      this.editedItem.fechaHacienda = new Date();
      this.editedItem.contingenciaFecha = new Date();
      this.editedItem.cia = this.strCia;
      this.editedItem.usuario = this.user.id;

      this.modelFactura.comprobante = this.editedItem;
      this.modelFactura.comprobanteDetalle = this.lineasDetalle;

      this.pagoInfo.total = this.editedItem.totalComprobante;

      if (this.editedIndex > -1) {
        axios
          .post("api/Facturacion/insertComprobante", this.modelFactura)
          .then(function (response) {
            me.visibleProgress = false;

            me.respuestaHacienda = response.data;

            this.editItem.clavehacienda = me.respuestaHacienda.claveHacienda;
            me.editItem.consHacReal = me.respuestaHacienda.consecutivoHacienda;
            me.editItem.estadoHacienda = me.respuestaHacienda.eInvoiceStatus;
            me.editItem.mensajeHacienda = me.respuestaHacienda.details;

            if (
              me.editedItem.estadoHacienda === "ACEPTADO" ||
              me.editedItem.estadoHacienda === "RECHAZADO"
            ) {
              me.imprimirValid = true;
              me.reenviarValid = true;
              me.verPDF = true;
              me.verXMLFirmado = true;
              me.verXMLRespuesta = true;
            } else {
              me.imprimirValid = false;
              me.reenviarValid = false;
              me.verPDF = false;
              me.verXMLFirmado = false;
              me.verXMLRespuesta = false;
            }

            if (me.editedItem.estadoHacienda === "PROCESANDO") {
              me.consultarEstadoValid = true;
            }
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.editedItem.tipoFact = "04";
        this.editedItem.nombreTabla = "Tiquetes";
        axios
          .post("api/Facturacion/insertComprobante", this.modelFactura)
          .then(function (response) {
            me.visibleProgress = false;
            me.respuestaHacienda = response.data;

            me.editedItem.clavehacienda = me.respuestaHacienda.claveHacienda;
            me.editedItem.consHacReal =
              me.respuestaHacienda.consecutivoHacienda;
            me.editedItem.estadoHacienda = me.respuestaHacienda.eInvoiceStatus;
            me.editedItem.mensajeHacienda = me.respuestaHacienda.details;

            if (
              me.editedItem.estadoHacienda === "ACEPTADO" ||
              me.editedItem.estadoHacienda === "RECHAZADO"
            ) {
              me.imprimirValid = true;
              me.reenviarValid = true;
              me.verPDF = true;
              me.verXMLFirmado = true;
              me.verXMLRespuesta = true;
            } else {
              me.imprimirValid = false;
              me.reenviarValid = false;
              me.verPDF = false;
              me.verXMLFirmado = false;
              me.verXMLRespuesta = false;
            }

            if (me.editedItem.estadoHacienda === "PROCESANDO") {
              me.consultarEstadoValid = true;
            }

            me.getData();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.cancelarValid = false;
      this.guardarValid = false;
      this.incluirValid = true;
      this.snackValue = false;
      this.btnAgregarArti = false;
      this.btnAgregarCliente = false;
    },

    saveDetalleArticulo() {
      if (this.detalleEditedIndex > -1) {
        Object.assign(
          this.lineasDetalle[this.detalleEditedIndex],
          this.detalleEditedItem
        );
      } else {
        this.lineasDetalle.push(this.detalleEditedItem);
      }
      this.close();

      let me = this;
      if (this.detalleEditedIndex > -1) {
        axios
          .post(
            "api/Facturacion/realizarCalculosComprobante",
            this.lineasDetalle
          )
          .then(function (response) {
            me.lineasDetalle = response.data.comprobanteDetalle;
            me.totales = response.data.comprobante;
            me.editedItem.totalIVADevuelto = 0; //validar esto

            me.editedItem.subTotal = me.totales.subtotal;
            me.editedItem.totalDescuentos = me.totales.totalDescuentos;
            me.editedItem.totalImpuesto = me.totales.totalImpuesto;
            me.editedItem.totalIva1 = me.totales.totalIva1;
            me.editedItem.totalIva2 = me.totales.totalIva2;
            me.editedItem.totalIva4 = me.totales.totalIva4;
            me.editedItem.totalIva13 = me.totales.totalIva13;
            me.editedItem.totalComprobante = me.totales.totalComprobante;

            me.editedItem.totalServGravados = me.totales.totalServGravados;
            me.editedItem.totalServExentos = me.totales.totalServExentos;
            me.editedItem.totalServExonerado = me.totales.totalServExonerado;
            me.editedItem.totalMercanciasGravadas =
              me.totales.totalMercanciasGravadas;
            me.editedItem.totalMercanciasExentas =
              me.totales.totalMercanciasExentas;
            me.editedItem.totalMercExonerada = me.totales.totalMercExonerada;
            me.editedItem.totalGravado = me.totales.totalGravado;
            me.editedItem.totalExento = me.totales.totalExento;
            me.editedItem.totalExonerado = me.totales.totalExonerado;
            me.editedItem.totalVenta = me.totales.totalVenta;
            me.editedItem.totalVentaNeta = me.totales.totalVentaNeta;
            me.editedItem.totalIVADevuelto = me.totales.totalIVADevuelto;
            me.editedItem.totalOtrosCargos = me.totales.totalOtrosCargos;
            me.editedItem.totalIva13 = me.totales.totalIva13;

            me.editedItem.totalIVADevueltoStr = me.totales.totalIVADevueltoStr; //validar esto
            me.editedItem.totalIva1Str = me.totales.totalIva1Str;
            me.editedItem.totalIva2Str = me.totales.totalIva2Str;
            me.editedItem.totalIva4Str = me.totales.totalIva4Str;
            me.editedItem.totalIva13Str = me.totales.totalIva13Str;
            me.editedItem.totalDescuentosStr = me.totales.totalDescuentosStr;
            me.editedItem.totalImpuestoStr = me.totales.totalImpuestoStr;
            me.editedItem.subtotalStr = me.totales.subtotalStr;
            me.editedItem.totalComprobanteStr = me.totales.totalComprobanteStr;
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .post(
            "api/Facturacion/realizarCalculosComprobante",
            this.lineasDetalle
          )
          .then(function (response) {
            me.lineasDetalle = response.data.comprobanteDetalle;

            me.totales = response.data.comprobante;
            me.editedItem.totalIVADevuelto = 0; //validar esto
            me.editedItem.subTotal = me.totales.subtotal;
            me.editedItem.totalDescuentos = me.totales.totalDescuentos;
            me.editedItem.totalImpuesto = me.totales.totalImpuesto;
            me.editedItem.totalIva1 = me.totales.totalIva1;
            me.editedItem.totalIva2 = me.totales.totalIva2;
            me.editedItem.totalIva4 = me.totales.totalIva4;
            me.editedItem.totalIva13 = me.totales.totalIva13;
            me.editedItem.totalComprobante = me.totales.totalComprobante;

            me.editedItem.totalServGravados = me.totales.totalServGravados;
            me.editedItem.totalServExentos = me.totales.totalServExentos;
            me.editedItem.totalServExonerado = me.totales.totalServExonerado;
            me.editedItem.totalMercanciasGravadas =
              me.totales.totalMercanciasGravadas;
            me.editedItem.totalMercanciasExentas =
              me.totales.totalMercanciasExentas;
            me.editedItem.totalMercExonerada = me.totales.totalMercExonerada;
            me.editedItem.totalGravado = me.totales.totalGravado;
            me.editedItem.totalExento = me.totales.totalExento;
            me.editedItem.totalExonerado = me.totales.totalExonerado;
            me.editedItem.totalVenta = me.totales.totalVenta;
            me.editedItem.totalVentaNeta = me.totales.totalVentaNeta;
            me.editedItem.totalIVADevuelto = me.totales.totalIVADevuelto;
            me.editedItem.totalOtrosCargos = me.totales.totalOtrosCargos;
            me.editedItem.totalIva13 = me.totales.totalIva13;

            me.editedItem.totalIVADevueltoStr = me.totales.totalIVADevueltoStr; //validar esto
            me.editedItem.totalIva1Str = me.totales.totalIva1Str;
            me.editedItem.totalIva2Str = me.totales.totalIva2Str;
            me.editedItem.totalIva4Str = me.totales.totalIva4Str;
            me.editedItem.totalIva13Str = me.totales.totalIva13Str;
            me.editedItem.totalDescuentosStr = me.totales.totalDescuentosStr;
            me.editedItem.totalImpuestoStr = me.totales.totalImpuestoStr;
            me.editedItem.subtotalStr = me.totales.subtotalStr;
            me.editedItem.totalComprobanteStr = me.totales.totalComprobanteStr;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.cancelarValid = true;
      this.imprimirValid = false;
      this.reenviarValid = false;
      this.verPDF = false;
      this.verXMLFirmado = false;
      this.verXMLRespuesta = false;
      this.guardarValid = true;
      this.incluirValid = false;
      this.snackValue = false;
      this.panelResumen = [0];
      this.consultarEstadoValid = false;
    },

    close() {
      this.snackValue = false;
      this.dialogArticulos = false;
      this.$nextTick(() => {
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.detalleEditedIndex = -1;
      });
    },

    deleteItem(item) {
      if (this.guardarValid) {
        this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
        this.detalleEditedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },

    closed() {
      this.snackValue = false;
      this.showNewData = false;
      this.showNewDataArti = false;
      this.dialog = false;
      this.dialogArticulos = false;
      this.dialogSinpe = false;
      this.dialogBuscarArticulos = false;
      this.dialogFormaReenvio = false;
    },

    selected(value) {
      this.cliente = Object.assign({}, value);
      this.showNewData = false;
      this.dialog = false;
      this.dialogArticulos = false;
      this.editedItem.clienteCod = this.cliente.codigo;
      this.editedItem.clienteNombre = this.cliente.nombre;
      this.editedItem.clientecorreo = this.cliente.correo;
      this.editedItem.clienteCedula = this.cliente.cedula;
      this.editedItem.clienteAreaTel = this.cliente.areaTel;
      this.editedItem.clienteTelefono = this.cliente.telefono;
      this.editedItem.clienteNombreComercial = this.cliente.nombreComercial;
      this.getProvincias();
      this.editedItem.clienteProvincia = this.cliente.provincia;
      this.getCantones();
      this.editedItem.clienteCanton = this.cliente.canton;
      this.getDistritos();
      this.editedItem.clienteDistrito = this.cliente.distrito;
      this.getBarrios();
      this.editedItem.clienteBarrio = this.cliente.barrio;
      this.editedItem.clienteDireccion = this.cliente.direccion;
      this.editedItem.clienteTipoId = this.cliente.tipoId;
      this.btnAgregarArti = true;
    },

    reenviar() {
      this.reenvioInfo.correo = this.editedItem.clientecorreo;
      this.reenvioInfo.numero = this.editedItem.clienteTelefono;
      this.dialogFormaReenvio = true;
      this.showNewData = true;
    },

    selectedReenvio(value) {
      this.reenvioInfo = Object.assign({}, value);
      this.reenvioInfo.numero = "506" + this.reenvioInfo.numero;

      if (this.reenvioInfo.porwhatsapp) {
        this.reenviarWhatsApp();
      }

      if (this.reenvioInfo.porcorreo) {
        this.reenviarCorreo();
      }
    },

    selectedArticulos(value) {
      this.articulo = Object.assign({}, value);
      this.showNewDataArti = false;
      this.dialogArticulos = true;
      this.detalleEditedItem.cia = this.strCia;
      this.detalleEditedItem.factura = this.editedItem.codigoFact;
      this.detalleEditedItem.tipoFact = "04";
      this.detalleEditedItem.numLinea = 0;
      this.detalleEditedItem.unidad = this.articulo.unidad;
      this.detalleEditedItem.descuento = 0;
      this.detalleEditedItem.totalLinea = 0;
      this.detalleEditedItem.sucursal = this.editedItem.sucursal;
      this.detalleEditedItem.terminal = this.editedItem.terminal;
      this.detalleEditedItem.tipoCodigo = "01";
      this.detalleEditedItem.unidadMedidaComercial = "M2";
      this.detalleEditedItem.impCodigo = this.articulo.codigoImp;
      this.detalleEditedItem.impCodigoTarifa = this.articulo.codigoTarifa;
      this.detalleEditedItem.impTarifa = this.articulo.tarifa;
      this.detalleEditedItem.porcDescuento = 0;
      this.detalleEditedItem.monto = this.articulo.monto;
      this.detalleEditedItem.descripcion = this.articulo.nombre;
      this.detalleEditedItem.cantidad = 1;
      this.detalleEditedItem.cabys = this.articulo.cabys;
      this.detalleEditedItem.articulo = this.articulo.codigo;
      this.detalleEditedItem.tipoCambio = this.editedItem.tipoCambio;
      this.detalleEditedItem.impuestosVentas = this.articulo.tarifa;
      this.detalleEditedItem.tipoProducto = this.articulo.tipoArt;
      this.detalleEditedItem.moneda = this.editedItem.moneda;
      this.detalleEditedItem.clienteCod = this.editedItem.clienteCod;
      this.detalleEditedItem.clienteCedula = this.editedItem.clienteCedula;
    },

    limpiar() {
      this.snackValue = false;
    },

    cerrar() {
      this.snackValue = false;
      this.dialog = false;
      this.dialogArticulos = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.imprimirValid = false;
        this.reenviarValid = false;
        this.verPDF = false;
        this.verXMLFirmado = false;
        this.verXMLRespuesta = false;
        this.guardarValid = false;
        this.incluirValid = true;
        this.consultarEstadoValid = false;
        this.panelResumen = [];
        this.panel = [];
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.lineasDetalle = [];
        this.btnAgregarArti = false;
        this.btnAgregarCliente = false;
      });
    },

    onImageChange(ev) {
      this.tempFile = ev;
      this.editedItem.certificado = ev.name;
    },

    getCodigoTiquete() {
      let me = this;
      axios
        .get(
          "api/Facturacion/getComprobanteConsInterno?cia=" +
            this.strCia +
            "&docNombre=Tiquetes&tipoDoc=T"
        )
        .then(function (response) {
          me.editedItem.codigoFact = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getFechaActual() {
      let me = this;
      axios
        .get("api/Facturacion/getFechaActualString")
        .then(function (response) {
          me.fecha = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    validarLicencia() {
      let me = this;
      axios
        .get("api/General/licenciaValidar?cia=" + this.strCia)
        .then(function (response) {
          me.licenciaValida = response.data;
          if (me.licenciaValida.valido) {
            me.incluir();
          } else {
            me.snackText = me.licenciaValida.motivo;
            me.snackValue = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    incluir() {
      this.snackValue = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getCodigoTiquete();
        this.cancelarValid = true;
        this.imprimirValid = false;
        this.reenviarValid = false;
        this.verPDF = false;
        this.verXMLFirmado = false;
        this.verXMLRespuesta = false;
        this.guardarValid = true;
        this.incluirValid = false;
        this.consultarEstadoValid = false;
        this.getCia();
        this.getMonedas();
        this.getActividades();
        this.getCondicionVenta();
        this.getMedioPago();
        this.getSucursales();
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.lineasDetalle = [];
        this.editedItem.estadoHacienda = "PENDIENTE";
        this.panel = [0];
        this.btnAgregarCliente = true;
        this.getFechaActual();
        this.editedItem.clienteCod = "CL-00001";
        this.BuscarClienteCodigo();
        this.btnAgregarArti = true;
      });
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

.right-input input {
  text-align: right;
}
</style>