<template>
  <div class="renderPage">
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-card class="mx-auto" max-width="360" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title
                class="text-h6 mb-4"
                v-text="dashboardInfo.plan"
              >
              </v-list-item-title>
              <v-list-item-subtitle>
                <p v-text="dashboardInfo.fechaInicioStr"></p>
                <p v-text="dashboardInfo.fechaVencimientoStr"></p>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar>
              <v-btn color="primary" fab dark>
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-card class="mx-auto" max-width="360" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 mb-4">
                Documentos
              </v-list-item-title>
              <v-list-item-subtitle>
                <p v-text="dashboardInfo.consumidosDocumentos"></p>
                <p v-text="dashboardInfo.disponibleDocumentos"></p>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar>
              <v-btn color="primary" fab dark>
                <v-icon>mdi-file-document-box</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-card class="mx-auto" max-width="360" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 mb-4">
                Detalles
              </v-list-item-title>
              <v-list-item-subtitle>
                <p v-text="dashboardInfo.soporte"></p>
                <p v-text="dashboardInfo.usuarios"></p>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar>
              <v-btn color="primary" fab dark>
                <v-icon>mdi-library-plus</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </v-col>

      <!-- <v-col cols="12" sm="12" md="12">
        <v-card class="mx-auto text-center" color="primary" dark>
          <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
              <v-sparkline
                :labels="labels"
                :value="value"
                color="rgba(255, 255, 255, .7)"
                height="80"
                padding="20"
                stroke-linecap="round"
                smooth
                auto-draw
                :auto-draw-duration="4000"
              >
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="text-h4 font-weight-thin">Facturas Emitidas 2022</div>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-col> -->
      <v-col cols="12" sm="12" md="12">
        <v-footer v-bind="localAttrs" :padless="padless">
          <v-card flat tile width="100%" class="primary text-center">
            <v-card-text class="pa-2">
              <v-btn
                v-for="icon in icons"
                :key="icon"
                class="mx-4"
                icon
                @click="selectedIcon(icon)"
              >
                <v-icon size="24px">
                  {{ icon }}
                </v-icon>
              </v-btn>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
              {{ new Date().getFullYear() }} — <strong>Eduva Solutions</strong>
            </v-card-text>
          </v-card>
        </v-footer>
      </v-col>
    </v-row>
    <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { Bar } from "vue-chartjs/legacy";
import Snackbar from "./shared/Snackbar.vue";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar, Snackbar },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 350,
    },
    height: {
      type: Number,
      default: 350,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      icons: ["mdi-facebook", "mdi-whatsapp", "mdi-youtube"],
      items: [],
      snackText: "",
      snackValue: false,
      dashboardInfo: [],
      chartData: {},
      // dashboardInfo: {
      //   plan: "Plan Anual profesional",
      //   fechaInicio: "Último pago: 10-06-2022",
      //   fechaVencimiento: "Vencimiento: 10-06-2023",
      //   consumidosDocumentos: "Realizados: 5",
      //   disponibleDocumentos: "Disponibles: 55",
      //   soporte: "Soporte incluido",
      //   usuarios: "1 usuario",
      // },
      // chartData: {
      //   labels: [
      //     "Enero",
      //     "Febrero",
      //     "Marzo",
      //   ],
      //   datasets: [
      //     {
      //       label: "Facturas 2022",
      //       backgroundColor: "#029151",
      //       data: [40, 20, 12],
      //     },
      //   ],
      // },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav);
  //   this.getFacturasInfo();
  // },

  watch: {},

  created() {
    this.getData();
    this.getFacturasInfo();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("/api/General/getDashboardPlanInfo?cia=" + this.strCia)
        .then(function (response) {
          me.dashboardInfo = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getFacturasInfo() {
      let me = this;
      axios
        .get("/api/General/getDashboardFacturasInfo?cia=" + this.strCia)
        .then(function (response) {
          me.chartData = response.data;

          // me.snackText = me.chartData;
          // me.snackValue = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    selectedIcon(icon) {
      if (icon === "mdi-facebook") {
        window.open(
          "https://www.facebook.com/Eduva-Solutions-109625341739954",
          "_blank"
        );
      }
      if (icon === "mdi-whatsapp") {
        window.open(
          "https://api.whatsapp.com/send/?phone=50670863468",
          "_blank"
        );
      }
      if (icon === "mdi-youtube") {
        window.open(
          "https://www.youtube.com/channel/UCRJVKCvSgBxIgaZhFZtCvmQ",
          "_blank"
        );
      }
    },
  },
};
</script>

<style>
.img {
  width: 100vw !important;
  height: 95vh !important;
}
</style>