<template dark>
  <div class="renderPage">
    <v-tabs
      background-color="primary"
      icons-and-text
      fixed-tabs
      center-active
      v-model="tab2"
    >
      <v-tabs-slider color="black"></v-tabs-slider>
      <v-tab key="cia_registrados" @click="limpiar()">
        Compañias Registradas
        <v-icon>mdi-content-save</v-icon>
      </v-tab>
      <v-tab key="cia_nuevos" @click="limpiar()">
        Compañia
        <v-icon>mdi-pencil-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="cia_registrados">
        <v-data-table :headers="headers" :items="items" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field
                class="text-xs-center"
                v-model="search"
                append-icon="mdi-magnify"
                label="Búsqueda"
                single-line
                hide-details
                dense
              >
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
          </template>
        </v-data-table>
        <!-- <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" /> -->
      </v-tab-item>
      <v-tab-item key="cia_nuevos">
        <v-form v-model="valid">
          <div>
            <v-expansion-panels multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>General</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container>
                    <v-row>
                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Tipo Identificación"
                          item-text="descripcion"
                          item-value="tipoId"
                          v-model="editedItem.tipoId"
                          :items="tiposIdentificacion"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.cedula"
                          label="Cedula"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col align="center" cols="12" sm="1" md="1">
                        <v-btn
                          color="primary"
                          fab
                          small
                          dark
                          @click="getClienteHacienda"
                          :disabled="incluirValid"
                        >
                          <v-icon>mdi-file-find</v-icon>
                        </v-btn>
                      </v-col>

                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="editedItem.esProd"
                          label="Es Producción"
                          class="pa-0"
                          :disabled="incluirValid"
                        >
                        </v-checkbox>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Actividad Economica"
                          item-text="descripcion"
                          item-value="codigo"
                          v-model="editedItem.codigoActividad"
                          :items="actividades"
                          class="pa-0"
                          outlined
                          dense
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="3" md="6">
                        <v-text-field
                          v-model="editedItem.nombre"
                          label="Nombre"
                          :rules="nameRules"
                          required
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="6">
                        <v-text-field
                          v-model="editedItem.nombreComercial"
                          label="Nombre Comercial"
                          :rules="nameRules"
                          required
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>
                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Moneda"
                          item-text="md_nombre"
                          item-value="md_codigo"
                          v-model="editedItem.moneda"
                          :items="monedas"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>
                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Segunda Moneda"
                          item-text="md_nombre"
                          item-value="md_codigo"
                          v-model="editedItem.moneda2"
                          :items="monedas"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.sucursal"
                          label="Sucursal/Casa Matriz"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.terminal"
                          label="Terminal/Punto Venta"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="editedItem.ivaIncluido"
                          label="IVA Incluido"
                          class="pa-0"
                          :disabled="incluirValid"
                        >
                        </v-checkbox>
                      </v-col>

                      <v-col cols="12" md="3" sm="3">
                        <v-file-input
                          label="Logo"
                          accept="image/jpg,image/jpeg"
                          v-model="modelFileImage"
                          @change="onChangeCiaImage"
                          required
                          :rules="requiredRules"
                          class="pa-0"
                          :disabled="incluirValid"
                        />
                      </v-col>

                      <v-col cols="12" sm="3" md="6">
                        <v-text-field
                          v-model="editedItem.imagePath"
                          label="Nombre Logo"
                          readonly
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  >Ubicación Geografica</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-container>
                    <v-row>
                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Provincia"
                          item-text="pr_nombre"
                          item-value="pr_codigo"
                          v-model="editedItem.provincia"
                          :items="provincias"
                          @change="changeProvincia"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Canton"
                          item-text="ct_nombre"
                          item-value="ct_codigo"
                          v-model="editedItem.canton"
                          :items="cantones"
                          @change="changeCantones"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Distrito"
                          item-text="dt_nombre"
                          item-value="dt_codigo"
                          v-model="editedItem.distrito"
                          :items="distritos"
                          @change="changeDistritos"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col col="12" md="3" sm="3">
                        <v-select
                          label="Barrio"
                          item-text="br_nombre"
                          item-value="br_codigo"
                          v-model="editedItem.barrio"
                          :items="barrios"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.areaTel"
                          label="Area Teléfono"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.telefono"
                          label="# Teléfono"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="2" md="6">
                        <v-textarea
                          v-model="editedItem.direccion"
                          label="Dirección"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="15"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  >Conexión Hacienda</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.usuariohacienda"
                          label="Usuario Hacienda"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.clavehacienda"
                          label="Clave Hacienda"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col cols="12" sm="3" md="3">
                        <v-file-input
                          v-model="editedItem.certificado"
                          multiple
                          label="Certificado"
                        ></v-file-input>
                      </v-col> -->

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.claveCert"
                          label="Clave Certificado"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          label="Generar espacio"
                          v-if="visible"
                          class="pa-0"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="3" sm="3">
                        <v-file-input
                          label="Certificado"
                          accept=".p12"
                          v-model="modelFile"
                          @change="onImageChange"
                          required
                          :rules="requiredRules"
                          class="pa-0"
                          :disabled="incluirValid"
                        />
                      </v-col>

                      <v-col cols="12" sm="3" md="6">
                        <v-text-field
                          v-model="editedItem.certificado"
                          label="Nombre Certificado"
                          readonly
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  >Configuración Correo</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="3" md="6">
                        <v-text-field
                          v-model="editedItem.correo"
                          label="Correo electrónico "
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.claveCcorreo"
                          label="Clave Correo"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.puertoCorreo"
                          label="Puerto Correo"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="editedItem.smtpCorreo"
                          label="SMTP Correo"
                          class="pa-0"
                          :disabled="incluirValid"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="editedItem.usaSeguridad"
                          label="Usa SSL"
                          class="pa-0"
                          :disabled="incluirValid"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>

          <v-row align="center" justify="space-around">
            <v-btn color="primary" :disabled="!incluirValid" @click="incluir()">
              Incluir
            </v-btn>
            <v-btn color="primary" :disabled="!guardarValid" @click="save()">
              Guardar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!eliminarValid"
              @click="deleteItem()"
            >
              Eliminar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!cancelarValid"
              @click="cerrar()"
              dark
            >
              Cancelar
            </v-btn>
          </v-row>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
//import Cabys from "./Cabys.vue";

export default {
  components: {
    Snackbar,
    //Cabys,
  },
  dark: true,
  data() {
    return {
      dialog: false,
      valid: false,
      incluirValid: true,
      cancelarValid: false,
      eliminarValid: false,
      guardarValid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab2: "",
      headers: [
        {
          text: "Cédula",
          align: "start",
          value: "cedula",
        },
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        {
          text: "Nombre Comercial",
          align: "start",
          value: "nombreComercial",
        },
        {
          text: "Sucursal",
          align: "start",
          value: "sucursal",
        },
        {
          text: "Terminal",
          align: "start",
          value: "terminal",
        },
        { text: "Editar", value: "actions", width: 50, sortable: false },
      ],
      items: [],
      tiposIdentificacion: [],
      monedas: [],
      provincias: [],
      cantones: [],
      distritos: [],
      barrios: [],
      actividades: [],
      modeloActividades: {
        cia: "",
        actividades: [],
      },
      editedIndex: -1,
      editedItem: {
        nombre: "",
        nombreComercial: "",
        cedula: "",
        provincia: "",
        canton: "",
        distrito: "",
        barrio: "",
        direccion: "",
        sucursal: "",
        terminal: "",
        moneda: "",
        moneda2: "",
        usuariohacienda: "",
        clavehacienda: "",
        propietarioCert: "",
        certificado: "",
        claveCert: "",
        esProd: false,
        correo: "",
        claveCcorreo: "",
        smtpCorreo: "",
        puertoCorreo: "",
        usaSeguridad: false,
        areaTel: "",
        telefono: "",
        tipoId: "",
        codigoActividad: "",
        image: "",
        imagePath: "",
        ciaImage: "",
        ivaIncluido: false,
      },
      defaultItem: {
        nombre: "",
        nombreComercial: "",
        cedula: "",
        provincia: "",
        canton: "",
        distrito: "",
        barrio: "",
        direccion: "",
        sucursal: "",
        terminal: "",
        moneda: "",
        moneda2: "",
        usuariohacienda: "",
        clavehacienda: "",
        propietarioCert: "",
        certificado: "",
        claveCert: "",
        esProd: false,
        correo: "",
        claveCcorreo: "",
        smtpCorreo: "",
        puertoCorreo: "",
        usaSeguridad: false,
        areaTel: "",
        telefono: "",
        tipoId: "",
        codigoActividad: "",
        image: "",
        imagePath: "",
        ciaImage: "",
        ivaIncluido: false,
      },
      clienteHacienda: {
        nombre: "",
        tipoIdentificacion: "",
        situacion: {
          estado: "",
        },
      },
      showNewData: false,
      nameRules: [(v) => !!v || "Campo requerido"],
      tempFile: "",
      tempFileImage: "",
      modelFile: null,
      modelFileImage: null,
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.getData();
    this.getTiposIdentificacion();
    this.getMonedas();
    this.getProvincias();
    //this.getActividades();
  },

  methods: {
    getData() {
      let me = this;
      axios
        .get("api/Cias/getCias", {
          crossdomain: true,
        })
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    limpiar() {
      this.snackValue = false;
    },

    getActividades() {
      let me = this;
      axios
        .get("api/General/getActividadesByCia?cia=" + this.editedItem.cedula)
        .then(function (response) {
          me.actividades = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // getActividadesHacienda() {
    //   let me = this;
    //   axios
    //     .get(
    //       "https://api.hacienda.go.cr/fe/ae?identificacion=" +
    //         me.editedItem.cedula
    //     )
    //     .then(function (response) {
    //         me.actividades = response.data;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    getTiposIdentificacion() {
      let me = this;
      axios
        .get("api/General/getTiposIdentificacion")
        .then(function (response) {
          me.tiposIdentificacion = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getBarrios() {
      let me = this;
      axios
        .get(
          "api/General/getBarrios?provincia=" +
            this.editedItem.provincia +
            "&canton=" +
            this.editedItem.canton +
            "&distrito=" +
            this.editedItem.distrito
        )
        .then(function (response) {
          me.barrios = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeDistritos() {
      this.getBarrios();
    },

    getDistritos() {
      let me = this;
      axios
        .get(
          "api/General/getDistritos?provincia=" +
            this.editedItem.provincia +
            "&canton=" +
            this.editedItem.canton
        )
        .then(function (response) {
          me.distritos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeCantones() {
      this.getDistritos();
    },

    getCantones() {
      let me = this;
      axios
        .get("api/General/getCantones?provincia=" + this.editedItem.provincia)
        .then(function (response) {
          me.cantones = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    changeProvincia() {
      this.getCantones();
    },

    getProvincias() {
      let me = this;
      axios
        .get("api/General/getProvincias")
        .then(function (response) {
          me.provincias = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getMonedas() {
      let me = this;
      axios
        .get("api/General/getMonedas")
        .then(function (response) {
          me.monedas = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getClienteHacienda() {
      let me = this;
      axios
        .get(
          "https://api.hacienda.go.cr/fe/ae?identificacion=" +
            me.editedItem.cedula
        )
        .then(function (response) {
          me.clienteHacienda = response.data;
          me.editedItem.nombre = me.clienteHacienda.nombre;
          me.editedItem.nombreComercial = me.clienteHacienda.nombre;
          me.editedItem.tipoId = me.clienteHacienda.tipoIdentificacion;

          if (me.clienteHacienda.situacion.estado === "Inscrito") {
            me.editedItem.esProd = true;
            me.editedItem.areaTel = "506";
          } else {
            me.editedItem.esProd = false;
          }

          me.actividades = response.data.actividades;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      //this.changeEsReceptor();
      this.changeProvincia();
      this.changeCantones();
      this.changeDistritos();
      this.getActividades();
      this.tab2 = 1;
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
    },

    save() {
      let me = this;
      this.editedItem.cia = this.strCia;
      let formData = new FormData();

      if (this.editedIndex > -1) {
        if (this.tempFile != "") {
          formData.append("images", this.tempFile);
        }
        if (this.tempFileImage != "") {
          formData.append("images", this.tempFileImage);
        }
        formData.append("ciaInfo", JSON.stringify(this.editedItem));

        axios
          .post("/api/Cias/updateCias", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            me.snackText = "Registro modificado satisfactoriamente";
            me.snackValue = true;
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      } else {
        if (this.tempFile != "") {
          formData.append("images", this.tempFile);
        }
        if (this.tempFileImage != "") {
          formData.append("images", this.tempFileImage);
        }
        formData.append("ciaInfo", JSON.stringify(this.editedItem));
        axios
          //.post("api/Cias/insertCias", this.editedItem)
          .post("/api/Cias/insertCias", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            me.snackText = "Registro agregado satisfactoriamente";
            me.snackValue = true;
            me.saveActividades();
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
      }
      this.cancelarValid = true;
      this.eliminarValid = true;
      this.guardarValid = true;
      this.incluirValid = false;
      this.snackValue = false;
      //this.changeEsReceptor();
    },

    saveActividades() {
      this.modeloActividades.cia = this.editedItem.cedula;
      this.modeloActividades.actividades = this.actividades;

      axios
        .post("api/General/insertActividadesByCia", this.modeloActividades)
        .then(function () {})
        .catch(function (error) {
          console.log(error);
        });
    },

    // validarCampos(){
    //     let me = this;

    //     if(this.editedItem.tipoArt === "") {
    //         return false;
    //     }
    //     else
    //     {
    //         return TRUE;
    //     }

    // },

    deleteItem() {
      let me = this;
      if (confirm("¿Está seguro de que desea eliminar el articulo?")) {
        axios
          .delete("api/cias/deleteClientes?cia=" + this.editedItem.cedula)
          .then(function () {
            me.getData();
          })
          .catch(function (error) {
            console.log(error);
            // me.saved(false);
          });
        this.cerrar();
        this.tab2 = 0;
        this.esRec = false;
      }
    },

    closed() {
      this.showNewData = false;
      this.dialog = false;
    },

    cerrar() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.eliminarValid = false;
        this.guardarValid = false;
        this.incluirValid = true;
      });
    },

    onImageChange(ev) {
      this.tempFile = ev;
      this.editedItem.certificado = ev.name;
    },

    onChangeCiaImage(ev) {
      this.tempFileImage = ev;
      this.editedItem.imagePath = ev.name;
    },

    incluir() {
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.cancelarValid = true;
        this.eliminarValid = false;
        this.guardarValid = true;
        this.incluirValid = false;
        this.esRec = false;
      });
    },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}
</style>