<template>
  <v-app>
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";

Vue.use(VueExcelXlsx);
export default {
  name: "App",

  components: {},
  data() {
    return {};
  },
};
</script>

<style>
</style>
