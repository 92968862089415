<template>
  <div>
    <qrcode-vue :value="textToQR" :size="options.size" :level="options.level" />
  </div>
</template>

<script>
/**
 * About pm-qrcode
 *-------------------
 * @author malayvuong
 * PM-QRCODE mean Phien Malay QR Code
 * Phien (https://github.com/phamanhphien)
 * Malay = Malayvuong (https://github.com/malayvuong)
 *
 **/
import QrcodeVue from "qrcode.vue";

export default {
  name: "VrCode",
  components: {
    QrcodeVue,
  },
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    options: {
      type: Object,
      default: function () {
        return {
          size: 100,
          background: "#ffffff",
          foreground: "#000000",
          className: "",
          level: "L",
          padding: 10,
        };
      },
    },
    type: {
      type: String,
      default: "canvas",
    },
    // Helpers options
    helpers: { type: String, default: "sms" },
  },
  data() {
    return {
      textToQR: "",
      numberSMS: "7070-1222",
      messageSMS: "Pase 10 83040942 Probando Sinpe",
    };
  },
  //   watch: {
  //     value(val) {
  //       this.setTextToQr();
  //     },
  //   },
  created() {
    this.setTextToQr();
  },
  methods: {
    /**
     * Set value by helpers type
     *-------------------
     * @author malayvuong
     *
     **/
    setTextToQr() {
      switch (this.helpers) {
        case "call":
          this.textToQR = `tel:${this.numberSMS || 0}`;
          break;
        case "sms":
          this.textToQR = `SMSTO:${this.numberSMS || 0}${
            this.messageSMS ? ":" + this.messageSMS : ""
          }`;
          break;
        default:
          this.textToQR = this.value.toString();
          break;
      }
    },
  },
};
</script>