<template>
  <div class="cardContainer container" color="blue">
    <v-card elevation="5">
      <v-toolbar color="primary" dark flat>
        <v-spacer />
        <v-toolbar-title class="text-capitalize">
          Iniciar Sesión
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-form ref="form" v-model="valid">
        <!-- <v-text-field
          type="text"
          placeholder="Ingrese su usuario"
          label="Usuario"
          filled
          rounded
          dense
          prepend-icon="mdi-account-circle"
          v-model="user.cedula"
          :rules="requiredRules"
          required
        >
        </v-text-field>

        <v-text-field 
          type="password"
          placeholder="Ingrese su contraseña"
          label="Contraseña"
          prepend-icon="mdi-lock"
          filled
          rounded
          dense
          v-model="user.clave"
          :rules="requiredRules"
          required
        >
        </v-text-field> -->

        <v-text-field
          label="Usuario"
          placeholder="Ingrese su usuario"
          solo-inverted
          prepend-icon="mdi-account-circle"
          v-model="user.cedula"
          :rules="requiredRules"
          required
        ></v-text-field>

        <v-text-field
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show3 ? 'text' : 'password'"
          label="Contraseña"
          placeholder="Ingrese su contraseña"
          class="input-group--focused"
          solo-inverted
          prepend-icon="mdi-lock"
          @click:append="show3 = !show3"
          v-model="user.clave"
          :rules="requiredRules"
          required
        ></v-text-field>

        <div class="btnContainer">
          <v-btn
            color="primary"
            class="text-capitalize"
            :disabled="!valid"
            @click="log()"
            >Ingresar</v-btn
          >
        </div>
      </v-form>
    </v-card>
    <Snackbar v-bind:text="snackText" v-bind:snackbar="snackValue" />
  </div>
</template>

<script>
import axios from "axios";
import Snackbar from "../components/shared/Snackbar.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    Snackbar,
  },
  data() {
    return {
      show3: false,
      valid: false,
      snackValue: false,
      snackText: "",
      user: {
        cedula: "",
        clave: "",
        nombre: "",
      },
      requiredRules: [(v) => !!v || "Campo requerido"],
    };
  },
  methods: {
    ...mapMutations(["setUser"]),
    log() {
      let me = this;
      axios
        .get(
          "/api/Usuarios/isValidUser?cedula=" +
            me.user.cedula +
            "&clave=" +
            me.user.clave
        )
        .then(function (response) {
          if (response.data) {
            me.setUser(response.data);
            me.$router.replace({ name: "Dashboard" });
          } else {
            me.snackText = "Credenciales Incorrectas";
            me.snackValue = true;
          }
        })
        .catch(function (error) {
          me.snackText = "Error al Validar Credenciales";
          me.snackValue = true;
          console.log(error);
        });
    },
  },
};
</script>

<style>
.cardContainer {
  position: relative;
  margin: auto;
  display: flex;
}

.cardContainer .v-card {
  position: relative;
  margin: auto;
}

.cardContainer .v-card .v-form {
  height: 100%;
  padding: 60px;
  display: flex;
  flex-direction: column;
}

.btnContainer {
  display: flex;
  flex-direction: row-reverse;
}
</style>