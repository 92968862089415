<template>
  <div class="renderPage">
    <v-tabs background-color="primary" icons-and-text fixed-tabs center-active v-model="tab2">
      <v-tabs-slider></v-tabs-slider>
      <v-tab key="compras_registrados">
        Compras Registradas
        <v-icon>mdi-content-save</v-icon>
      </v-tab>
      <v-tab key="compras_nuevos">
        Compras
        <v-icon>mdi-pencil-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab2" class="ti">
      <v-tab-item key="compras_registrados">
        <v-data-table v-model="selected" :headers="headers" :items="itemsFilter" item-key="codigoFact" show-select
          class="elevation-1" :search="search">
          <template v-slot:top>
            <v-container>
              <v-row>
                <v-col cols="12" sm="1" md="1">
                  <v-text-field label="Espacio separacion" class="pa-0" v-if="visible" outlined dense></v-text-field>
                </v-col>
                <v-col col="12" md="6" sm="6">
                  <v-select label="Actividad Económica" item-text="descripcion" item-value="codigo"
                    v-model="codigoActividadAll" :items="actividades" class="pa-0" outlined dense></v-select>
                </v-col>
                <v-col col="12" md="3" sm="3">
                  <v-select label="Estado Respuesta" item-text="descripcion" item-value="codigo" v-model="estadoDevAll"
                    :items="estadosCompras" class="pa-0" outlined dense></v-select>
                </v-col>
                <v-col align="center" cols="12" sm="1" md="1">
                  <v-btn color="primary" @click="enviarSeleccionados">
                    Enviar
                  </v-btn>
                </v-col>

                <v-col cols="12" sm="1" md="1">
                  <v-progress-circular :size="50" :width="10" color="primary" indeterminate
                    v-if="visibleLoteProgress"></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field class="text-xs-center" v-model="search" append-icon="mdi-magnify" label="Búsqueda"
                single-line hide-details dense>
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.codigoFact">
                <td>
                  <v-checkbox v-model="selected" :value="item" style="margin: 0px; padding: 0px" hide-details :disabled="item.codigoDev == 'ACEPTADO' ||
                    item.codigoDev == 'RECHAZADO'
                    " />
                </td>
                <td>{{ item.codigoFact }}</td>
                <td>{{ item.clienteNombre }}</td>
                <td>{{ item.fechaHaciendaString }}</td>
                <td>{{ item.clavehacienda }}</td>
                <td>{{ getTipoDocumento(item.clavehacienda) }}</td>
                <td>{{ item.codigoDev }}</td>
                <td>{{ item.totalComprobanteStr }}</td>
                <td>
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <!-- <v-data-table :headers="headers" :items="items" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field
                class="text-xs-center"
                v-model="search"
                append-icon="mdi-magnify"
                label="Búsqueda"
                single-line
                hide-details
                dense
              >
              </v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="getData()"> Refrescar </v-btn>
          </template>
        </v-data-table> -->
        <!-- <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" /> -->
      </v-tab-item>
      <v-tab-item key="compras_nuevos">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="12">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field v-model="editedItem.cia" label="Receptor" class="pa-0" readonly outlined
                        dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="editedItem.clienteNombreComercial" label="Nombre" class="pa-0" readonly
                        outlined dense></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="1" md="1">
                      <v-text-field label="Moneda" v-model="editedItem.moneda" class="pa-0" outlined dense readonly>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-text-field label="Tipo C." v-model="editedItem.tipoCambio" class="pa-0" outlined dense
                        readonly>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field label="Plazo Crédito" v-model="editedItem.plazoCredito" class="pa-0" outlined dense
                        readonly>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field v-model="editedItem.cedulaReceptor" label="Proveedor" class="pa-0" readonly outlined
                        dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="editedItem.clienteNombre" label="Nombre" class="pa-0" readonly outlined
                        dense></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" md="4">
                      <v-text-field label="Estado Documento Emisor" v-model="editedItem.estadoHaciendaEmisor"
                        class="pa-0" outlined dense readonly>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="editedItem.clavehacienda" label="Clave Emisor" class="pa-0" readonly
                        outlined dense></v-text-field>
                    </v-col>

                    <v-col col="12" md="6" sm="6">
                      <v-select label="Actividad Económica" item-text="descripcion" item-value="codigo"
                        v-model="editedItem.codigoActividad" :items="actividades" class="pa-0" outlined
                        dense></v-select>
                    </v-col>

                    <!-- <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        label="Estado Doc Emisor"
                        v-model="editedItem.estadoHaciendaEmisor"
                        class="pa-0"
                        outlined
                        dense
                        readonly
                      >
                      </v-text-field>
                    </v-col> -->

                    <v-col col="12" md="3" sm="3">
                      <v-select label="Estado de Aceptación" item-text="descripcion" item-value="codigo"
                        v-model="editedItem.estadoDev" :items="estadosCompras" class="pa-0" outlined dense></v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field type="text" v-model="fechaHaciendaString" label="Fecha Hacienda" class="pa-0"
                        readonly outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field type="text" v-model="fechaString" label="Fecha Recibido" class="pa-0" readonly
                        outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-tabs background-color="primary" icons-and-text fixed-tabs center-active v-model="tabFactura"
                height="40">
                <v-tabs-slider color="white"></v-tabs-slider>
                <v-tab key="detalle"> Lineas de detalle </v-tab>
                <v-tab key="hacienda"> Información adicional </v-tab>
              </v-tabs>

              <v-col cols="12" sm="12" md="12">
                <v-card>
                  <v-tabs-items v-model="tabFactura">
                    <v-tab-item key="detalle">
                      <v-data-table hide-default-footer :headers="headersDetalle" :items="lineasDetalle"
                        :search="search" :items-per-page="-1">
                      </v-data-table>

                      <v-container>
                        <v-row>
                          <v-expansion-panels multiple v-model="panelResumen">
                            <v-expansion-panel>
                              <v-expansion-panel-header>Resumen Compra</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field v-model="editedItem.estadoHacienda" label="Estado Hacienda"
                                        class="pa-0" readonly outlined dense></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="1" md="1">
                                      <v-tooltip bottom>
                                        <template #activator="{ on }">
                                          <v-btn color="primary" @click="consultarEstadoHacienda" class="pa-0" v-on="on"
                                            :disabled="!consultarEstadoValid">
                                            <v-icon>mdi-cloud-sync</v-icon>
                                          </v-btn>
                                        </template>
                                        <span>Consultar estado hacienda</span>
                                      </v-tooltip>
                                    </v-col>

                                    <v-col cols="12" sm="1" md="1">
                                      <v-progress-circular :size="50" :width="10" color="primary" indeterminate
                                        v-if="visibleProgress"></v-progress-circular>
                                    </v-col>

                                    <v-col cols="12" sm="1" md="1">
                                      <v-text-field label="Espacio separacion" class="pa-0" v-if="visible" outlined
                                        dense></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field v-model="editedItem.totalIVADevueltoStr" label="Iva Devuelto"
                                        step="any" min="0" readonly class="right-input pa-0" outlined
                                        dense></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field v-model="editedItem.subtotalStr" label="Sub Total" step="any"
                                        min="0" readonly class="right-input pa-0" outlined dense></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4" md="4">
                                      <v-text-field v-model="editedItem.consHacReal" label="Consecutivo Hacienda"
                                        class="pa-0" readonly outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                      <v-text-field label="Espacio separacion" class="pa-0" v-if="visible" outlined
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field v-model="editedItem.totalDescuentosStr" label="Total Descuentos"
                                        step="any" min="0" readonly class="right-input pa-0" outlined
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field v-model="editedItem.totalImpuestoStr" label="Total Impuestos"
                                        step="any" min="0" readonly class="right-input pa-0" outlined
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                      <v-text-field v-model="editedItem.totalIva1Str" label="Iva 1%" step="any" min="0"
                                        readonly class="right-input pa-0" outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                      <v-text-field v-model="editedItem.totalIva2Str" label="Iva 2%" step="any" min="0"
                                        readonly class="right-input pa-0" outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                      <v-text-field v-model="editedItem.totalIva4Str" label="Iva 4%" step="any" min="0"
                                        readonly class="right-input pa-0" outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                      <v-text-field v-model="editedItem.totalIva13Str" label="Iva 13%" step="any"
                                        min="0" readonly class="right-input pa-0" outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="1" md="1">
                                      <v-text-field label="Espacio separacion" class="pa-0" v-if="visible" outlined
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field v-model="editedItem.totalComprobanteStr" label="Total Comprobante"
                                        step="any" min="0.00" readonly class="right-input pa-0" outlined
                                        dense></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-row>
                      </v-container>
                    </v-tab-item>

                    <v-tab-item key="hacienda">
                      <v-container>
                        <v-row>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="9" md="9">
                                <v-textarea v-model="editedItem.observacion" label="Detalle Mensaje" outlined rows="1"
                                  row-height="15" class="pa-0"></v-textarea>
                              </v-col>

                              <v-col cols="12" sm="1" md="1">
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-btn color="primary" @click="getXMLRecibido" class="pa-0" v-on="on"
                                      :disabled="!verXMLRespuesta">
                                      <v-icon>mdi-file-import</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Ver XML Recibido</span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="12" sm="1" md="1">
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-btn color="primary" @click="getXMLFirmado" class="pa-0" v-on="on"
                                      :disabled="!verXMLFirmado">
                                      <v-icon>mdi-file-export</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Ver XML Enviado</span>
                                </v-tooltip>
                              </v-col>

                              <v-col cols="12" sm="1" md="1">
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <v-btn color="primary" @click="getXMLRespuesta" class="pa-0" v-on="on"
                                      :disabled="!verXMLRespuesta">
                                      <v-icon>mdi-file-import</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Ver XML Respuesta</span>
                                </v-tooltip>
                              </v-col>

                              <v-col cols="12" sm="9" md="9">
                                <v-textarea v-model="editedItem.mensajeHacienda" label="Mensaje Hacienda" outlined
                                  class="pa-0" readonly></v-textarea>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <div>
            <v-expansion-panels multiple v-model="panel"> </v-expansion-panels>
          </div>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>

          <v-row align="center" justify="space-around">
            <!-- <v-btn color="primary" :disabled="!incluirValid" @click="incluir()">
              Incluir
            </v-btn> -->
            <v-btn color="primary" :disabled="!guardarValid" @click="save()">
              Guardar
            </v-btn>
            <v-btn color="primary" :disabled="!cancelarValid" @click="cerrar()">
              Cancelar
            </v-btn>
            <!-- 
            <v-btn
              color="primary"
              :disabled="!reenviarValid"
              @click="reenviarCorreo()"
            >
              Reenviar
            </v-btn> -->
          </v-row>

          <v-container>
            <v-row align="center" justify="space-around"> </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
      <Snackbar v-bind:text="snackText" v-bind:snackbar.sync="snackValue" />
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Snackbar from "./shared/Snackbar.vue";
//import BuscarDocumentos from "./BuscarDocumentos.vue";
//import BuscarArticulos from "./BuscarArticulos.vue";

export default {
  components: {
    Snackbar,
    //BuscarDocumentos,
    //BuscarArticulos,
  },
  // dark: true,
  data() {
    return {
      btnAgregarDocumento: false,
      btnAgregarArti: false,
      iconVisible: false,
      iconVisibleCedula: false,
      visibleBtnConsultar: true,
      visibleProgress: false,
      visibleLoteProgress: false,
      dialog: false,
      valid: false,
      incluirValid: true,
      consultarEstadoValid: false,
      cancelarValid: false,
      //enviarValid: false,
      guardarValid: false,
      search: "",
      snackText: "",
      snackValue: false,
      tab2: 0,
      tabFactura: 0,
      dialogDelete: false,
      verXMLFirmado: false,
      verXMLRespuesta: false,
      panel: [],
      panelResumen: [],
      codigoActividadAll: "",
      estadoDevAll: 0,
      selected: [],
      headers: [
        {
          text: "Código",
          align: "start",
          value: "codigoFact",
        },
        {
          text: "Proveedor",
          align: "start",
          value: "clienteNombre",
        },
        {
          text: "Fecha Hacienda",
          align: "start",
          value: "fechaHaciendaString",
        },
        {
          text: "Clave Emisor",
          align: "start",
          value: "clavehacienda",
        },
        {
          text: "Tipo Doc",
          align: "start",
          //value: "clavehacienda",
        },
        {
          text: "Estado",
          align: "start",
          value: "codigoDev",
        },
        {
          text: "Total",
          align: "start",
          value: "totalComprobanteStr",
        },
        {
          text: "Ver",
          align: "start",
          value: "actions",
          width: 50,
          sortable: false,
        },
      ],
      headersDetalle: [
        {
          text: "Cant",
          align: "start",
          value: "cantidad",
        },
        {
          text: "Cabys",
          align: "start",
          value: "cabys",
        },
        {
          text: "Descripción",
          align: "start",
          value: "descripcion",
        },
        {
          text: "Unidad",
          align: "start",
          value: "unidad",
        },
        {
          text: "Precio",
          align: "start",
          value: "montoStr",
        },
        {
          text: "Total",
          align: "start",
          value: "totalLineaStr",
        },
        // { text: "Editar", value: "actions", width: 50, sortable: false },
        // { text: "Eliminar", value: "delete", width: 70, sortable: false },
      ],
      estadosCompras: [
        { codigo: 1, descripcion: "ACEPTADO" },
        { codigo: 2, descripcion: "ACEPTADO PARCIAL" },
        { codigo: 3, descripcion: "RECHAZADO" },
      ],
      items: [],
      tiposIdentificacion: [],
      actividades: [],
      condicionesVenta: [],
      mediosPago: [],
      sucursales: [],
      terminales: [],
      monedas: [],
      cliente: [],
      articulo: [],
      totales: [],
      lineasDetalle: [],
      tipoCambio: [],
      respuestaHacienda: [],
      comprobante: [],
      editedIndex: -1,
      modelFactura: {
        comprobante: [],
        comprobanteDetalle: [],
      },
      editedItem: {
        cia: "",
        codigoFact: "",
        tipoFact: "",
        fecha: new Date().toISOString().slice(0, 10),
        fechaString: "",
        tipoCambio: 1,
        clienteCod: "",
        clienteNombre: "",
        clienteCorreo: "",
        clienteCedula: "",
        clienteAreaTel: "",
        clienteTelefono: "",
        clienteNombreComercial: "",
        clienteProvincia: "",
        clienteCanton: "",
        clienteDistrito: "",
        clienteBarrio: "",
        clienteDireccion: "",
        clienteTipoId: "",
        mensajeHacienda: "",
        moneda: "",
        estadoHacienda: "",
        clavehacienda: "",
        subtotal: 0,
        totalDescuentos: 0,
        totalImpuesto: 0,
        totalComprobante: 0,
        codigoDev: "",
        estadoDev: 0,
        consHacienda: "",
        condicionVenta: "",
        medioPago: "",
        plazoCredito: 0,
        sucursal: "",
        terminal: "",
        xml: "",
        xmlFirmado: "",
        respuestaHacienda: "",
        fechaHacienda: Date,
        fechaHaciendaString: "",
        totalGravado: 0,
        totalExento: 0,
        consHacReal: "",
        cedulaReceptor: "",
        ordenCompra: "",
        observacion: "",
        usuario: "",
        enviado: false,
        reenviado: false,
        totalServGravados: 0,
        totalServExentos: 0,
        totalServExonerado: 0,
        totalMercanciasExentas: 0,
        totalMercanciasGravadas: 0,
        totalMercExonerada: 0,
        totalExonerado: 0,
        totalVenta: 0,
        totalVentaNeta: 0,
        totalIVADevuelto: 0,
        totalOtrosCargos: 0,
        totalIva1: 0,
        totalIva2: 0,
        totalIva4: 0,
        totalIva8: 0,
        totalIva13: 0,
        codigoActividad: "",
        emisorNombre: "",
        emisorNombreComercial: "",
        emisorTelefono: "",
        emisorCorreo: "",
        emisorDireccion: "",
        subTotal: 0,

        totalIVADevueltoStr: "",
        totalIva1Str: "",
        totalIva2Str: "",
        totalIva4Str: "",
        totalIva13Str: "",
        totalDescuentosStr: "",
        totalImpuestoStr: "",
        subtotalStr: "",
        totalComprobanteStr: "",
        nombreTabla: "",
        documentoOrigen: "",
        nombreTablaReferencia: "",
        estadoHaciendaEmisor: "",
      },
      defaultItem: {
        cia: "",
        codigoFact: "",
        tipoFact: "",
        fecha: Date,
        fechaString: "",
        tipoCambio: 1,
        clienteCod: "",
        clienteNombre: "",
        clienteCorreo: "",
        clienteCedula: "",
        clienteAreaTel: "",
        clienteTelefono: "",
        clienteNombreComercial: "",
        clienteProvincia: "",
        clienteCanton: "",
        clienteDistrito: "",
        clienteBarrio: "",
        clienteDireccion: "",
        clienteTipoId: "",
        mensajeHacienda: "",
        moneda: "",
        estadoHacienda: "",
        clavehacienda: "",
        subtotal: 0,
        totalDescuentos: 0,
        totalImpuesto: 0,
        totalComprobante: 0,
        codigoDev: "",
        estadoDev: 0,
        consHacienda: "",
        condicionVenta: "",
        medioPago: "",
        plazoCredito: 0,
        sucursal: "",
        terminal: "",
        xml: "",
        xmlFirmado: "",
        respuestaHacienda: "",
        fechaHacienda: Date,
        fechaHaciendaString: "",
        totalGravado: 0,
        totalExento: 0,
        consHacReal: "",
        cedulaReceptor: "",
        ordenCompra: "",
        observacion: "",
        usuario: "",
        enviado: false,
        reenviado: false,
        totalServGravados: 0,
        totalServExentos: 0,
        totalServExonerado: 0,
        totalMercanciasExentas: 0,
        totalMercanciasGravadas: 0,
        totalMercExonerada: 0,
        totalExonerado: 0,
        totalVenta: 0,
        totalVentaNeta: 0,
        totalIVADevuelto: 0,
        totalOtrosCargos: 0,
        totalIva1: 0,
        totalIva2: 0,
        totalIva4: 0,
        totalIva8: 0,
        totalIva13: 0,
        codigoActividad: "",
        emisorNombre: "",
        emisorNombreComercial: "",
        emisorTelefono: "",
        emisorCorreo: "",
        emisorDireccion: "",
        subTotal: 0,

        totalIVADevueltoStr: "",
        totalIva1Str: "",
        totalIva2Str: "",
        totalIva4Str: "",
        totalIva13Str: "",
        totalDescuentosStr: "",
        totalImpuestoStr: "",
        subtotalStr: "",
        totalComprobanteStr: "",
        nombreTabla: "",
        documentoOrigen: "",
        nombreTablaReferencia: "",
        estadoHaciendaEmisor: "",
      },
      ciaItem: {
        nombre: "",
        nombreComercial: "",
        cedula: "",
        provincia: "",
        canton: "",
        distrito: "",
        barrio: "",
        direccion: "",
        sucursal: "",
        terminal: "",
        moneda: "",
        moneda2: "",
        usuariohacienda: "",
        clavehacienda: "",
        propietarioCert: "",
        certificado: "",
        claveCert: "",
        esProd: false,
        correo: "",
        claveCcorreo: "",
        smtpCorreo: "",
        puertoCorreo: "",
        usaSeguridad: false,
        areaTel: "",
        telefono: "",
        tipoId: "",
        codigoActividad: "",
        image: "",
      },
      detalleditedIndex: -1,
      detalleEditedItem: {
        cia: "",
        factura: "",
        tipoFact: "",
        numLinea: 0,
        cantidad: 0,
        articulo: "",
        descripcion: "",
        unidad: "",
        monto: 0,
        descuento: 0,
        totalLinea: 0,
        impuestosVentas: 0,
        sucursal: "",
        terminal: "",
        tipoCodigo: "",
        unidadMedidaComercial: "",
        impCodigo: "",
        impCodigoTarifa: "",
        impTarifa: "",
        cabys: "",
        porcDescuento: 0,
        tipoCambio: 0,
        tipoProducto: "",
        montoTotal: 0,
        subTotal: 0,
        impuestoNeto: 0,

        moneda: "",
        montoStr: "",
        totalLineaStr: "",
      },
      detalleDefaultItem: {
        cia: "",
        factura: "",
        tipoFact: "",
        numLinea: 0,
        cantidad: 0,
        articulo: "",
        descripcion: "",
        unidad: "",
        monto: 0,
        descuento: 0,
        totalLinea: 0,
        impuestosVentas: 0,
        sucursal: "",
        terminal: "",
        tipoCodigo: "",
        unidadMedidaComercial: "",
        impCodigo: "",
        impCodigoTarifa: "",
        impTarifa: "",
        cabys: "",
        porcDescuento: 0,
        tipoCambio: 0,
        tipoProducto: "",
        montoTotal: 0,
        subTotal: 0,
        impuestoNeto: 0,

        moneda: "",
        montoStr: "",
        totalLineaStr: "",
      },
      showNewData: false,
      showNewDataArti: false,
      nameRules: [(v) => !!v || "Campo requerido"],
      tempFile: "",
      modelFile: null,
    };
  },

  computed: {
    ...mapGetters(["user", "strCia"]),
    formTitle() {
      return this.editedIndex === -1 ? "Agregar Articulo" : "Editar Articulo";
    },
    itemsFilter() {
      return this.items.map((x) => ({
        ...x,
        isSelectable: x.codigoDev == "PENDIENTE",
      }));
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogArticulos(val) {
      val || this.close();
    },

    search() {
      this.snackValue = false;
    },
  },

  created() {
    this.getData();
    //this.getMonedas();
    //this.getProvincias();
    this.getActividades();
    this.getCondicionVenta();
    this.getMedioPago();
  },

  methods: {
    signalChange: function (evt) {
      this.snackText = "Si llega al escribir" + evt;
      this.snackValue = true;
    },

    // changeText() {
    //   this.iconVisible = true;
    // },

    // activarIconoCedula() {
    //   this.iconVisibleCedula = true;
    // },

    enviarSeleccionados() {
      this.visibleLoteProgress = true;
      let me = this;

      if (this.codigoActividadAll != "" && this.estadoDevAll != 0) {
        if (this.selected.length > 0) {
          for (const item of this.selected) {
            item.fechaHacienda = new Date();
            item.cia = this.strCia;
            item.usuario = this.user.id;
            item.tipoFact = "05";
            item.nombreTabla = "Compras";
            item.codigoActividad = this.codigoActividadAll;
            item.estadoDev = this.estadoDevAll;
          }

          this.snackText = "Procesando por favor espere";
          this.snackValue = true;

          axios
            .post("api/Compras/enviarLoteCompras", this.selected)
            .then(function (response) {
              //me.visibleProgress = false;
              me.visibleLoteProgress = false;
              me.respuestaHacienda = response.data;

              me.getData();
              me.snackValue = false;
            })
            .catch(function (error) {
              console.log(error);
            });

          me.cancelarValid = false;
          me.guardarValid = false;
          me.incluirValid = true;
          //me.snackValue = false;
        } else {
          this.snackText = "Debe seleccionar al menos un registro";
          this.snackValue = true;
        }
      } else {
        this.snackText =
          "Por favor valide los datos de Actividad Comercial y Estado Respuesta";
        this.snackValue = true;
      }
    },

    getTipoDocumento(clave) {
      let tipoDocumento = "";

      if (clave.substring(29, 31) === "01") {
        tipoDocumento = "Factura";
      }
      if (clave.substring(29, 31) === "02") {
        tipoDocumento = "Nota Débito";
      }
      if (clave.substring(29, 31) === "03") {
        tipoDocumento = "Nota Crédito";
      }
      if (clave.substring(29, 31) === "04") {
        tipoDocumento = "Tiquete";
      }
      return tipoDocumento;
    },

    getData() {
      let me = this;
      axios
        .get(
          "api/Compras/GetCompras?cia=" +
          this.strCia +
          "&docNombre=Compras&tipoDoc=05",
          {
            crossdomain: true,
          }
        )
        .then(function (response) {
          me.items = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getXMLRecibido() {
      this.snackValue = false;
      window.open(
        "EduvaFE\\" +
        this.editedItem.cia +
        "\\XML_RECEPCION\\" +
        this.editedItem.clavehacienda +
        ".xml",
        "_blank"
      );
    },

    getXMLFirmado() {
      this.snackValue = false;
      window.open(
        "EduvaFE\\" +
        this.editedItem.cia +
        "\\MensajeReceptor\\" +
        this.editedItem.consHacReal +
        "\\" +
        this.editedItem.consHacReal +
        "_Signed.xml",
        "_blank"
      );
    },

    getXMLRespuesta() {
      this.snackValue = false;
      window.open(
        "EduvaFE\\" +
        this.editedItem.cia +
        "\\MensajeReceptor\\" +
        this.editedItem.consHacReal +
        "\\" +
        this.editedItem.consHacReal +
        "_Response.xml",
        "_blank"
      );
    },

    printDate: function () {
      return new Date().tolocal;
    },

    getActividades() {
      let me = this;
      axios
        .get("api/General/getActividadesByCia?cia=" + this.strCia)
        .then(function (response) {
          me.actividades = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // getCondicionVenta() {
    //   let me = this;
    //   axios
    //     .get("api/General/getCondicionVenta")
    //     .then(function (response) {
    //       me.condicionesVenta = response.data;
    //       me.editedItem.condicionVenta = me.condicionesVenta[0]["codigo"];
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    // getMedioPago() {
    //   let me = this;
    //   axios
    //     .get("api/General/getMediosPago")
    //     .then(function (response) {
    //       me.mediosPago = response.data;
    //       me.editedItem.medioPago = me.mediosPago[0]["codigo"];
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    // getCia() {
    //   let me = this;
    //   axios
    //     .get("api/Cias/getCia?ciaId=" + this.strCia)
    //     .then(function (response) {
    //       me.ciaItem = response.data;
    //       me.editedItem.emisorNombre = me.ciaItem.nombre;
    //       me.editedItem.emisorNombreComercial = me.ciaItem.nombreComercial;
    //       me.editedItem.codigoActividad = me.ciaItem.codigoActividad;
    //       me.editedItem.emisorTelefono = me.ciaItem.telefono;
    //       me.editedItem.emisorCorreo = me.ciaItem.correo;
    //       me.editedItem.emisorDireccion = me.ciaItem.direccion;
    //       me.editedItem.moneda = me.ciaItem.moneda;
    //       me.editedItem.cia = me.ciaItem.cedula;
    //       me.editedItem.fecha = me.printDate();

    //       me.getSucursales();
    //       me.getTerminales(me.ciaItem.sucursal);
    //       me.editedItem.sucursal = me.ciaItem.sucursal;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    getSucursales() {
      let me = this;
      axios
        .get("api/Cias/getSucursalesByCia?cia=" + this.strCia)
        .then(function (response) {
          me.sucursales = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTerminales(sucursal) {
      let me = this;
      axios
        .get(
          "api/Cias/getTerminales?cia=" + this.strCia + "&sucursal=" + sucursal
        )
        .then(function (response) {
          me.terminales = response.data;
          me.editedItem.terminal = me.terminales[0]["terminalId"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // getBarrios() {
    //   let me = this;
    //   axios
    //     .get(
    //       "api/General/getBarrios?provincia=" +
    //         this.editedItem.clienteProvincia +
    //         "&canton=" +
    //         this.editedItem.clienteCanton +
    //         "&distrito=" +
    //         this.editedItem.clienteDistrito
    //     )
    //     .then(function (response) {
    //       me.barrios = response.data;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    // changeDistritos() {
    //   this.getBarrios();
    // },

    // getDistritos() {
    //   let me = this;
    //   axios
    //     .get(
    //       "api/General/getDistritos?provincia=" +
    //         this.editedItem.clienteProvincia +
    //         "&canton=" +
    //         this.editedItem.clienteCanton
    //     )
    //     .then(function (response) {
    //       me.distritos = response.data;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    // changeCantones() {
    //   this.getDistritos();
    // },

    // getCantones() {
    //   let me = this;
    //   axios
    //     .get(
    //       "api/General/getCantones?provincia=" +
    //         this.editedItem.clienteProvincia
    //     )
    //     .then(function (response) {
    //       me.cantones = response.data;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    // changeProvincia() {
    //   this.getCantones();
    // },

    // changeSucursal() {
    //   this.getTerminales();
    // },

    // getProvincias() {
    //   let me = this;
    //   axios
    //     .get("api/General/getProvincias")
    //     .then(function (response) {
    //       me.provincias = response.data;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    // getMonedas() {
    //   let me = this;
    //   axios
    //     .get("api/General/getMonedas")
    //     .then(function (response) {
    //       me.monedas = response.data;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    // BuscarClienteCodigo() {
    //   let me = this;
    //   if (me.editedItem.clienteCod !== "") {
    //     me.showNewData = false;
    //     axios
    //       .get(
    //         "api/Clientes/getCliente?cia=" +
    //           this.strCia +
    //           "&codigo=" +
    //           this.editedItem.clienteCod
    //       )
    //       .then(function (response) {
    //         me.cliente = response.data;
    //         me.showNewData = false;
    //         me.dialog = false;
    //         me.dialogArticulos = false;
    //         me.editedItem.clienteCod = me.cliente.codigo;
    //         me.editedItem.clienteNombre = me.cliente.nombre;
    //         me.editedItem.clientecorreo = me.cliente.correo;
    //         me.editedItem.clienteCedula = me.cliente.cedula;
    //         me.editedItem.clienteAreaTel = me.cliente.areaTel;
    //         me.editedItem.clienteTelefono = me.cliente.telefono;
    //         me.editedItem.clienteNombreComercial = me.cliente.nombreComercial;
    //         me.getProvincias();
    //         me.editedItem.clienteProvincia = me.cliente.provincia;
    //         me.getCantones();
    //         me.editedItem.clienteCanton = me.cliente.canton;
    //         me.getDistritos();
    //         me.editedItem.clienteDistrito = me.cliente.distrito;
    //         me.getBarrios();
    //         me.editedItem.clienteBarrio = me.cliente.barrio;
    //         me.editedItem.clienteDireccion = me.cliente.direccion;
    //         me.editedItem.clienteTipoId = me.cliente.tipoId;
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   }
    // },

    // BuscarClienteCedula() {
    //   let me = this;
    //   if (me.editedItem.clienteCedula !== "") {
    //     me.showNewData = false;
    //     axios
    //       .get(
    //         "api/Clientes/getCliente?cia=" +
    //           this.strCia +
    //           "&cedula=" +
    //           this.editedItem.clienteCedula
    //       )
    //       .then(function (response) {
    //         me.cliente = response.data;
    //         me.showNewData = false;
    //         me.dialog = false;
    //         me.dialogArticulos = false;
    //         me.editedItem.clienteCod = me.cliente.codigo;
    //         me.editedItem.clienteNombre = me.cliente.nombre;
    //         me.editedItem.clientecorreo = me.cliente.correo;
    //         me.editedItem.clienteCedula = me.cliente.cedula;
    //         me.editedItem.clienteAreaTel = me.cliente.areaTel;
    //         me.editedItem.clienteTelefono = me.cliente.telefono;
    //         me.editedItem.clienteNombreComercial = me.cliente.nombreComercial;
    //         me.getProvincias();
    //         me.editedItem.clienteProvincia = me.cliente.provincia;
    //         me.getCantones();
    //         me.editedItem.clienteCanton = me.cliente.canton;
    //         me.getDistritos();
    //         me.editedItem.clienteDistrito = me.cliente.distrito;
    //         me.getBarrios();
    //         me.editedItem.clienteBarrio = me.cliente.barrio;
    //         me.editedItem.clienteDireccion = me.cliente.direccion;
    //         me.editedItem.clienteTipoId = me.cliente.tipoId;
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   }
    // },

    // mostrarBuscarDocumentos() {
    //   this.dialog = true;
    //   this.showNewData = true;
    //   this.cancelarValid = true;
    // },

    // mostrarBuscarArticulos() {
    //   this.dialogArticulos = false;
    //   this.dialogBuscarArticulos = true;
    //   this.showNewDataArti = true;
    //   this.cancelarValid = true;
    // },

    // getClienteHacienda() {
    //   let me = this;
    //   axios
    //     .get(
    //       "https://api.hacienda.go.cr/fe/ae?identificacion=" +
    //         me.editedItem.cedula
    //     )
    //     .then(function (response) {
    //       me.clienteHacienda = response.data;
    //       me.editedItem.nombre = me.clienteHacienda.nombre;
    //       me.editedItem.nombreComercial = me.clienteHacienda.nombre;
    //       me.editedItem.tipoId = me.clienteHacienda.tipoIdentificacion;

    //       if (me.clienteHacienda.situacion.estado === "Inscrito") {
    //         me.editedItem.esProd = true;
    //         me.editedItem.areaTel = "506";
    //       } else {
    //         me.editedItem.esProd = false;
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    // changeMonedas() {
    //   if (this.editedItem.moneda === "USD") {
    //     this.getTipoCambio();
    //   }
    //   if (this.editedItem.moneda === "CRC") {
    //     this.editedItem.tipoCambio = 0;
    //   }
    // },

    // getTipoCambio() {
    //   let me = this;
    //   axios
    //     .get(
    //       "https://apis.gometa.org/tdc/tdc.json?fbclid=IwAR0ye7K1k---y1DMcYsWN7AMSpQAB_LPTcbQnSDY72FrnffmeR_zfDfYiO4",
    //       { crossdomain: true }
    //     )
    //     .then(function (response) {
    //       me.tipoCambio = response.data;
    //       me.editedItem.tipoCambio = me.tipoCambio.venta;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    editItem(item) {
      this.editedItem.observacion = " ";
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      // this.snackText = "el estado que tiene es: " + this.items.indexOf(item);
      // this.snackValue = true;

      this.getModeloComprobante();
      this.editedItem.nombreTabla = "Compras";
      this.getActividades();
      this.tab2 = 1;

      if (
        this.editedItem.estadoHacienda === "ACEPTADO" ||
        this.editedItem.estadoHacienda === "RECHAZADO"
      ) {
        this.verXMLFirmado = true;
        this.verXMLRespuesta = true;
      } else {
        this.verXMLFirmado = false;
        this.verXMLRespuesta = false;
      }

      this.guardarValid = false;
      this.cancelarValid = true;
      this.panel = [0];
      this.panelResumen = [0];
      if (this.editedItem.estadoHacienda === "PROCESANDO") {
        this.consultarEstadoValid = true;
      }

      if (this.editedItem.estadoHacienda === "PENDIENTE") {
        this.guardarValid = true;
      }
    },

    detalleEditItem(item) {
      if (this.guardarValid) {
        this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
        this.detalleEditedItem = Object.assign({}, item);
        this.dialogArticulos = true;
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.detalleEditedIndex = -1;
      });
    },

    // deleteItemConfirm() {
    //   if (this.guardarValid) {
    //     this.lineasDetalle.splice(this.detalleEditedIndex, 1);
    //     this.closeDelete();

    //     let me = this;

    //     axios
    //       .post(
    //         "api/Facturacion/realizarCalculosComprobante",
    //         this.lineasDetalle
    //       )
    //       .then(function (response) {
    //         me.lineasDetalle = response.data.comprobanteDetalle;

    //         me.totales = response.data.comprobante;
    //         me.editedItem.totalIVADevuelto = 0; //validar esto
    //         me.editedItem.subTotal = me.totales.subtotal;
    //         me.editedItem.totalDescuentos = me.totales.totalDescuentos;
    //         me.editedItem.totalImpuesto = me.totales.totalImpuesto;
    //         me.editedItem.totalIva1 = me.totales.totalIva1;
    //         me.editedItem.totalIva2 = me.totales.totalIva2;
    //         me.editedItem.totalIva4 = me.totales.totalIva4;
    //         me.editedItem.totalIva13 = me.totales.totalIva13;
    //         me.editedItem.totalComprobante = me.totales.totalComprobante;

    //         me.editedItem.totalServGravados = me.totales.totalServGravados;
    //         me.editedItem.totalServExentos = me.totales.totalServExentos;
    //         me.editedItem.totalServExonerado = me.totales.totalServExonerado;
    //         me.editedItem.totalMercanciasGravadas =
    //           me.totales.totalMercanciasGravadas;
    //         me.editedItem.totalMercanciasExentas =
    //           me.totales.totalMercanciasExentas;
    //         me.editedItem.totalMercExonerada = me.totales.totalMercExonerada;
    //         me.editedItem.totalGravado = me.totales.totalGravado;
    //         me.editedItem.totalExento = me.totales.totalExento;
    //         me.editedItem.totalExonerado = me.totales.totalExonerado;
    //         me.editedItem.totalVenta = me.totales.totalVenta;
    //         me.editedItem.totalVentaNeta = me.totales.totalVentaNeta;
    //         me.editedItem.totalIVADevuelto = me.totales.totalIVADevuelto;
    //         me.editedItem.totalOtrosCargos = me.totales.totalOtrosCargos;
    //         me.editedItem.totalIva13 = me.totales.totalIva13;

    //         me.editedItem.totalIVADevueltoStr = me.totales.totalIVADevueltoStr; //validar esto
    //         me.editedItem.totalIva1Str = me.totales.totalIva1Str;
    //         me.editedItem.totalIva2Str = me.totales.totalIva2Str;
    //         me.editedItem.totalIva4Str = me.totales.totalIva4Str;
    //         me.editedItem.totalIva13Str = me.totales.totalIva13Str;
    //         me.editedItem.totalDescuentosStr = me.totales.totalDescuentosStr;
    //         me.editedItem.totalImpuestoStr = me.totales.totalImpuestoStr;
    //         me.editedItem.subtotalStr = me.totales.subtotalStr;
    //         me.editedItem.totalComprobanteStr = me.totales.totalComprobanteStr;
    //         //me.getData();
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //         // me.saved(false);
    //       });
    //   }
    // },

    consultarEstadoHacienda() {
      let me = this;

      this.comprobante = this.editedItem;

      axios
        .post("api/Compras/obtenerRespuestaHacienda", this.comprobante)
        .then(function (response) {
          me.respuestaHacienda = response.data;
          me.editedItem.estadoHacienda = me.respuestaHacienda.eInvoiceStatus;
          me.editedItem.mensajeHacienda = me.respuestaHacienda.details;

          if (me.editedItem.estadoHacienda !== "PROCESANDO") {
            me.consultarEstadoValid = false;
          }

          if (
            me.editedItem.estadoHacienda === "ACEPTADO" ||
            me.editedItem.estadoHacienda === "RECHAZADO"
          ) {
            me.imprimirValid = true;
            me.reenviarValid = true;
            me.verPDF = true;
            me.verXMLFirmado = true;
            me.verXMLRespuesta = true;
          } else {
            me.imprimirValid = false;
            me.reenviarValid = false;
            me.verPDF = false;
            me.verXMLFirmado = false;
            me.verXMLRespuesta = false;
          }
          me.getData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // reenviarCorreo() {
    //   let me = this;

    //   this.comprobante = this.editedItem;

    //   axios
    //     .post("api/Facturacion/reenviarCorreo", this.comprobante)
    //     .then(function (response) {
    //       me.snackText = response.data;
    //       me.snackValue = true;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    getModeloComprobante() {
      let me = this;

      axios
        .get(
          "api/Compras/getModeloComprobante?cia=" +
          this.strCia +
          "&clave=" +
          this.editedItem.clavehacienda +
          "&docNombre=Compras"
        )
        .then(function (response) {
          me.modelFactura = response.data;

          //me.editedItem = me.modelFactura.comprobante;
          me.lineasDetalle = me.modelFactura.comprobanteDetalle;

          me.editedItem.totalIVADevueltoStr =
            me.modelFactura.comprobante.totalIVADevueltoStr; //validar esto
          me.editedItem.totalIva1Str = me.modelFactura.comprobante.totalIva1Str;
          me.editedItem.totalIva2Str = me.modelFactura.comprobante.totalIva2Str;
          me.editedItem.totalIva4Str = me.modelFactura.comprobante.totalIva4Str;
          me.editedItem.totalIva13Str =
            me.modelFactura.comprobante.totalIva13Str;
          me.editedItem.totalDescuentosStr =
            me.modelFactura.comprobante.totalDescuentosStr;
          me.editedItem.totalImpuestoStr =
            me.modelFactura.comprobante.totalImpuestoStr;
          me.editedItem.subtotalStr = me.modelFactura.comprobante.subtotalStr;
          me.editedItem.totalComprobanteStr =
            me.modelFactura.comprobante.totalComprobanteStr;
          me.editedItem.enviado = 0;
          me.editedItem.estadoHaciendaEmisor =
            me.modelFactura.comprobante.estadoHaciendaEmisor;
          me.fechaString = me.modelFactura.comprobante.fechaString;
          me.fechaHaciendaString = me.modelFactura.comprobante.fechaHaciendaString;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    save() {
      let me = this;
      // this.snackText = "el estado que tiene es: " + this.editedIndex;
      // this.snackValue = true;
      //if (this.editedIndex > -1) {
      if (
        this.editedItem.codigoActividad != "" &&
        this.editedItem.estadoDev != 0
      ) {
        this.visibleProgress = true;
        //this.editedItem.fecha = new Date();
        this.editedItem.fechaHacienda = new Date();
        this.editedItem.cia = this.strCia;
        this.editedItem.usuario = this.user.id;
        this.editedItem.tipoFact = "05";
        this.editedItem.nombreTabla = "Compras";
        axios
          .post("api/Compras/enviarCompra", this.editedItem)
          .then(function (response) {
            me.visibleProgress = false;
            me.respuestaHacienda = response.data;

            me.editedItem.clavehacienda = me.respuestaHacienda.claveHacienda;
            me.editedItem.consHacReal =
              me.respuestaHacienda.consecutivoHacienda;
            me.editedItem.estadoHacienda = me.respuestaHacienda.eInvoiceStatus;
            me.editedItem.mensajeHacienda = me.respuestaHacienda.details;

            if (
              me.editedItem.estadoHacienda === "ACEPTADO" ||
              me.editedItem.estadoHacienda === "RECHAZADO"
            ) {
              me.imprimirValid = true;
              me.reenviarValid = true;
              me.verPDF = true;
              me.verXMLFirmado = true;
              me.verXMLRespuesta = true;
            } else {
              me.imprimirValid = false;
              me.reenviarValid = false;
              me.verPDF = false;
              me.verXMLFirmado = false;
              me.verXMLRespuesta = false;
            }

            if (me.editedItem.estadoHacienda === "PROCESANDO") {
              me.consultarEstadoValid = true;
            }

            me.getData();
          })
          .catch(function (error) {
            console.log(error);
          });

        me.cancelarValid = false;
        me.guardarValid = false;
        me.incluirValid = true;
        me.snackValue = false;
      } else {
        this.snackText =
          "Por favor valide los datos de Actividad Comercial y Estado Respuesta";
        this.snackValue = true;
      }
      //}
    },

    // saveDetalleArticulo() {
    //   if (this.detalleEditedIndex > -1) {
    //     Object.assign(
    //       this.lineasDetalle[this.detalleEditedIndex],
    //       this.detalleEditedItem
    //     );
    //   } else {
    //     this.lineasDetalle.push(this.detalleEditedItem);
    //   }
    //   this.close();

    //   let me = this;
    //   if (this.detalleEditedIndex > -1) {
    //     axios
    //       .post(
    //         "api/Facturacion/realizarCalculosComprobante",
    //         this.lineasDetalle
    //       )
    //       .then(function (response) {
    //         me.lineasDetalle = response.data.comprobanteDetalle;
    //         me.totales = response.data.comprobante;
    //         me.editedItem.totalIVADevuelto = 0; //validar esto

    //         me.editedItem.subTotal = me.totales.subtotal;
    //         me.editedItem.totalDescuentos = me.totales.totalDescuentos;
    //         me.editedItem.totalImpuesto = me.totales.totalImpuesto;
    //         me.editedItem.totalIva1 = me.totales.totalIva1;
    //         me.editedItem.totalIva2 = me.totales.totalIva2;
    //         me.editedItem.totalIva4 = me.totales.totalIva4;
    //         me.editedItem.totalIva13 = me.totales.totalIva13;
    //         me.editedItem.totalComprobante = me.totales.totalComprobante;

    //         me.editedItem.totalServGravados = me.totales.totalServGravados;
    //         me.editedItem.totalServExentos = me.totales.totalServExentos;
    //         me.editedItem.totalServExonerado = me.totales.totalServExonerado;
    //         me.editedItem.totalMercanciasGravadas =
    //           me.totales.totalMercanciasGravadas;
    //         me.editedItem.totalMercanciasExentas =
    //           me.totales.totalMercanciasExentas;
    //         me.editedItem.totalMercExonerada = me.totales.totalMercExonerada;
    //         me.editedItem.totalGravado = me.totales.totalGravado;
    //         me.editedItem.totalExento = me.totales.totalExento;
    //         me.editedItem.totalExonerado = me.totales.totalExonerado;
    //         me.editedItem.totalVenta = me.totales.totalVenta;
    //         me.editedItem.totalVentaNeta = me.totales.totalVentaNeta;
    //         me.editedItem.totalIVADevuelto = me.totales.totalIVADevuelto;
    //         me.editedItem.totalOtrosCargos = me.totales.totalOtrosCargos;
    //         me.editedItem.totalIva13 = me.totales.totalIva13;

    //         me.editedItem.totalIVADevueltoStr = me.totales.totalIVADevueltoStr; //validar esto
    //         me.editedItem.totalIva1Str = me.totales.totalIva1Str;
    //         me.editedItem.totalIva2Str = me.totales.totalIva2Str;
    //         me.editedItem.totalIva4Str = me.totales.totalIva4Str;
    //         me.editedItem.totalIva13Str = me.totales.totalIva13Str;
    //         me.editedItem.totalDescuentosStr = me.totales.totalDescuentosStr;
    //         me.editedItem.totalImpuestoStr = me.totales.totalImpuestoStr;
    //         me.editedItem.subtotalStr = me.totales.subtotalStr;
    //         me.editedItem.totalComprobanteStr = me.totales.totalComprobanteStr;
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   } else {
    //     axios
    //       .post(
    //         "api/Facturacion/realizarCalculosComprobante",
    //         this.lineasDetalle
    //       )
    //       .then(function (response) {
    //         me.lineasDetalle = response.data.comprobanteDetalle;

    //         me.totales = response.data.comprobante;
    //         me.editedItem.totalIVADevuelto = 0; //validar esto
    //         me.editedItem.subTotal = me.totales.subtotal;
    //         me.editedItem.totalDescuentos = me.totales.totalDescuentos;
    //         me.editedItem.totalImpuesto = me.totales.totalImpuesto;
    //         me.editedItem.totalIva1 = me.totales.totalIva1;
    //         me.editedItem.totalIva2 = me.totales.totalIva2;
    //         me.editedItem.totalIva4 = me.totales.totalIva4;
    //         me.editedItem.totalIva13 = me.totales.totalIva13;
    //         me.editedItem.totalComprobante = me.totales.totalComprobante;

    //         me.editedItem.totalServGravados = me.totales.totalServGravados;
    //         me.editedItem.totalServExentos = me.totales.totalServExentos;
    //         me.editedItem.totalServExonerado = me.totales.totalServExonerado;
    //         me.editedItem.totalMercanciasGravadas =
    //           me.totales.totalMercanciasGravadas;
    //         me.editedItem.totalMercanciasExentas =
    //           me.totales.totalMercanciasExentas;
    //         me.editedItem.totalMercExonerada = me.totales.totalMercExonerada;
    //         me.editedItem.totalGravado = me.totales.totalGravado;
    //         me.editedItem.totalExento = me.totales.totalExento;
    //         me.editedItem.totalExonerado = me.totales.totalExonerado;
    //         me.editedItem.totalVenta = me.totales.totalVenta;
    //         me.editedItem.totalVentaNeta = me.totales.totalVentaNeta;
    //         me.editedItem.totalIVADevuelto = me.totales.totalIVADevuelto;
    //         me.editedItem.totalOtrosCargos = me.totales.totalOtrosCargos;
    //         me.editedItem.totalIva13 = me.totales.totalIva13;

    //         me.editedItem.totalIVADevueltoStr = me.totales.totalIVADevueltoStr; //validar esto
    //         me.editedItem.totalIva1Str = me.totales.totalIva1Str;
    //         me.editedItem.totalIva2Str = me.totales.totalIva2Str;
    //         me.editedItem.totalIva4Str = me.totales.totalIva4Str;
    //         me.editedItem.totalIva13Str = me.totales.totalIva13Str;
    //         me.editedItem.totalDescuentosStr = me.totales.totalDescuentosStr;
    //         me.editedItem.totalImpuestoStr = me.totales.totalImpuestoStr;
    //         me.editedItem.subtotalStr = me.totales.subtotalStr;
    //         me.editedItem.totalComprobanteStr = me.totales.totalComprobanteStr;
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   }
    //   this.cancelarValid = true;
    //   this.imprimirValid = false;
    //   this.reenviarValid = false;
    //   this.verPDF = false;
    //   this.verXMLFirmado = false;
    //   this.verXMLRespuesta = false;
    //   this.guardarValid = true;
    //   this.incluirValid = false;
    //   this.snackValue = false;
    //   this.panelResumen = [0];
    //   this.consultarEstadoValid = false;
    // },

    close() {
      this.dialogArticulos = false;
      this.$nextTick(() => {
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.detalleEditedIndex = -1;
      });
    },

    deleteItem(item) {
      if (this.guardarValid) {
        this.detalleEditedIndex = this.lineasDetalle.indexOf(item);
        this.detalleEditedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },

    closed() {
      this.showNewData = false;
      this.showNewDataArti = false;
      this.dialog = false;
      this.dialogArticulos = false;
    },

    // selected(value) {
    //   this.getProvincias();
    //   this.editedItem = Object.assign({}, value);
    //   this.getCodigoFactura();
    //   this.showNewData = false;
    //   this.dialog = false;
    //   this.getCantones();
    //   this.getDistritos();
    //   this.getBarrios();
    //   this.getFechaActual();
    //   this.getModeloComprobante();
    //   this.panelResumen = [0];
    // },

    // selectedArticulos(value) {
    //   this.articulo = Object.assign({}, value);
    //   this.showNewDataArti = false;
    //   this.dialogArticulos = true;
    //   this.detalleEditedItem.cia = this.strCia;
    //   this.detalleEditedItem.factura = this.editedItem.codigoFact;
    //   this.detalleEditedItem.tipoFact = "01";
    //   this.detalleEditedItem.numLinea = 0;
    //   this.detalleEditedItem.unidad = this.articulo.unidad;
    //   this.detalleEditedItem.descuento = 0;
    //   this.detalleEditedItem.totalLinea = 0;
    //   this.detalleEditedItem.sucursal = this.editedItem.sucursal;
    //   this.detalleEditedItem.terminal = this.editedItem.terminal;
    //   this.detalleEditedItem.tipoCodigo = "01";
    //   this.detalleEditedItem.unidadMedidaComercial = "M2";
    //   this.detalleEditedItem.impCodigo = this.articulo.codigoImp;
    //   this.detalleEditedItem.impCodigoTarifa = this.articulo.codigoTarifa;
    //   this.detalleEditedItem.impTarifa = this.articulo.tarifa;
    //   this.detalleEditedItem.porcDescuento = 0;
    //   this.detalleEditedItem.monto = this.articulo.monto;
    //   this.detalleEditedItem.descripcion = this.articulo.nombre;
    //   this.detalleEditedItem.cantidad = 1;
    //   this.detalleEditedItem.cabys = this.articulo.cabys;
    //   this.detalleEditedItem.articulo = this.articulo.codigo;
    //   this.detalleEditedItem.tipoCambio = this.editedItem.tipoCambio;
    //   this.detalleEditedItem.impuestosVentas = this.articulo.tarifa;
    //   this.detalleEditedItem.tipoProducto = this.articulo.tipoArt;
    //   this.detalleEditedItem.moneda = this.editedItem.moneda;
    // },

    cerrar() {
      this.dialog = false;
      this.dialogArticulos = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.valid = false;
        this.cancelarValid = false;
        this.imprimirValid = false;
        this.reenviarValid = false;
        this.verXMLFirmado = false;
        this.verXMLRespuesta = false;
        this.consultarEstadoValid = false;
        this.panelResumen = [];
        this.panel = [];
        this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
        this.lineasDetalle = [];
      });
    },

    // incluir() {
    //   this.$nextTick(() => {
    //     this.editedIndex = -1;
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.getCodigoFactura();
    //     this.cancelarValid = true;
    //     this.imprimirValid = false;
    //     this.reenviarValid = false;
    //     this.verPDF = false;
    //     this.verXMLFirmado = false;
    //     this.verXMLRespuesta = false;
    //     this.guardarValid = true;
    //     this.incluirValid = false;
    //     this.consultarEstadoValid = false;
    //     this.getCia();
    //     this.getMonedas();
    //     this.getActividades();
    //     this.getCondicionVenta();
    //     this.getMedioPago();
    //     this.getSucursales();
    //     this.detalleEditedItem = Object.assign({}, this.detalleDefaultItem);
    //     this.lineasDetalle = [];
    //     this.editedItem.estadoHacienda = "PENDIENTE";
    //     this.panel = [0];
    //     this.btnAgregarArti = false;
    //     this.btnAgregarDocumento = true;
    //     //this.getFechaActual();
    //   });
    // },
  },
};
</script>

<style>
.cd {
  overflow: scroll;
}

.v-tabs-items {
  overflow: auto;
}

.botonSalir {
  position: fixed;
  top: 5px;
  right: 5px;
}

.right-input input {
  text-align: right;
}
</style>